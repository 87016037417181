import React from "react";

export default ({ component: Component, title = "aiApait" }) => {
    const withTitle = (props) => {
        document.title = title + " - aiApait";
        return <Component {...props} />;
    };

    return withTitle;
};
 