import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { timeInNotificationFormat } from "utils/dateFormatUtils";
import { connect } from "react-redux";
//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import notificationBellIcon from "../../../assets/images/aiAPaiT/notification-bell-icon.svg";
import { Loader } from "components/Loader";
import "./NotificationDropdown.scss";
//i18n
import { withTranslation } from "react-i18next";

const NotificationDropdown = ({
    redirectNotification,
    notificationCount,
    page,
    notificationsData,
    totalNotifications,
    fetchNotificatoins,
    isLoading,
    handlePageClick,
    updatePage
}) => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false);
    const [notiCount, setNotiCount] = useState("40");

    const handleNotificationClick = (item) => {
        setMenu(!menu);
        redirectNotification(item);
    };

    const findNotificationCountClass = (length) => {
        switch (true) {
            case length < 100:
                return "two-digit-badge";
            case length < 1000:
                return "three-digit-badge";
            case length >= 1000:
                return "four-digit-badge text-truncate";
        }
    };

    const handleFetchNotifications = () => {
        if (!menu) {
            updatePage();
            fetchNotificatoins();
        }
    };

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="dropdown d-inline-block"
                tag="li"
            >
                <DropdownToggle
                    className="btn header-item noti-icon "
                    tag="button"
                    id="page-header-notifications-dropdown"
                >
                    <img src={notificationBellIcon} onClick={handleFetchNotifications} />
                    {notificationCount !== 0 ? (
                        <span
                            className={`badge bg-primary ${findNotificationCountClass(
                                notificationCount
                            )}`}
                        >
                            {notificationCount}
                        </span>
                    ) : null}
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end noti-dropdown-con">
                    <div className="p-3 pb-0">
                        <Row className="align-items-center">
                            <Col>
                                <h6 className="m-0 noti-heading">Notifications</h6>
                            </Col>
                        </Row>
                    </div>

                    <SimpleBar
                        style={{ height: "230px" }}
                        className={`${notificationsData?.length === 0 ? "condition" : ""}`}
                    >
                        {isLoading && page == 1 ? (
                            <Loader size="sm" classes="mb-2" />
                        ) : (
                            <>
                                {notificationsData?.length === 0 ? (
                                    <>
                                        <span className="no-not-typo ">
                                            No Notifications to show
                                        </span>
                                    </>
                                ) : (
                                    notificationsData?.map((item, index) => {
                                        return (
                                            <div
                                                key={item?.id}
                                                onClick={(e) => handleNotificationClick(item)}
                                                className={` mt-1 ${
                                                    item?.readStatus === "UNREAD"
                                                        ? "unread-not-bg-color"
                                                        : ""
                                                }`}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="text-reset notification-item">
                                                    <div className="media">
                                                        <div className="media-body notification-item-inner-con">
                                                            <h6
                                                                className={`mt-0 mb-1 noti-name ${
                                                                    item?.readStatus === "UNREAD"
                                                                        ? "unread-not-typo-color"
                                                                        : null
                                                                } `}
                                                            >
                                                                {item?.title || "-"}
                                                            </h6>
                                                            <div className="">
                                                                <p
                                                                    className={`mb-1 noti-main-content ${
                                                                        item?.readStatus ===
                                                                        "UNREAD"
                                                                            ? "unread-not-typo-color"
                                                                            : null
                                                                    } `}
                                                                >
                                                                    {item?.description || "-"}
                                                                </p>
                                                                <p
                                                                    className={`"mb-0 noti-time" ${
                                                                        item?.readStatus ===
                                                                        "UNREAD"
                                                                            ? "unread-not-typo-color"
                                                                            : null
                                                                    } `}
                                                                >
                                                                    <i className="mdi mdi-clock-outline" />{" "}
                                                                    {timeInNotificationFormat(
                                                                        item?.updatedAt
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </>
                        )}
                    </SimpleBar>
                    {notificationsData?.length != totalNotifications && (
                        <>
                            {isLoading && page != 1 ? (
                                <Loader size="sm" classes="mb-2" />
                            ) : notificationsData?.length === 0 ? null : (
                                <div className="w-100 p-2 d-flex justify-content-center align-items-center cursor-pointer">
                                    <p onClick={() => handlePageClick()}>See More</p>
                                </div>
                            )}
                        </>
                    )}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { notificationCount } = state?.userAuth;
    return { notificationCount };
};

export default connect(mapStatetoProps, null)(withTranslation()(NotificationDropdown));
