import React from "react";
import PropTypes from "prop-types"; 
import { CustomModal } from "components/CustomModal";
import PdfDocumentViewer from './PdfDocumentViewer'

const DocumentViewerModal = ({
    modalActivator = false,
    modalCloseHandler = () => {},
    modalData = "",
}) => {

  // checking if file type is pdf
  const isPdfType = modalData.split('.')[modalData.split('.').length - 1] === "pdf" ? true : false;
  
    return (
        <>
            <CustomModal
                isOpen={modalActivator}
                className="pdf-view-modal-con"
                onClose={modalCloseHandler}
                modalCloseIconClass="close-modal-icon pb-0"
                modalBodyClass="pdf-view-modal-body"
                data={
                  <>
                  {isPdfType ? 
                  (<>
                  <PdfDocumentViewer documentPath={modalData}/>
                </>):(<>
                  <div className="document-view-modal-con">
                    <img src={modalData}/>
                  </div>
                </>)}
                  </>
                }
            />
        </>
    );
};

// handling prop types
DocumentViewerModal.propTypes = {
    modalActivator: PropTypes.bool,
    modalCloseHandler: PropTypes.func,
    modalData: PropTypes.string,
};

export default DocumentViewerModal;
