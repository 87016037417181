import { Col, Row, Card } from "reactstrap";

const COL_MD = 3;

export default function CaseManagementHeader({ data }){
  return(
    <>
      <Card className="case-detail-main-container">
          <Row>
              <Col md={COL_MD}>
                  <div className="case-detail-form-fields-container">
                      <p>{"Case Id"}</p>
                      <div>{data?.caseId || "-"}</div>
                  </div>
              </Col>
              <Col md={6}>
                  <div className="case-detail-form-fields-container">
                      <p>{"Title"}</p>
                      <div>{data?.caseTitle || "-"}</div>
                  </div>
              </Col>
          </Row>
          <Row>
              <Col md={COL_MD}>
                  <div className="case-detail-form-fields-container">
                      <p>{"Full Name"}</p>
                      <div>{data?.customerName || "-"}</div>
                  </div>
              </Col>
              <Col md={COL_MD}>
                  <div className="case-detail-form-fields-container">
                      <p>{"Department Name"}</p>
                      <div>{data?.adminName || "-"}</div>
                  </div>
              </Col>
              <Col md={COL_MD}>
                  <div className="case-detail-form-fields-container">
                      <p>{"Country"}</p>
                      <div>{data?.countryName || "-"}</div>
                  </div>
              </Col>
              <Col md={COL_MD}>
                  <div className="case-detail-form-fields-container">
                      <p>{"Total Spendings"}</p>
                      <div>${data?.earnings?.toFixed(2) || "-"}</div>
                  </div>
              </Col>
          </Row>
      </Card>
    </>
  )
}