import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import MetaTags from "react-meta-tags";
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest";
import { Pagination } from "components/Pagination";
import { DataTable } from "components/DataTable";
import { HeaderDataTable } from "../ComponentsProject/HeaderDataTable";
import { customerManagementHeader, sortingOptions } from "../../constants";
import { Sorting } from "components/Sorting";

import detailsIcon from "../../assets/images/aiAPaiT/Table/view-icon.svg";
import { APP_ROUTES } from "helpers/routeHelpers";
import { METATAGS } from "../../constants";
import { getAllCustomers } from "api/api.service";

const Listing = () => {

    // getting data from backend using custom hook
    const {
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        searchText,
        handleSearchChange,
        data,
        total,
        isFetching,
        request
    } = useLocalPaginatedRequest({
        requestFn: getAllCustomers,
        params: {},
        deps: []
    });

    return (
        <>
            <MetaTags>
                <title>{METATAGS.CUSTOMER_MANAGEMENT}</title>
            </MetaTags>
            <Row className="p-3 case-management-con">
                <Col md={12}>
                    <HeaderDataTable
                        searchPlaceholder="Search by customer name"
                        searchValue={searchText}
                        onSearchChange={handleSearchChange}
                        isButton={false}
                    />
                </Col>
                <Col md={12}>
                    <DataTable
                        data={data}
                        loading={isFetching}
                        config={[
                            {
                                title: customerManagementHeader.CUSTOMER_ID,
                                className: "bold-font cm-cust-name text-truncate ",
                                render: (data) => data?.id || "-"
                            },
                            {
                                title: customerManagementHeader.CUSTOMER_NAME,
                                className: "bold-font cm-cust-name text-truncate ",
                                render: (data) => data?.name || "-"
                            },
                            {
                                title: customerManagementHeader.PHONE_NUMBER,
                                className: "cm-subadmin-name",
                                render: (data) => <>{data?.countryCode + data?.phone}</> || "-"
                            },
                            {
                                title: customerManagementHeader.TOTAL_SPENDING,
                                className: "cm-region bold-font ",
                                render: (data) => <>${data?.totalPayments.toFixed(2)}</> || "-"
                            },
                            {
                                title: customerManagementHeader.CASE_FILED,
                                className: "cm-process ",
                                render: (data) => data?.totalCases
                            },
                            {
                                title: customerManagementHeader.ACTION,
                                className: "cm-action",
                                render: (data) => (
                                    <>
                                        <Link
                                            to={`${APP_ROUTES.CUSTOMER_MANAGEMENT_DETAILS}/${data?.id}`}
                                            className=" pointer "
                                        >
                                            <img src={detailsIcon} alt="details icon" />
                                        </Link>
                                    </>
                                )
                            }
                        ]}
                    />
                </Col>
                <Col md={12}>
                    <div className=" bg-white d-flex justify-content-between align-items-center py-3">                       
                        <Sorting options={sortingOptions} changeHandler={onChangePageSize} />
                        <Pagination
                            className="pagination-contain "
                            currentPage={page}
                            totalData={total}
                            onPageClick={handlePageClick}
                            rowLimit={pageSize}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Listing;
