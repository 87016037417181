
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const downloadCSVData = (blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = `sample_${new Date().getTime()}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export const downloadXlsData = async (response) => {
    let blob = await response.blob()

    var url = window.URL.createObjectURL(blob)
    var a = document.createElement('a')
    a.href = url
    a.download = `template_${new Date().getTime()}.xlsx`
    document.body.appendChild(a)
    a.click()
    a.remove()
}

export const pdfFromHTML = (divToPrint) => {
    html2canvas(divToPrint.current, {
    quality: 4,
    scale: 5})
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', );
      const doc=new JsPDF("", "pt", "a4");
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
  
      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
  
      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;
  
      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;
      doc.addImage(imgData, 'JPEG', marginX, 20, canvasWidth, canvasHeight)
      doc.save(`receipt_${new Date().getTime()}.pdf`);
    })
}

