import React from "react";
import PropTypes from "prop-types";

import { CustomButton } from "components/CustomButton";
import { SearchBox } from "components/SearchBox";

const Header = (props) => {
    const {
        searchPlaceholder = "Search",
        buttonTitle = "",
        searchValue = "",
        onSearchChange = () => {},
        onButtonClick = () => {},
        isButton = true
    } = props;
    return (
        <>
            <div className="bg-white p-3 search-btn-header-con">
                <div>
                    <SearchBox
                        iconClass="main-header-search-icon"
                        className={`${isButton && "margin-resp"} search-btn-search-box `}
                        placeholder={searchPlaceholder}
                        onChange={onSearchChange}
                        value={searchValue}
                    />
                </div>
                {isButton && (
                    <div className="">
                        <CustomButton
                            color="primary"
                            title={buttonTitle}
                            onClick={onButtonClick}
                            className="login-btn-typo search-btn-header-btn"
                        />
                    </div>
                )}
            </div>
        </>
    );
};


// handling prop types
Header.propTypes = {
    onSearchChange: PropTypes.func,
    onButtonClick: PropTypes.func,
    searchValue: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    buttonTitle: PropTypes.string,
    isButton: PropTypes.bool
};

export default Header;
