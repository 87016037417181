import React from 'react';
import PropTypes from "prop-types";
import { Label, Input } from 'reactstrap';

const Sorting  = ({
  options,
  changeHandler
}) => {
  return(
    <div className="d-flex align-items-center sorting-con">
      <Label className="ms-4 me-3 mb-0">Show</Label>
      <Input 
      type="select" 
      name="select" 
      id="exampleSelect" 
      onChange={changeHandler}
      className="sorting-dropdown">
          {options && options.map((el, index) => <option value={el.value} key={index}>{el.label}</option>)}
      </Input>
    </div>
  )
};


Sorting.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  changeHandler: PropTypes.func,
};

export default Sorting;