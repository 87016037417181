export default {
    LOGIN: "Login",
    LOGOUT: "Logout",
    REGISTER: "Register",
    FORGET_PASSWORD: "Forget Password",
    FORGET_VERIFY_CODE: "Forget Verify Code",
    OTP_VERIFY_CODE: "OTP Verify Code",
    RESET_PASSWORD: "Reset Password",
    DASHBOARD: "Dashboard"
};
