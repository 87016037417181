export const APP_ROUTES = {
    // **** Auth
    LOGIN: "/login",
    LOGOUT: "/logout",
    FORGET_PASSWORD: "/forgot-password",
    OTP_VERIFY_CODE: "/forget-verify-code",
    RESET_PASSWORD: "/reset-password",
    // FORGET_VERIFY_CODE: "/forget-verify-code/:tokenId",
    // RESET_PASSWORD: "/reset-password/:code/:tokenId",
    REGISTER: "/register",
    // DASHBOARD: "/dashboard",
    // **** Store Management
    STORE_MANAGEMENT: "/store-management",
    STORE_DETAILS: "/store-management/:storeId",
    ADD_STORE: "/store-management/add-store",
    EDIT_STORE: "/store-management/edit-store/:storeId",
    // **** User Management
    USER_MANAGEMENT: "/user-management",
    ADD_USER: "/user-management/add-user",
    EDIT_USER: "/user-management/edit-user/:userId",
    USER_DETAILS: "/user-management/:userId",
    // **** Store Audit
    STORE_AUDIT: "/store-audit",
    STORE_AUDIT_DETAILS: "/store-audit/:storeAuditId",
    STORE_AUDIT_GALLERY: "/store-audit/gallery/:storeAuditId",
    // **** Jobs
    JOBS: "/jobs",
    CREATE_JOB: "/jobs/create-job/",
    EDIT_JOB: "/jobs/edit-job/:jobId",
    JOB_DETAILS: "/jobs/:jobId",

    ROLE_MANAGEMENT: "/role-management",
    ROLE_MANAGEMENT_DETAILS: "/role-management/:id",
    CLIENT_MANAGEMENT: "/client-management",
    ADD_CLIENT: "/client-management/add-client",
    EDIT_CLIENT: "/client-management/edit-client/:clientId",
    CLIENT_DETAILS: "/client-management/client-details/:clientId",

    STORE_AUDIT: "/store-audit",
    STORE_AUDIT_DETAILS: "/store-audit/store-audit-details/:status",
    PROJECTS: "/projects",
    CREATE_PROJECT: "/projects/add-project",
    UPDATE_PROJECT: "/projects/edit-project/:projectId",
    EXECUTIVE_SUMMARY_REPORT: "/executive-summary-report",
    EXECUTIVE_SUMMARY_REPORT_DETAILS: "/executive-summary-report-details",
    REP_PERFORMANCE_REPORT: "/rep-performance-report",
    PAYOUT_REPORTS: "/payout-report",
    REVENUE_REPORTS: "/revenue-report",
    MONTHLY_REP_PERFORMANCE_REPORT: "/monthly-rep-performance-report",
    BILLING_REPORT: "/billing-report",
    STORE_SURVEY_REPORT: "/store-survey-report",
    PROFILE: "/profile",
    USER_PROFILE: "/user-profile",
    VERIFICATION_CODE: "/verification-code",
    CHANGE_EMAIL: "/change-email",
    CHANGE_PASSWORD: "/change-password",

    //sidebar
    DASHBOARD: "/dashboard",
    CASE_MANAGEMENT: "/case-management",
    CASE_MANAGEMENT_DETAILS: "/case-management/case-details",
    CUSTOMER_MANAGEMENT: "/customer-management",
    CUSTOMER_MANAGEMENT_DETAILS: "/customer-management/customer-details",
    SUB_ADMINS: "/departments",
    SUB_ADMINS_DETAILS: "/departments/details",
    EARNINGS: "/earnings",
    EARNINGS_DETAILS: "/earnings/details",
    COUNTRIES: "/countries",
    GOODS_SERVICES: "/goods-services",
    CHATS: "/chats",
    SETTINGS: "/settings",
    SETTINGS_DETAILS: "/settings/edit-profile",
    SETTINGS_CHANGE_PASS: "/settings/change-pass"
};

export const getRoute = (route, obj = {}) => {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj);
        objectKeys?.forEach((item) => {
            route = route?.replace(new RegExp(/:([\d\w?])+/, "i"), (match) => {
                let formattedMatchedValue =
                    match[match.length - 1] === "?"
                        ? match.slice(1, match.length - 1)
                        : match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        console.log("route", route);
        return route;
    }
    return route;
};
