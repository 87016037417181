import { Loader } from "components/Loader";
import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Media } from "reactstrap";

const CardStats = (props) => {
    const { report, className, onClick, titleClass, descriptionClass, loading } = props;
    return (
        <div className={`cs-card-stats ${className} ${onClick && "pointer"}`}>
            <Card className="mini-stats-wid" onClick={onClick}>
                <CardBody>
                    {loading ? (
                        <Loader />
                    ) : (
                        <Media>
                            <Media body>
                                <p className={`${titleClass} `}>{report.title}</p>
                                <h4 className={`${descriptionClass} mb-0`}>{report.description}</h4>
                            </Media>
                            {report.iconUrl ? (
                                <div className="align-self-center">
                                    <img
                                        src={report.iconUrl}
                                        className="img-fluid"
                                        height={48}
                                        width={48}
                                    />
                                </div>
                            ) : (
                                <div className="avatar-sm rounded-circle bg-primary align-self-center overflow-hidden position-relative">
                                    <span className="avatar-title bg-theme-secondary-light clr-theme-primary">
                                        <i
                                            className={"bx " + report.iconClass + " font-size-24"}
                                        ></i>
                                    </span>
                                </div>
                            )}
                        </Media>
                    )}
                </CardBody>
            </Card>
        </div>
    );
};

CardStats.propTypes = {
    report: PropTypes.any,
    loading: PropTypes.bool,
    className: PropTypes.any,
    onClick: PropTypes.any,
    titleClass: PropTypes.any,
    descriptionClass: PropTypes.any,
    showOptions: PropTypes.any
};

export default CardStats;
