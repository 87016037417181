import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";

import { store } from "store";
import moment from "moment";
import { Loader } from "components/Loader";

const MESSAGE_LENGTH = 500;

const Messages = ({ list, loading }) => {
    const [seeMore, setSeeMore] = useState(false);
    const userId = store?.getState()?.userAuth?.user?.id;

    return (
        <>
            <div>
                {loading && <Loader />}
                {list?.map((msg, i) => (
                    <>
                        <div className="msg-width-scroll" key={i}>
                            <h2 className="msg-daytime-bar">
                                <span>{moment(msg?.date).format("dddd, MMMM Do YYYY")}</span>
                            </h2>
                            {msg?.chat?.map((item, index) => (
                                <div className="d-flex flex-column" key={index}>
                                    <div>
                                        <div
                                            className={`msg-text ${
                                                item?.senderId === userId ||
                                                item?.sender?.type == "SUBADMIN" ||
                                                item?.sender?.type == "ADMIN" ||
                                                item?.type == "ADMIN_EVENT" ||
                                                item?.type == "SUBADMIN_EVENT"
                                                    ? "owner"
                                                    : ""
                                            }`}
                                        >
                                            <div className="pl-3">
                                                <div
                                                    className={`elevation-0 ${
                                                        item?.senderId === userId ||
                                                        item?.sender?.type == "SUBADMIN" ||
                                                        item?.sender?.type == "ADMIN" ||
                                                        item?.type == "ADMIN_EVENT" ||
                                                        item?.type == "SUBADMIN_EVENT"
                                                            ? "owner-message"
                                                            : "recipient-msg-con"
                                                    }`}
                                                >
                                                    <div className="pa-2 d-flex flex-column message-box response">
                                                        <span className="align-self-start chat-message">
                                                            {item?.content.length >=
                                                            MESSAGE_LENGTH ? (
                                                                <>
                                                                    {seeMore &&
                                                                        item?.content.slice(0)}
                                                                    {!seeMore &&
                                                                        item?.content.slice(
                                                                            0,
                                                                            MESSAGE_LENGTH
                                                                        )}
                                                                    <button
                                                                        className="see-more"
                                                                        onClick={() => {
                                                                            setSeeMore(!seeMore);
                                                                        }}
                                                                    >
                                                                        {!seeMore
                                                                            ? "See more"
                                                                            : "See less"}
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                item?.content
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>

                                                <span
                                                    className={`message-time ${
                                                        item?.senderId === userId ||
                                                        item?.sender?.type == "SUBADMIN" ||
                                                        item?.sender?.type == "ADMIN" ||
                                                        item?.type == "ADMIN_EVENT" ||
                                                        item?.type == "SUBADMIN_EVENT"
                                                            ? "msg-time-owner"
                                                            : ""
                                                    }`}
                                                >
                                                    {moment(item?.updatedAt).fromNow()}{" "}
                                                    {item?.type === "ADMIN_EVENT" ? " ● admin" : ""}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ))}
            </div>
        </>
    );
};

// handling prop types
Messages.propTypes = {
    userData: PropTypes.any,
    list: PropTypes.any,
    loading: PropTypes.bool
};

export default Messages;
