import React from "react"
import PropTypes from 'prop-types'
import { Link, useLocation } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import { APP_ROUTES } from "../../helpers/routeHelpers";
import RightArrowIcon from "../../assets/images/aiAPaiT/rightarrow-icon.svg";
import "./Breadcrumb.scss";

const Breadcrumb = props => {
  const { title, breadcrumbItems } = props
  // const itemLength = breadcrumbItems.length
  const {pathname} = useLocation();
  let pathnameArr = pathname.split('/');

  let urlData = [{
    path: APP_ROUTES.DASHBOARD,
    title: "Dashboards",
    count: "1",
    matchUrl: "/dashboard",
    key: "01"
  },
  {
    path: APP_ROUTES.CASE_MANAGEMENT,
    title: "Case Management",
    count: "1",
    matchUrl: "/case-management",
    key: "02"
  },
  {
    path: APP_ROUTES.CASE_MANAGEMENT_DETAILS,
    title: "Case Details",
    count: "2",
    matchUrl: "/case-management/case-details",
    key: "03"
  },
  {
    path: APP_ROUTES.CUSTOMER_MANAGEMENT,
    title: "Customer Management",
    count: "1",
    matchUrl: "/customer-management",
    key: "04"
  },
  {
    path: APP_ROUTES.CUSTOMER_MANAGEMENT_DETAILS,
    title: "Customer Details",
    count: "2",
    matchUrl: "/customer-management/customer-details",
    key: "05"
  },
  {
    path: APP_ROUTES.SUB_ADMINS,
    title: "Departments Management",
    count: "1",
    matchUrl: "/departments",
    key: "06"
  },
  {
    path: APP_ROUTES.SUB_ADMINS_DETAILS,
    title: "Department Details",
    count: "2",
    matchUrl: "/departments/details",
    key: "07"
  },
  {
    path: APP_ROUTES.EARNINGS,
    title: "Earnings",
    count: "1",
    matchUrl: "/earnings",
    key: "08"
  },
  {
    path: APP_ROUTES.EARNINGS_DETAILS,
    title: "Earnings Detail",
    count: "2",
    matchUrl: "/earnings/details",
    key: "09"
  },
  {
    path: APP_ROUTES.COUNTRIES,
    title: "Countries",
    count: "1",
    matchUrl: "/countries",
    key: "10"
  },
  {
    path: APP_ROUTES.GOODS_SERVICES,
    title: "Goods/Services",
    count: "1",
    matchUrl: "/goods-services",
    key: "11"
  },
  {
    path: APP_ROUTES.CHATS,
    title: "Chats",
    count: "1",
    matchUrl: "/chats",
    key: "12"
  },
  {
    path: APP_ROUTES.SETTINGS,
    title: "Settings",
    count: "1",
    matchUrl: "/settings",
    key: "13"
  },
  {
    path: APP_ROUTES.SETTINGS_DETAILS,
    title: "Edit Profile",
    count: "2",
    matchUrl: "/settings/edit-profile",
    key: "14"
  },
  {
    path: APP_ROUTES.SETTINGS_CHANGE_PASS,
    title: "Change Password",
    count: "2",
    matchUrl: "/settings/change-pass",
    key: "15"
  }]
  return (
    <Row className="breadcrumbs-con">
      <Col xs="12" className="breadcrumbs-col">
        <div className="d-flex align-items-center justify-content-between">
          {/* <h4 className="mb-0 font-size-18">{title}</h4> */}
          <div className="page-title-right d-flex">
            {urlData.map((el, idx) => {
              if(pathname.startsWith(el.path)){
                if(el.count === "2"){
                  return(
                    <div key={el.key} className="d-flex align-items-center">
                      <img src={RightArrowIcon} alt="right arrow icon" className="mx-2 breadcrumb-img"/>
                      <div  >
                        <div className="breadcrumb-typo breadcrumb-typo-bold" to={el.path}>{el.title}</div>
                      </div>
                    </div>
                  )
                }else{
                  return(
                  <div key={el.key}>
                      {
                        pathnameArr.length > 2 ? (
                          
                          <div  >
                            <Link className="breadcrumb-typo" to={el.path}>{el.title}</Link>
                          </div>
                          ) : (
                          
                            <div  >
                              <div className="breadcrumb-typo breadcrumb-typo-bold" to={el.path}>{el.title}</div>
                            </div>
                            )
                      }
                  </div>)
                }                
            }
            })}
            {/* <ol className="breadcrumb m-0"> */}

              {/* {breadcrumbItems.map((item, key) => (
                <BreadcrumbItem key={key} active={key + 1 === itemLength}>
                  <Link to="#">{item.title}</Link>
                </BreadcrumbItem>
              ))} */}
            {/* </ol> */}
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItems: PropTypes.array,
  title: PropTypes.string
}

export default Breadcrumb
