// styling header status
const customStyles = {
  WarehouseIM: {
    container: provided => ({
      display: "inline-block",
      position: "relative",
      width: "100%",
      borderRadius: "4px",
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: "40px",
      borderColor: "#C9B9FF",
      // border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        borderColor: "#C9B9FF",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      color: "#000000",
      // backgroundColor: state.data.color,
      fontSize: '1rem',
      fontFamily: "'Urbanist', sans-serif ",
      textAlign: 'center'
    }),
  },
  addNewDept: {
    container: provided => ({
      display: "inline-block",
      position: "relative",
      width: "100%",
      borderRadius: "4px",
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: "45px",
      borderColor: "rgba(112, 77, 231, 1)",
      // border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        borderWidth: '2px',
        borderColor:"rgba(112, 77, 231, 1)",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      color: "#000000",
      // backgroundColor: state.data.color,
      fontSize: '1rem',
      fontFamily: "'Urbanist', sans-serif ",
      textAlign: 'center'
    }),
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    }
  },
}

export default customStyles
