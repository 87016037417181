import React, { useState } from "react";

import { Col, Row } from "reactstrap";
import MetaTags from "react-meta-tags";
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux'

import { Pagination } from "components/Pagination";
import { DataTable } from "components/DataTable";
import { AddNewGoodServices } from "components/AddNewGoodServices";

import { HeaderDataTable } from "../ComponentsProject/HeaderDataTable";
import { sortingOptions, goodsServicesHeader } from '../../constants';
import { Sorting } from 'components/Sorting';

import { getAllGoodsServices, updateGoodsServices } from 'api/api.service'

import editIcon from "../../assets/images/aiAPaiT/Table/edit-icon.svg";
import { METATAGS } from "../../constants";
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest";

const Listing = () => {

    const userType = useSelector(store => store?.userAuth?.user?.type)    
    const [isAddNewModalActive, setIsAddNewModalActive] = useState(false);
    const [editRowData, setEditRowData] = useState({});
    const [isToggleLoadding, setIsToggleLoading] = useState(false)

    // getting data from backend using custom hook
    const {
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        searchText,
        handleSearchChange,
        data,
        total,
        isFetching,
        request
    } = useLocalPaginatedRequest({
        requestFn: getAllGoodsServices,
        params: {...(userType !== 'ADMIN' && {'status': 'ACTIVE'})},
        deps: [],
        })

    // add goodservice modal activation handling
    const ButtonClickHanlder = (e) => {
        setIsAddNewModalActive(true)
    }

    // edit goodservice change handling
    const modalEditHandler = (name, id) => {
        setEditRowData({name, id});
        setIsAddNewModalActive(true);
    }

    // add goodservice modal close handling
    const modalCloseHandler = (isCreated) => {
        isCreated && request();
        setIsAddNewModalActive(false)
        setEditRowData({});
    }

    // update status handling
    const handleStatusButtonClick = async (e, id, status, name) => {
        e.preventDefault()
        try{
            let currentStatus = status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
            setIsToggleLoading(true)
            let res = await updateGoodsServices(id, {status: currentStatus, name: name});
            data.map(el => {
            if(el.id === id){
                el.status = currentStatus;
            }
            })
            toast.success("Status has been updated successfully.")
        }finally{
            setIsToggleLoading(false)
        }
    }

    return (
        <>
            <MetaTags> 
                <title>{METATAGS.GOODS_SERVICES}</title>
            </MetaTags>
            <Row className="p-3 case-management-con goods-services-con">
                <Col md={12} >
                    <HeaderDataTable
                        buttonTitle="Add New Good/Service"
                        searchPlaceholder="Search Goods/Services"
                        searchValue={searchText}
                        onSearchChange={handleSearchChange}
                        onButtonClick={ButtonClickHanlder}
                        isButton = {userType === 'ADMIN'}
                     />
                </Col>
                <Col md={12} className="goods-services-table-con">
                    <DataTable
                        data={data}
                        loading={isFetching}
                        config={[
                            {
                                title: goodsServicesHeader.CLASS_NO,
                                className: "sa-cust-name text-truncate goods-services-no-col",
                                render: (data) => data?.id || "-"
                            },
                            {
                                title: goodsServicesHeader.GOOD_SERVICES,
                                className: "bold-font sa-subadmin-name goods-services-name-col",
                                render: (data) => data?.name || "-"
                            },
                            ...(userType === 'ADMIN' ? [{
                              title: goodsServicesHeader.STATUS,
                              className: "sa-region goods-services-status-col",
                              render: (data) => {
                                return(
                                        <>                                
                                          <label className={`switch-dt ${isToggleLoadding && "switch-pointer-events"}`} onClick={(e) => handleStatusButtonClick(e,data?.id, data?.status, data?.name)}>
                                            <input 
                                            type="checkbox" 
                                            checked={data?.status === "ACTIVE"} 
                                            defaultChecked={data?.status === "ACTIVE"} 
                                            />
                                            <span className="slider-dt round-dt"></span>
                                          </label>
                                          <span className={`ms-3 toggle-btn-typo-dt ${data?.status === "ACTIVE" && "color-active-dt"}`}>
                                            {data?.status === "ACTIVE" ? "Active" : "Inactive"}
                                          </span>
                                        </>
                                    )
                              }
                            }]: []),
                            ...(userType === 'ADMIN' ? [{
                              title: goodsServicesHeader.ACTION,
                              className: "sa-action",
                              render: (data) => (
                                <>
                                  <span
                                      className=" pointer ms-3"
                                      onClick={(e) => modalEditHandler(data?.name, data?.id, data?.code)}
                                  >
                                      <img src={editIcon} alt="details icon" />
                                  </span>
                                </>)
                          }]: [])
                        ]}
                    />
                </Col>
                <Col md={12} >
                    <div className=" bg-white d-flex justify-content-between align-items-center py-3">
                        <Sorting 
                        options={sortingOptions}
                        changeHandler={onChangePageSize}/>
                        <Pagination
                            currentPage={page}
                            totalData={total}
                            onPageClick={handlePageClick}
                            className="pagination-contain"
                            rowLimit={pageSize}
                        />
                    </div> 
                </Col>
            </Row>
            <AddNewGoodServices
            modalActivator={isAddNewModalActive}
            modalCloseHandler={modalCloseHandler}
            modalData = {editRowData}
            isEdit={Object.keys(editRowData).length > 0}
             />
        </>
    );
};

export default Listing;
