/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Select from "react-select";
import Animated from "react-select/animated";

let styles = {
    container: (provided) => ({
        ...provided,
        display: "inline-block",
        position: "relative",
        width: "100%",
        borderRadius: "4px",
    }),
    control: (provided, state) => ({
        ...provided,
        borderWidth: "1px",
        borderColor: "#C9B9FF",
        minHeight: "40px",
        backgroundColor: "white",
        boxShadow: state.isFocused ? 0 : 0,

        "&:hover": {
            borderColor: "#C9B9FF"
        }
    })
};

class AutoComplete extends Component {
    render() {
        let {
            className = "",
            options,
            onInputChange = () => {},
            onChange,
            handleBlur = () => {},
            customStyles = undefined,
            maxMenuHeight,
            isClearable = false,
            placeholder = "",
            isLoading = false,
            value,
            isMulti = false,
            handleScroll = undefined,
            handleOnFocus = undefined,
            selectRef,
            isOptionDisabled = undefined,
            isDisabled,
            closeMenuOnSelect = true,
            isSearchable=false,
            ...rest
        } = this.props;
      
        styles = customStyles ? customStyles : styles;
        return (
            <Select
                isDisabled={isDisabled}
                onFocus={handleOnFocus}
                onBlur={handleBlur}
                ref={selectRef}
                onMenuScrollToBottom={handleScroll}
                className={className}
                options={options}
                onInputChange={onInputChange}
                onChange={onChange}
                styles={styles}
                maxMenuHeight={maxMenuHeight}
                isClearable={isClearable}
                placeholder={placeholder}
                closeMenuOnSelect={closeMenuOnSelect}
                isLoading={isLoading}
                value={value}
                isOptionDisabled={isOptionDisabled}
                isMulti={isMulti}
                components={Animated()}
                isSearchable={isSearchable}
                {...rest}
            />
        );
    }
}

export default AutoComplete;
