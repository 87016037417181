import PropTypes from "prop-types"
import React from "react"
import { Input, InputGroup, InputGroupText } from "reactstrap"

const TextInput = props => {
  const {
    className,
    name,
    placeholder,
    type = "text",
    onChange,
    value,
    children,
    appendIcon=false,
    iconFile,
    iconClickHandler= () => {},
    inputGroupClassName="",
    ...rest
  } = props
  return (
    <div className={`bv-text-input ${className}`}>
      <InputGroup className={inputGroupClassName}>
      
        <Input
          {...rest}
          name={name}
          className="form-control"
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          value={value}
          autoComplete="off"
        >
          {children}
        </Input>
        {appendIcon && 
        <InputGroupText className="appended-icon" onClick={iconClickHandler}>
          <img src={iconFile} />
        </InputGroupText>
      }

      </InputGroup>
    </div>
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  inputGroupClassName: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  children: PropTypes.any,
  iconFile: PropTypes.string,
  appendIcon: PropTypes.bool,
  iconClickHandler: PropTypes.func,
}

export default TextInput
