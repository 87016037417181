import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

export default function PdfDocumentViewer(props) {

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Document
      loading={"Loading Pdf.. "}
      renderMode="svg"
      file={props?.documentPath}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={(error) => console.log("Inside Error", error)}
    >
      {Array.from(
        new Array(numPages),
        (el, index) => (
          <Page
            wrap={false}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ),
      )}
    </Document>
  );
}