import { injectBaseConstantMethods } from "./BaseConstants";

const NotificationStatus = {
    READ: 1,
    UN_READ: 0
};
const displayTextKeys = {
    [NotificationStatus.READ]: "READ",
    [NotificationStatus.UN_READ]: "UNREAD"
};

const labelClass = {
    [NotificationStatus.READ]: "readed-notification",
    [NotificationStatus.UN_READ]: "unreaded-notification"
};

export default injectBaseConstantMethods(NotificationStatus, displayTextKeys, labelClass);
