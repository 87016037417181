import { ME_SUCCESS, SET_TOKEN, SET_FCM_TOKEN,
    UPDATE_NOTIFICATION_COUNT,
    UPDATE_NOTIFICATION_COUNT_SUCCESS,
    UPDATE_NOTIFICATION_COUNT_FAILURE } from "../actions";

const initialState = {
    isAuthenticated: false,
    user: {}, 
    userData: {},
    fcmToken: "",
    notificationCount: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ME_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: {
                    ...state.user,
                    ...action.payload
                }
            };
        case SET_TOKEN:
            return {
                ...state,
                user: {
                    ...state.user,
                    Token: action.token
                }
            };
        case SET_FCM_TOKEN:
            return {
                ...state,
                fcmToken: action.payload
            };

        case UPDATE_NOTIFICATION_COUNT:
            return {
                ...state
            };
        case UPDATE_NOTIFICATION_COUNT_SUCCESS:
            return {
                ...state,
                notificationCount: action?.payload
            };
        case UPDATE_NOTIFICATION_COUNT_FAILURE:
            return {
                ...state
            };
    
    
        default:
            return state;
    }
};

export default reducer;
