import { injectBaseConstantMethods } from "./BaseConstants";

export const STEPS_NAME = {
    Document_Submission: "FILING",
    E_Signature: "POAAPPLICATION",
    Publication: "PUBLICATION",
    Registration: "REGISTRATION",
    Tmcr: "TMCRDOWNLOAD"
};

const displayTextKeys = {
    [STEPS_NAME.Document_Submission]: "Document Submission",
    [STEPS_NAME.E_Signature]: "E Signature",
    [STEPS_NAME.Publication]: "Publication",
    [STEPS_NAME.Registration]: "Registration",
    [STEPS_NAME.Tmcr]: "TMCR Download"
};

const labelClass = {
    // [STEPS_NAME.Document_Submission]: "green-bg-text",
    // [STEPS_NAME.Completed]: "green-bg-text",
    // [STEPS_NAME.E_Signature]: "red-bg-text",
    // [STEPS_NAME.Pending]: "blue-bg-text",
    // [STEPS_NAME.Awaiting]: "yellow-bg-text"
};

export default injectBaseConstantMethods(STEPS_NAME, displayTextKeys, labelClass);
