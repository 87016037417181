import React, { useState } from "react";

import { Col, Row } from "reactstrap";
import MetaTags from "react-meta-tags";

import toast from "react-hot-toast";

import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest";
import { getAllCountries, updateCountry } from "api/api.service";
import { Pagination } from "components/Pagination";
import { DataTable } from "components/DataTable";
import { AddNewCountry } from "components/AddNewCountry";
import { HeaderDataTable } from "../ComponentsProject/HeaderDataTable";
import { sortingOptions, countriesHeader } from "../../constants";
import { Sorting } from "components/Sorting";
import { METATAGS } from "../../constants";

const Listing = () => {
    const [isToggleLoadding, setIsToggleLoading] = useState(false);
    const [isAddCountry, setIsAddCountry] = useState(false);
    const [addNewCountryValue, setAddNewCountryValue] = useState("");

    // fetching data from API using custom hook
    const {
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        searchText,
        handleSearchChange,
        data,
        total,
        isFetching,
        request
    } = useLocalPaginatedRequest({
        requestFn: getAllCountries,
        params: {},
        deps: []
    });

    // add country modal open handling
    const ButtonClickHanlder = (e) => {
        setIsAddCountry(true);
    };

    // add country modal close handling
    const AddCountryModalCloseHandler = (isCreated) => {
        isCreated && request();
        setIsAddCountry(false);
    };

    // on country select change handling
    const selectCountryHandler = (e) => {
        setAddNewCountryValue(e?.value);
    };

    // country status update handling
    const handleStatusButtonClick = async (e, id, status) => {
        e.preventDefault();
        try {
            let currentStatus = status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
            setIsToggleLoading(true);
            let res = await updateCountry(id, { status: currentStatus });
            data.map((el) => {
                if (el.id === id) {
                    el.status = currentStatus;
                }
            });
            toast.success("Status has been updated successfully.");
        } finally {
            setIsToggleLoading(false);
        }
    };

    return (
        <>
            <MetaTags>
                <title>{METATAGS.COUNTRIES}</title>
            </MetaTags>
            <Row className="p-3 case-management-con countries-con">
                <Col md={12}>
                    <HeaderDataTable
                        buttonTitle="Add New Country"
                        searchPlaceholder="Search countries"
                        searchValue={searchText}
                        onSearchChange={handleSearchChange}
                        onButtonClick={ButtonClickHanlder}
                    />
                </Col>
                <Col md={12} className="countries-table-con">
                    <DataTable
                        data={data}
                        loading={isFetching}
                        config={[
                            {
                                title: countriesHeader.NO,
                                className: " text-truncate countries-id-col",
                                render: (data) => data?.id || "-"
                            },
                            {
                                title: countriesHeader.COUNTRY,
                                className: " bold-font countries-region-col",
                                render: (data) => data?.name || "-"
                            },
                            {
                                title: countriesHeader.COUNTRY_CODE,
                                className: " bold-font countries-region-col",
                                render: (data) => data?.code || "-"
                            },
                            {
                                title: countriesHeader.STATUS,
                                className: " status-toggle-con ",
                                render: (data) => {
                                    return (
                                        <>
                                            <div>
                                                <label
                                                    className={`switch-dt ${
                                                        isToggleLoadding && "switch-pointer-events"
                                                    }`}
                                                    onClick={(e) =>
                                                        handleStatusButtonClick(
                                                            e,
                                                            data?.id,
                                                            data?.status
                                                        )
                                                    }
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={data?.status === "ACTIVE"}
                                                        defaultChecked={data?.status === "ACTIVE"}
                                                    />
                                                    <span className="slider-dt round-dt"></span>
                                                </label>
                                                <span
                                                    className={`ms-3 toggle-btn-typo-dt ${
                                                        data?.status === "ACTIVE" &&
                                                        "color-active-dt"
                                                    }`}
                                                >
                                                    {data?.status === "ACTIVE"
                                                        ? "Active"
                                                        : "Inactive"}
                                                </span>
                                            </div>
                                        </>
                                    );
                                }
                            }
                        ]}
                    />
                </Col>
                <Col md={12}>
                    <div className=" bg-white d-flex justify-content-between align-items-center py-3">
                        <Sorting options={sortingOptions} changeHandler={onChangePageSize} />
                        <Pagination
                            className="pagination-contain "
                            currentPage={page}
                            totalData={total}
                            onPageClick={handlePageClick}
                            rowLimit={pageSize}
                        />
                    </div>
                </Col>
            </Row>
            <AddNewCountry
                modalActivator={isAddCountry}
                modalCloseHandler={AddCountryModalCloseHandler}
                modalData={addNewCountryValue}
                selectCountryHandler={selectCountryHandler}
            />
        </>
    );
};

export default Listing;
