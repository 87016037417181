import React, { useState } from "react";
import PropTypes from 'prop-types';
import moment from 'moment'
import { useSelector } from "react-redux";
import toast from 'react-hot-toast'
import { useParams, useHistory } from "react-router-dom";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Col,
  Row,
  Card,
  Accordion,
  AccordionBody,
  Label,
  AccordionItem
} from "reactstrap";
import {
    finalizeMediaUtil,
    initializeMediaUtil,
    uploadOnS3,
} from "utils/mediaUtils"
import { removeSpaceUnderscoreCharacterFromString } from 'utils/commonUtils'
import { TextInput } from "components/Input";
import { CustomAccordionHeader } from "./Accordion";

import { ImageUpload } from "components/ImageUpload";
import { ImageTypes } from "../../constants"
import { CustomButton } from "components/CustomButton";
import { CaseRejection } from "components/CaseRejectionModal";
import { APP_ROUTES } from "helpers/routeHelpers";

import { createStepFour } from 'api/api.service'
import UploadLogo from "../../assets/images/aiAPaiT/case-mangement/upload-logo.png";

import DateIcon from '../../assets/images/aiAPaiT/case-mangement/date-icon.svg';
import ESignatureIcon from "../../assets/images/aiAPaiT/case-mangement/e-signature-icon.png";

const KB_CONVERTER = 1024
export default function Registration ({
  openAccord, 
  toggleButtonHandler=() => {}, 
  title="",
  targetId="",
  accordId="",
  processNO,
  data_description,
  refetchStepsAPIHandler}) {

  let dataObj = {}
  const params = useParams();

  const userType = useSelector(store => store?.userAuth?.user?.type)
  const [trademarkRegistrationMedia, setTrademarkRegistrationMedia] = useState({
      image: "",
      fileName: undefined,
      imageError: undefined
  })
  const [errorType, setErrorType] = useState(0)
  const [isSubmittingStepFour, setIsSubmittingStepFour] = useState(false)
  
  // formik validation for input fields
  const formik = useFormik({
    enableReinitialize : true,
    initialValues: {
        stepFees: "",
        feeDeadline: ""
    }, 
    validationSchema: Yup.object({
        stepFees: Yup.number().integer()
        .required('Step Fee is required.')
        .min(1, "Step Fee shouldn't be less than $1")
        .max(1000, "Step Fee shouldn't be greater than $1000"),
        feeDeadline: Yup.date().required("Please enter the date")
    }), 
    onSubmit: (values) => {
        dataObj.amount = values.stepFees
        dataObj.expiryDate = values.feeDeadline
        handleStepRegistrationSubmission()
    }
  })

  // file uploading
  const FileUploadHander = (file) => {
    let imageError = undefined;
    let fileName = undefined;
    let image = ""
    if (file) {
      const uploadedFileType = file?.type?.split("/")[1]?.toLowerCase()
      let fileSize = file && file?.size
      fileSize = fileSize / KB_CONVERTER
      if (!ImageTypes.includes(uploadedFileType)) {
        // setImageUploaded(true)
        imageError = "File type is not supported!"
        // setImageUploaded(false)
        fileName = undefined
        image = ""
        // setImagePreview("")
      } else {
        fileName = removeSpaceUnderscoreCharacterFromString(file?.name)
        imageError = ""
        image = file
        image.fileName = fileName
      }
    }
    return {imageError, image, fileName}
  }

  // handle file change
  const handleFileChange = (e, imageObjUpdater) => {
    e.preventDefault()
    if(userType === 'ADMIN'){
      toast.error("Admin can't process the case!")
      return
    }
    let file = e.target.files[0];
    const {image, imageError, fileName} = FileUploadHander(file)
    imageObjUpdater({image, imageError,fileName})
    setErrorType(0)
  }

  // hanlde remove image
  const removeImage = (imageObjUpdater) => {
    imageObjUpdater({image: "", imageError: undefined, fileName: undefined})
    setErrorType(1)
  }

  // media upload on server
  const handleMediaUpload = async file => {
    return initializeMediaUtil(file).then(async res => {
      const credentials = res
      await uploadOnS3(file, credentials)
      return await finalizeMediaUtil(credentials?.mediaId)
    })
  }

  // handle Form Submission using API on Backend
  const handleStepRegistrationSubmission = async () => {
    if(trademarkRegistrationMedia.image === ""){
      setErrorType(1)
      return
    }else{
          let payloadObj = {...dataObj}
          payloadObj.amount = Number(dataObj?.amount)
          payloadObj.expiryDate = moment(dataObj.expiryDate).format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
          payloadObj.documents = []
          setIsSubmittingStepFour(true)
          if (trademarkRegistrationMedia.image) {
            let imageRes = await handleMediaUpload(trademarkRegistrationMedia.image)
            payloadObj.documents.push({documentType: "TrademarkRegistration", mediaId: imageRes.id})
          }
          createStepFour(params?.id, payloadObj).then(res => {
            toast.success("Registration Step has been submitted successfully.")
            refetchStepsAPIHandler()
          }).catch(err => {
            setIsSubmittingStepFour(false)
          })
    }
  }

  return(
  <>
    <div className="case-detail-accordian-signature-con">
      <Accordion open={openAccord} toggle={toggleButtonHandler}>
          <AccordionItem>
              <CustomAccordionHeader
                  containerClassName="case-detail-accordian-container"
                  innerClassName="case-detail-accordian-header"
                  subContainerClassName="case-detail-accordian-header-status"
                  headerCount={processNO}
                  headerText={title}
                  status={"Open"}
                  targetId={targetId}
              />

              <AccordionBody
                  accordionId={accordId}
                  className="case-detail-accordian-body-con"
              >
                  <Card>
                    <form onSubmit={formik.handleSubmit}>
                    {userType !== 'ADMIN' ? 
                    (
                      <Row>
                          <Col md={12}>
                            <div className="d-flex">
                                <div className="case-detail-accordian-body">
                                  <div>Trademark Registration</div>
                                  <ImageUpload
                                    image={UploadLogo}
                                    inputId = {4}
                                    onChange={(e) => handleFileChange(e, setTrademarkRegistrationMedia)}
                                    fileName={trademarkRegistrationMedia?.fileName}
                                    imageError={trademarkRegistrationMedia?.imageError}
                                    removeImageHandler={() => removeImage(setTrademarkRegistrationMedia)}
                                    errorType={errorType === 1 ? "Kindly upload the file" : "" }
                                    />
                                </div>
                            </div>                              
                          </Col>
                          <Col md={6}>
                              <div className='bg-white p-3 pt-4 case-details-accordian-date-field'>
                                  <Label>Add Fee for this Step</Label>
                                  <div className="mb-3">
                                      <TextInput
                                          placeholder="Add Fee"
                                          name={"stepFees"}
                                          type="number"
                                          className="hide-icon"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.stepFees}
                                          autoFocus
                                      />
                                      {formik.touched.stepFees && formik.errors.stepFees ? (
                                          <span className="error">{formik.errors.stepFees}</span>
                                      ) : null}
                                  </div>                  
                              </div>
                              <div className='bg-white p-3 pt-0 case-details-accordian-date-field'>
                                <Label>Fee Deadline</Label>
                                <div className="mb-3">
                                    <TextInput
                                        name={"feeDeadline"}
                                        type="date"
                                        className="hide-icon"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.feeDeadline}
                                        autoFocus
                                        iconFile={DateIcon}
                                        min={moment().format('YYYY-MM-DD')}
                                    />
                                    {formik.touched.feeDeadline && formik.errors.feeDeadline ? (
                                        <span className="error">{formik.errors.feeDeadline}</span>
                                    ) : null}
                                </div>                  
                            </div>  
                          </Col>
                      </Row>): (
                      <>
                        <Row>
                            <div className="e-signature-footer-btn">
                                <img src={ESignatureIcon} height={40} width={40} />
                                <div className="accordian-body-footer-text">
                                    Case is in progress and waiting for department
                                    response
                                </div>
                            </div>
                        </Row>
                      </>)}
                      {userType !== 'ADMIN' ? 
                      (<>
                      <hr />
                      <Row>
                          <div className="accordian-body-footer-btn">
                              <CustomButton
                                  title="Send"
                                  className="trademark-body-footer-accept-button shadow-none me-3"
                                  color="#704DE7"
                                  outline={true}
                                  type={"submit"}
                                  loading={isSubmittingStepFour}
                              />
                          </div>
                      </Row>
                      </>): null}
                    </form>                                                                                        
                  </Card>
              </AccordionBody>
          </AccordionItem>
      </Accordion>
  </div>
  </>
  )
}

// handling prop types
Registration.propTypes = {
  openAccord: PropTypes.string,
  toggleButtonHandler: PropTypes.func,
  title: PropTypes.string,
  targetId: PropTypes.string,
  accordId: PropTypes.string,
  isPA: PropTypes.bool,
  processNO: PropTypes.string

}