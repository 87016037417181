import React from 'react';
import {
  Card,
} from "reactstrap";

const DepartmentHeader = ({data}) => {  
  return(
    <>
      <div className="dept-detail-container  py-2 bg-white dept-header-typo-sec dept-header-left-con">
        <div className="dept-detail-main-container mb-0">
          <div className='cm-header-con dpt-header-left'>
            <div className="dept-detail-form-fields-container">
                <p>Department Name</p>
                <div>{data?.name || "-"}</div>
            </div>
            <div className="dept-detail-form-fields-container">
                <p>Total Earnings</p>
                <div>{`$${data?.totalSpendings}` || "-"}</div>
            </div>
            <div className="dept-detail-form-fields-container">
                <p>Phone Number</p>
                <div>{`${data?.countryCode}${data?.phoneNumber}` || "-"}</div>
            </div>
            <div className="dept-detail-form-fields-container">
                <p>Email Address</p>
                <div>{data?.email || "-"} </div>
            </div>
          </div>
        </div> 
      </div>
    </>
  )
};

DepartmentHeader.propTypes = {

}

export default DepartmentHeader;