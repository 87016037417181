import PropTypes from "prop-types";
import React from "react";
import { Button } from "reactstrap";

import { Loader } from "components/Loader";
import "./CustomButton.scss";

const CustomButton = (props) => {
    const {
        className = "",
        loaderColor = "light",
        title,
        outline = false,
        loading = false,
        color,
        onClick,
        type,
        disabled,
        name
    } = props;
    return (
        <Button
            name={name}
            disabled={disabled}
            type={type}
            color={color}
            onClick={onClick}
            outline={outline}
            className={`bv-btn ${className} ${outline && "btn-outline-primary"}`}
        >
            {loading ? <Loader size={"sm"} color={loaderColor} /> : title}
        </Button>
    );
};

CustomButton.propTypes = {
    type: PropTypes.any,
    className: PropTypes.any,
    title: PropTypes.any,
    outline: PropTypes.bool,
    color: PropTypes.any,
    onClick: PropTypes.func,
    disabled: PropTypes.any,
    loading: PropTypes.bool,
    loaderColor: PropTypes.string,
    name: PropTypes.any
};

export default CustomButton;
