import React, { useState } from 'react';
import PropTypes from "prop-types"
import toast from 'react-hot-toast'
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import { TextInput } from "components/Input";
import { CustomModal } from "components/CustomModal";
import { CustomButton } from "components/CustomButton";
import { createRejection } from 'api/api.service'
import backIcon from "../../assets/images/aiAPaiT/back-arrow.svg";
const isReject = true;
const CaseRejection = ({ 
  modalActivator= false,
  modalCloseHandler= () => {},
  setIsRefetchingRequired, 
  stepId, 
  title
}) => {
  const params = useParams()
  const [isRejecttingFiling, setIsRejecttingFiling] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
        reason: ""
    },
    validationSchema: Yup.object({
        reason: Yup.string()
        .required("Please enter the reason")
        .min(3, "Reason should contain at least 3 letters")
    }),
    onSubmit: (values) => {
      apiHandlingReject(values)
    }
  });

  function updatePayload(title, payload){
    let tempObj = {...payload}
    switch(title){
      case "E Signature":
        tempObj.trademarkStep = "POAAPPLICATION"
        tempObj.pOAApplicationId = stepId?.id
        break;
      case "Publication":
        tempObj.trademarkStep = "PUBLICATION"
        tempObj.publicationId = stepId?.id
        break;
      case "Registration":
        tempObj.trademarkStep = "REGISTRATION"
        tempObj.registrationId = stepId?.id
        break;
      case "TMCR":
        tempObj.trademarkStep = "REGISTRATION"
        tempObj.registrationId = stepId?.id
        break;
      default:
        tempObj.trademarkStep = "FILING"
        tempObj.filingId = stepId?.id
    }
    return tempObj
  }

  const apiHandlingReject = async (data) => {
      try{       
      let payload = {...data};
      payload.trademarkId = Number(params?.id);
      payload.status= "Rejected"
      payload = updatePayload(title, payload)
      setIsRejecttingFiling(true)
      await createRejection(payload)
      modalCloseHandler(true)
      setIsRefetchingRequired(true)
      toast.success("Case has been rejected successfully.")
    } finally {
      setIsRejecttingFiling(false)
    }
}

  return(
    <>
      <CustomModal
        isOpen={modalActivator}
        className="reject-custom-modal"
        data={
            <div className="text-center reject-modal px-5 pb-4 mb-3">
              <form onSubmit={formik.handleSubmit}>
                <div className='d-flex  align-items-center mb-2'>
                  <img 
                  src={backIcon} 
                  alt="close modal icon"
                  onClick={() => modalCloseHandler(false)}
                  />
                  <p className="my-2 mx-auto">
                    Case Rejection
                  </p>
                </div>
                <div className='mb-4' style={{'textAlign': 'left'}}>
                  <div className=' text-area-con '>
                    <textarea
                    rows="4"
                    type="text-area"
                    className="text-area px-2"
                    value={formik.values.reason}
                    onBlur={formik.handleBlur}
                    name={"reason"}
                    onChange={formik.handleChange}

                    />
                  </div>
                  {formik.touched.reason && formik.errors.reason ? (
                        <span className="error">{formik.errors.reason}</span>
                    ) : null}
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <CustomButton
                      title="Reject Case"
                      className="w-100 me-2 btn-color shadow-none"
                      color="primary"
                      type="submit"
                      outline={true}
                      loading={isRejecttingFiling}
                      // onClick={apiHandlingReject}
                  />
                  <div
                      className="w-100 ms-2 shadow-none resume-case-btn"
                      onClick={() => modalCloseHandler(false)}
                  ><span>Resume Case</span></div>
                </div>
              </form> 
            </div>
        }
      />
    </>
  )
}

CaseRejection.propTypes = {
  modalActivator: PropTypes.bool,
  modalCloseHandler: PropTypes.func,
}

export default CaseRejection;