import React, { useState } from "react";
import PropTypes from 'prop-types';
import moment from 'moment'
import toast from 'react-hot-toast'
import { useParams, useHistory } from "react-router-dom";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Col,
  Row,
  Card,
  Accordion,
  AccordionBody,
  Label,
  AccordionItem
} from "reactstrap";
import {
    finalizeMediaUtil,
    initializeMediaUtil,
    uploadOnS3,
} from "utils/mediaUtils"
import { removeSpaceUnderscoreCharacterFromString } from 'utils/commonUtils'
import { createStepTwo } from 'api/api.service'
import { CaseRejection } from "components/CaseRejectionModal";
import { APP_ROUTES } from "helpers/routeHelpers";

import { ImageUpload } from "components/ImageUpload";
import { ImageTypes } from "../../constants"
import { TextInput } from "components/Input";
import { CustomButton } from "components/CustomButton";
import { CustomAccordionHeader } from "./Accordion";

import UploadLogo from "../../assets/images/aiAPaiT/case-mangement/upload-logo.png";

const KB_CONVERTER = 1024

export default function POAApplication ({    
    openAccord, 
    toggleButtonHandler=() => {}, 
    title="",
    targetId="",
    accordId="",
    isReject=false,
    isPA=false,
    processNO,
    dataObj,
    data_description,
    refetchStepsAPIHandler}){

    const params = useParams();
    const history = useHistory()

    const [rejectionSuccessModal, setRejectionSuccessModal] = useState(false);
    const [powerAttorneyImage, setPowerAttorneyImage] = useState({
      image: "",
      fileName: undefined,
      imageError: undefined
    })
    const [applicationFieldImage, setApplictionFieldImage] = useState({
      image: "",
      fileName: undefined,
      imageError: undefined
    })
    const [errorType, setErrorType] = useState(0)
    const [isSubmittingStepTwo, setIsSubmittingStepTwo] = useState(false)

    // formik validation for input fields
    const formik = useFormik({
    enableReinitialize : true,
    initialValues: {
        stepFees: ""
    }, 
    validationSchema: Yup.object({
        stepFees: Yup.number().integer()
        .required('Step Fee is required.')
        .min(1, "Step Fee shouldn't be less than $1")
        .max(1000, "Step Fee shouldn't be greater than $1000")
    }), 
    onSubmit: (values) => {
        dataObj.amount = values.stepFees
        handleStepPOAApplicationSubmission()
    }
  })

  // file uploading
  const FileUploadHander = (file) => {
      let imageError = undefined;
      let fileName = undefined;
      let image = ""
      if (file) {
        const uploadedFileType = file?.name?.split(".")[file?.name?.split(".").length-1]?.toLowerCase()
        let fileSize = file && file?.size
        fileSize = fileSize / KB_CONVERTER
        if (!ImageTypes.includes(uploadedFileType)) {
            // setImageUploaded(true)
            imageError = "File type is not supported!"
            // setImageUploaded(false)
            fileName = undefined
            image = ""
            // setImagePreview("")
        } else {
          fileName = removeSpaceUnderscoreCharacterFromString(file?.name)
          imageError = ""
          image = file
          image.fileName = fileName
        }
      }
      return {imageError, image, fileName}
  }

  // handle file input change
  const handleFileChange = (e, imageObjUpdater, type) => {
    e.preventDefault()
    let file = e.target.files[0];
    const {image, imageError, fileName} = FileUploadHander(file)
    imageObjUpdater({image, imageError,fileName})
    if(errorType === 3){
      type == "power" && !applicationFieldImage.image ? setErrorType(2):
      type == "application" && !powerAttorneyImage.image && setErrorType(1)     
    }else if( errorType === 1){
      type == "power" && !applicationFieldImage.image ? setErrorType(2):
      type == "power" && applicationFieldImage.image && setErrorType(0)
    }else if( errorType === 2){
      type == "application" && !powerAttorneyImage.image ? setErrorType(1):
      type == "application" && powerAttorneyImage.image && setErrorType(0)
    }
  }

  // hanlde remove image
  const removeImage = (imageObjUpdater) => {
    imageObjUpdater({image: "", imageError: undefined, fileName: undefined})
  }

  // media upload on server
  const handleMediaUpload = async file => {
    return initializeMediaUtil(file).then(async res => {
      const credentials = res
      await uploadOnS3(file, credentials)
      return await finalizeMediaUtil(credentials?.mediaId)
    })
  }

  // handle Form Submission using API on Backend
  const handleStepPOAApplicationSubmission = async () => {
    if(powerAttorneyImage.image === "" && applicationFieldImage.image === ""){
      setErrorType(3)
      return
    }else if(powerAttorneyImage.image === ""){
      setErrorType(1)
      return
    }else if(applicationFieldImage.image === ""){
      setErrorType(2)
      return
    }else{
          let payloadObj = {...dataObj}
          payloadObj.amount = Number(dataObj?.amount)
          payloadObj.expiryDate = moment(dataObj.expiryDate).format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
          payloadObj.documents = []
          setIsSubmittingStepTwo(true)
          if (applicationFieldImage.image) {
            let imageRes = await handleMediaUpload(applicationFieldImage.image)
            payloadObj.documents.push({documentType: "Application", mediaId: imageRes.id})
          }
          if (powerAttorneyImage.image) {
            let imageRes = await handleMediaUpload(powerAttorneyImage.image)
            payloadObj.documents.push({documentType: "PowerOfAttorney", mediaId: imageRes.id})
          }
          createStepTwo(params?.id, payloadObj).then((res) => {
            toast.success("E Signature Step has been submitted successfully.")
            refetchStepsAPIHandler()
          }).catch(err => {
            setIsSubmittingStepTwo(false)
          })
    }
  }

  // rejection modal close handling
  const rejectionModalCloseHandler = (isReject) => {
    isReject && history.push(APP_ROUTES.CASE_MANAGEMENT);
    setRejectionSuccessModal(false);
  };

  return(
  <>
    <div className={`case-detail-accordian-signature-con ${isReject && "accordion-disable-class"}`}>
      <Accordion open={openAccord} toggle={toggleButtonHandler}>
          <AccordionItem>
              <CustomAccordionHeader
                  containerClassName="case-detail-accordian-container"
                  innerClassName="case-detail-accordian-header"
                  subContainerClassName="case-detail-accordian-header-status"
                  headerCount={processNO}
                  headerText={title}
                  status={isReject ? "Rejected" : "Open"}
                  targetId={targetId}
              />
              <AccordionBody
                  accordionId={accordId}
                  className="case-detail-accordian-body-con"
              >
                  {!isReject ?
                  (<>
                  <Card>
                    <form onSubmit={formik.handleSubmit}>
                      <Row>
                      {isPA && (
                      <><Col md={3}>                              
                          <div className="d-flex">
                              <div className="case-detail-accordian-body">
                                  <div>Power of Attorney field</div>
                                    <ImageUpload
                                        image={UploadLogo}
                                        inputId = {1}
                                        onChange={(e) => handleFileChange(e, setPowerAttorneyImage, "power")}
                                        fileName={powerAttorneyImage?.fileName}
                                        imageError={powerAttorneyImage?.imageError}
                                        removeImageHandler={() => removeImage(setPowerAttorneyImage)}
                                        errorType={errorType === 1 || errorType === 3? "Kindly upload the file" : "" }
                                    />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                              <div className="d-flex">
                                  <div className="case-detail-accordian-body">
                                      <div>Application Field</div>
                                      <ImageUpload
                                            image={UploadLogo}
                                            inputId = {2}
                                            onChange={(e) => handleFileChange(e, setApplictionFieldImage, "application")}
                                            fileName={applicationFieldImage?.fileName}
                                            imageError={applicationFieldImage?.imageError}
                                            removeImageHandler={() => removeImage(setApplictionFieldImage)}
                                            errorType={errorType === 2 || errorType === 3 ? "Kindly upload the file" : "" }
                                        />
                                  </div>
                              </div>
                          </Col>
                          </>)}
                          {!isPA && (
                            <>
                            <Col md={12}>
                              <div className="d-flex">
                                  <div className="case-detail-accordian-body">
                                      <div> {title === "Publication" ? "Trademark Publication Form" : "Trademark Registration"}</div>
                                      <div className="case-detail-accordian-body-img size-modifier">
                                          <div>Upload File</div>
                                          <img src={UploadLogo} alt="UploadFile" />
                                      </div>
                                  </div>
                              </div>
                          </Col>
                            </>)}
                          <Col md={6}>
                              <div className='bg-white p-3 pt-4 case-details-accordian-date-field'>
                                  <Label>Add Fee for this Step</Label>
                                  <div className="mb-3">
                                      <TextInput
                                          placeholder="Add Fee"
                                          name={"stepFees"}
                                          type="number"
                                          className="hide-icon"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.stepFees}
                                          autoFocus
                                      />
                                      {formik.touched.stepFees && formik.errors.stepFees ? (
                                          <span className="error">{formik.errors.stepFees}</span>
                                      ) : null}
                                  </div>                  
                              </div>
                          </Col>
                      </Row>
                      <hr />
                      <Row>
                          <div className="accordian-body-footer-btn">
                              <CustomButton
                                  title="Send"
                                  className="trademark-body-footer-accept-button shadow-none me-3"
                                  color="#704DE7"
                                  type={"submit"}
                                  loading={isSubmittingStepTwo}
                              />
                               {title !== "E Signature" ? (<CustomButton
                                  title="Reject"
                                  className="trademark-body-footer-reject-button shadow-none"
                                  color="#E64A4A"
                                  onClick={() => {
                                      setRejectionSuccessModal(true);
                                  }}
                              />): null }
                          </div>
                      </Row> 
                    </form>                                   
                  </Card>
                  <CaseRejection
                        modalCloseHandler={rejectionModalCloseHandler}
                        modalActivator={rejectionSuccessModal}
                        stepId={data_description}
                        setIsRefetchingRequired={refetchStepsAPIHandler }
                        title={title}
                    />
                    </>):
                    (<>
                      <Card className="p-3">
                        <Row>
                          <Col>
                            <p className="rejection-reason"><span className="rejection-typo-heading">Rejection reason: </span>{data_description?.reason || "-"}</p>
                          </Col>                          
                        </Row>
                      </Card>
                    </>)
                                }
              </AccordionBody>
          </AccordionItem>
      </Accordion>
  </div>
  </>
  )
}

// handling prop types
POAApplication.propTypes = {
  openAccord: PropTypes.string,
  toggleButtonHandler: PropTypes.func,
  title: PropTypes.string,
  targetId: PropTypes.string,
  accordId: PropTypes.string,
  isReject: PropTypes.bool,
  isPA: PropTypes.bool,
  processNO: PropTypes.string,
  setRejectionSuccessModal: PropTypes.func,
}