import moment from 'moment'

const convertNumToWeekDays = (num) => {
  switch(num){
      case 1:
          return "Mon"
      case 2:
          return "Tues"
      case 3:
          return "Wed"
      case 4:
          return "Thru"
      case 5:
          return "Fri"
      case 6:
          return "Sat"
      case 6:
          return "Sun"
  }
}

const convertNumToMonths = (num) => {
  switch(num){
      case 1:
          return "Jan"
      case 2:
          return "Feb"
      case 3:
          return "Mar"
      case 4:
          return "Apr"
      case 5:
          return "May"
      case 6:
          return "Jun"
      case 7:
          return "Jul"
      case 8:
          return "Aug"
      case 9:
          return "Sep"
      case 10:
          return "Oct"
      case 11:
          return "Nov"
      case 12:
          return "Dec"
  }
}

export const findDaysRange = () => {
  let start_date = moment().startOf('isoweek').format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
  let end_date = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
  let diffAmongDays = moment().diff(moment().startOf('isoweek').format(), 'days') + 1
  let daysRange = []
  let datesRange = []
  console.log('diff:', diffAmongDays)
  for(let i=0; i<= diffAmongDays-1; i++){
      daysRange.push(convertNumToWeekDays(i+1))
      datesRange.push(moment(moment().startOf('isoweek'), "YYYY-MM-DD").add(i, 'days').format("YYYY-MM-DD"))
  }

  return {daysRange, datesRange, start_date , end_date}
}

export const findMonthsRange = () => {
  let start_date = moment().startOf('month').format()
  let end_date = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
  let daysRange = []
  let datesRange = []
  let dateToday = moment().format('DD');
  for(let i=0; i<=dateToday-1; i++){
      daysRange.push(i+1)
      datesRange.push(moment(moment().startOf('month'), "YYYY-MM-DD").add(i, 'days').format("YYYY-MM-DD"))
  }

  return {daysRange, datesRange, start_date , end_date}
}

export const findYearRange = () => {
  let daysRange = []
  let datesRange = []
  let start_date = moment().startOf('Year').format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
  let end_date = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
  let diffAmongMonths = moment().diff(moment().startOf('Year').format(), 'months') + 1
  for(let i=0; i<= diffAmongMonths; i++){
    daysRange.push(convertNumToMonths(i+1))
    datesRange.push(moment(moment().startOf('Year'), "YYYY-MM").add(i, 'months').format("YYYY-MM"))
  }

  return {daysRange, datesRange, start_date , end_date}
}

export const groupingDataWithDate = (data, groupingFormat) => {
  //grouping the data
  const groups = data.reduce((groups, item) => {
  const date = moment(item?.createdAt).format(groupingFormat);
  if (!groups[date]) {
      groups[date] = [];
  }
  groups[date].push(item);
  return groups;
  }, {});
  const responses = Object.keys(groups).map((date) => {
  let sumOfAmount = 0;
  groups[date]?.map(el => sumOfAmount = sumOfAmount + el?.amount)
  return{
      date,
      chat: sumOfAmount
  };
  });

  return responses
}

export const findOutFinalAmountArray = (data, tempDates) => {
    let finalAmount = []
    tempDates?.map(el => {
        let tempAmount = 0;
        data.map(innerEl => {
            if(innerEl?.date === el){
                tempAmount = tempAmount + innerEl?.chat
            }
        })
        finalAmount.push(tempAmount)
    })

    return finalAmount
}

export const findDateFormat = (durationType, findMonthsRange, findDaysRange, findYearRange) => {
    switch(durationType){
        case "months":
            return findMonthsRange
        case "weeks":
            return findDaysRange
        case "years":
            return findYearRange
    }
}
