import React, { useState } from "react";
import MetaTags from "react-meta-tags";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthWrapper from "./AuthWrapper";
import { TextInput } from "components/Input";
import { CustomButton } from "components/CustomButton";
import { APP_ROUTES } from "helpers/routeHelpers";


// action
// import { registerUser, apiError } from "../../store/actions";
import { METATAGS } from "../../constants";

import { Link } from "react-router-dom";

// import images
import loginImage from "assets/images/aiAPaiT/login/login-img.png";

const Register = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            vendorName: "",
            contactNumber: "",

            email: "",
            username: "",
            password: ""
        },

        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            username: Yup.string().required("Please Enter Your Username"),
            password: Yup.string().required("Please Enter Your Password")
        }),

        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    return (
        <React.Fragment>
            <MetaTags>
                <title>{METATAGS.LOGIN}</title>
            </MetaTags>
            <AuthWrapper
                leftContent={
                    <div className="text-center">
                        <h1 className="text-white fw-normal">
                            <span>WELCOME BACK</span>
                            <span className="d-block font-size-14">Please login to continue</span>
                        </h1>
                    </div>
                }
                data={
                    <div className="bv-login-wrap mt-5">
                        <p className="login-type mb-4">Continue Filling Your Details</p>
                        <form className="" onSubmit={formik.handleSubmit}>
                            <div className="mb-2">
                                <TextInput
                                    placeholder="Email Address"
                                    name={"email"}
                                    type="email"
                                    className="hide-icon"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    autoFocus
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <span className="error">{formik.errors.email}</span>
                                ) : null}
                            </div>
                            <div className="mb-2">
                                <TextInput
                                    placeholder="Password"
                                    type="password"
                                    className="hide-icon"
                                    name="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <span className="error">{formik.errors.password}</span>
                                ) : null}
                            </div>
                            <Link
                            className="d-flex align-items-center justify-content-end mb-5 login-forget-pass-typo"
                            to={APP_ROUTES.FORGET_PASSWORD}
                            >
                                Forgot Password?
                            </Link>
                            <CustomButton
                                loading={isLoading}
                                type="submit"
                                color="primary"
                                title="Login Now"
                                className="w-100 login-btn-typo"
                            />
                        </form>
                        
                        <Link
                            className="d-flex align-items-center justify-content-center mt-4 login-register-typo"
                            to={APP_ROUTES.REGISTER}
                        >
                            Register Now
                        </Link>
                        
                    </div>
                }
                leftImgUrl={loginImage}
            />
        </React.Fragment>        
    );
};

export default Register;
