import PropTypes from "prop-types"
import React from "react"
import { Container } from "reactstrap"

import "./AuthWrapper.scss"
import "./AuthWrapper-responsive.scss"

import logo from "../../assets/images/aiAPaiT/logo-primary-better.svg"

const AuthWrapper = props => {
  const { className = "", leftImgUrl, data } = props
  return (
    <div
      className={`cs-auth-wrapper ${className} d-flex align-items-stretch min-vh-100`}
    >
      <div className=" col-md-7 align-items-center auth-left-img-con">
        <Container className="h-100">
          <div className="d-flex flex-column justify-content-evenly h-100 py-3 ">
            <img
              src={leftImgUrl}
              className="img-fluid mx-auto d-block col-md-12 login-img"
            />
          </div>
        </Container>
      </div>
      <div className="d-flex align-items-center auth-right-text-con">
        <Container>
          <div className="offset-xxl-2 col-xxl-8 offset-md-2 col-md-8">
            <img src={logo} className="img-fluid-auth-logo mx-auto d-block" />
            {data}
          </div>
        </Container>
      </div>
    </div>
  )
}

AuthWrapper.propTypes = {
  className: PropTypes.any,
  leftContent: PropTypes.any,
  leftImgUrl: PropTypes.any,
  data: PropTypes.any,
}

export default AuthWrapper
