import React, { useState } from "react";
import moment from 'moment'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Accordion,
  AccordionBody,
  Label,
  AccordionItem
} from "reactstrap";
import { TextInput } from "components/Input";
import { CustomButton } from "components/CustomButton";
import { CustomAccordionHeader } from "./Accordion";
import TrademarkDetail from './TrademarkDetail';
import DocumentViewerModal from "./DocumentViewerModal";
import { CaseRejection } from "components/CaseRejectionModal";
import { APP_ROUTES } from "helpers/routeHelpers";

import DateIcon from '../../assets/images/aiAPaiT/case-mangement/date-icon.svg';
import ESignatureIcon from "../../assets/images/aiAPaiT/case-mangement/e-signature-icon.png";

export default function FilingCompleted ({
    openAccord, 
    toggleButtonHandler=() => {}, 
    data_description,
    nextStepHandler= () => {},
    processNO, 
    isCompleted,
    isFormTwoVisible=false,
    refetchStepsAPIHandler=() => {}}) {

    const history = useHistory()
    const userType = useSelector(store => store?.userAuth?.user?.type)
    let dataValue = isCompleted ? data_description?.expiryDate : "";

    const [isPdfViewerModalActive, setIsPdfViewerModalActive] = useState(false)
    const [pdfDataLink, setPdfDataLink] = useState("");
    const [rejectionSuccessModal, setRejectionSuccessModal] = useState(false);

    // rejection modal close handling
    const rejectionModalCloseHandler = (isReject) => {
        isReject && history.push(APP_ROUTES.CASE_MANAGEMENT);
        setRejectionSuccessModal(false);
    };

    // formik validation for input fields
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            feeDeadline: dataValue
        },
        validationSchema: Yup.object({
            feeDeadline: Yup.date().required("Please enter the date")
        }),
        onSubmit: (values) => {
            nextStepHandler(values)
        }
    });

    // pdf/image document view handling
    const documentViewerHandler = (dataUrl) => {
        setPdfDataLink(dataUrl)
        setIsPdfViewerModalActive(true)
    };

    // pdf/image document view modal close handling
    const pdfViewerModalCloseHandler = (e) => {
        setPdfDataLink("")
        setIsPdfViewerModalActive(false)
    }

  return(
    <>
    <div className={`case-detail-accordian-signature-con `}>
      <Accordion open={openAccord} toggle={toggleButtonHandler}>
          <AccordionItem>
              <CustomAccordionHeader
                  containerClassName="case-detail-accordian-container"
                  innerClassName="case-detail-accordian-header"
                  subContainerClassName="case-detail-accordian-header-status"
                  headerCount={processNO}
                  headerText="Document Submission"
                  status={isCompleted ? "Completed" : "Open"}
                  targetId="1"
              />
              <AccordionBody accordionId="1" className="case-detail-accordian-body-con">
                  <Card>
                    {data_description && 
                    data_description?.documents?.length > 0 && 
                    Object.keys(data_description).length > 0 ? (
                    <form onSubmit={formik.handleSubmit}>
                      <Row>
                          <Col md={6}>
                            <div className="case-detail-accordian-body">
                                <div>{"Description"}</div>
                                <p className="case-detail-accordian-body-value">
                                    {data_description?.description || "-"}
                                </p>
                            </div>
                            <div className="case-detail-accordian-body">
                                <div>{"Types of Goods/Services"}</div>
                                <p>
                                    {data_description?.goodsType || "-"}
                                </p>
                            </div>
                            <div className="case-detail-accordian-body">
                                <div>{"Applicant's name"}</div>
                                <p>
                                    {data_description?.applicantName || "-"}
                                </p>
                            </div>
                            <div className="case-detail-accordian-body">
                                <div>{"Country"}</div>
                                <p>
                                    {data_description?.countryName || "-"}
                                </p>
                            </div>
                            <div className="case-detail-accordian-body">
                                <div>{"Step Fee"}</div>
                                <p>
                                    {`$${data_description?.amount}`  || "-"}
                                </p>
                            </div>
                            {!isCompleted  && userType !== 'ADMIN' && 
                            (<div className='bg-white p-3 pt-4 case-details-accordian-date-field'>
                                <Label>Fee Deadline</Label>
                                <div className="mb-3">
                                    <TextInput
                                        name={"feeDeadline"}
                                        type="date"
                                        className="hide-icon"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.feeDeadline}
                                        autoFocus
                                        iconFile={DateIcon}
                                        disabled={isCompleted || isFormTwoVisible}
                                        min={moment().format('YYYY-MM-DD')}
                                    />
                                    {formik.touched.feeDeadline && formik.errors.feeDeadline ? (
                                        <span className="error">{formik.errors.feeDeadline}</span>
                                    ) : null}
                                </div>                  
                            </div> )}                                       
                          </Col>
                          <Col md={6}>
                            <div className="header">
                                  Commercial Registration/Passport
                              </div>
                            {data_description?.documents?.map((el, idx) => {
                                if(el?.documentType === "CommercialRegistrationPassport"){
                                    return (
                                        <div key={idx}>
                                            <TrademarkDetail
                                                documentData={el?.media}                                                
                                                documentViewerHandler={documentViewerHandler}
                                            />
                                        </div>
                                    )
                                }
                            })}

                            <div className="header">
                                  Trademark
                            </div>

                            {data_description?.documents?.map((el, idx) => {
                                if(el?.documentType === "Trademark"){
                                    return (
                                        <div key={idx}>
                                            <TrademarkDetail
                                                documentData={el?.media}
                                                documentViewerHandler={documentViewerHandler}
                                            />
                                        </div>
                                    )
                                } 
                                    
                            })}
                          </Col>
                      </Row>
                      {userType === 'ADMIN' && !isCompleted ? (
                      <>
                      <hr />
                        <Row>
                            <div className="e-signature-footer-btn">
                                <img src={ESignatureIcon} height={40} width={40} />
                                <div className="accordian-body-footer-text">
                                    Case is in progress and waiting for department
                                    response
                                </div>
                            </div>
                        </Row>
                      </>) : null }

                      {!isCompleted &&  userType !== 'ADMIN' && (
                        <>
                      <hr />
                      <Row>
                          <div className="accordian-body-footer-btn">
                              <CustomButton
                                  title="Move To Next Step"
                                  className="trademark-body-footer-accept-button shadow-none me-3"
                                  type={"submit"}
                                  disabled={isFormTwoVisible }
                              />
 
                              <CustomButton
                                  title="Reject"
                                  className="trademark-body-footer-reject-button shadow-none"
                                  color="#E64A4A"
                                  onClick={() => {
                                      setRejectionSuccessModal(true);
                                  }}
                              />
                          </div>
                      </Row>
                      </>
                      )}
                    </form>):(<><p className="data-not-found-message">Data Not Found</p></>)}
                  </Card>
                    <DocumentViewerModal
                    modalActivator={isPdfViewerModalActive}
                    modalCloseHandler={pdfViewerModalCloseHandler}
                    modalData={pdfDataLink}
                    isDataFetching={false}
                    />
                    <CaseRejection
                        modalCloseHandler={rejectionModalCloseHandler}
                        modalActivator={rejectionSuccessModal}
                        stepId={data_description}
                        setIsRefetchingRequired={refetchStepsAPIHandler}
                    />
              </AccordionBody>
          </AccordionItem>
      </Accordion>
    </div>
    </>
  )
}

// handling prop types
FilingCompleted.propTypes = {
  openAccord: PropTypes.string,
  toggleButtonHandler: PropTypes.func,
  data_description: PropTypes.any,
  status: PropTypes.bool,
  resetSuccessModal: PropTypes.func,
  nextStepHandler: PropTypes.func,
  setRejectionSuccessModal: PropTypes.func,
  processNO: PropTypes.string
}
