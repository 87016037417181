import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types"; 
import toast from 'react-hot-toast';
import moment from 'moment';
import { CustomModal } from "components/CustomModal";
import { CustomButton } from "components/CustomButton";
import { pdfFromHTML } from 'utils/downloadUtils';
import { findStepNum } from 'utils/commonUtils';
import { stepsName } from '../../constants';
import dashboardIcon from "../../assets/images/aiAPaiT/logo-primary-better.svg";

let totalAmount = 0;
const DownloadReceiptModal = ({
    modalActivator = false,
    modalCloseHandler = () => {},
    modalData = [],
    isDataFetching= false,
    caseRelatedData={}
}) => {

  const [isDownloadPdfLoading, setIsDownloadPdfLoading] = useState(false)
  const divToPrint = useRef();

  function printDocument() {
    try{
      setIsDownloadPdfLoading(true);
      pdfFromHTML(divToPrint)
    }catch{
      toast.error("Oops! Something went wrong!")
    }finally{
      setIsDownloadPdfLoading(false);
      modalCloseHandler()
    }
  }

  const ButtonClickHanlder = (e) => {
    printDocument()
  }

  useEffect(() => {
    if(modalData.length > 0){
      let sum = 0;
      modalData.map(el => {
        sum = sum + Number(el.amount)
      })
      totalAmount = sum;
    }
  }, [modalData])

    return (
        <>
            <CustomModal
                isOpen={modalActivator}
                className="download-receipt-custom-modal"
                onClose={modalCloseHandler}
                modalCloseIconClass="close-modal-icon pb-0"
                data={
                  <>
                  {!isDataFetching ? 
                  (<>
                  <div className='p-5 bg-white reciept-con pt-0' id="printDiv" ref={divToPrint}>
                    <div className="receipt-logo-con mb-4 mx-auto">
                      <img src={dashboardIcon} className="receipt-logo-img"/>
                    </div>
                    <h1 className='title mb-3'>{caseRelatedData?.caseTitle}</h1>
                    <p className='case-id mb-5'>Case ID: {caseRelatedData?.caseId}</p>
                    {modalData?.map((el, idx) => (
                    <div className='step-body-con p-3 mb-3' key={idx}>
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='process-title'>ID:</p>
                        <p className='case-date'>{el?.transactionId}</p>
                      </div>
                      <hr className='hor-line'/>

                      <div className='d-flex justify-content-between align-items-center'> 
                        <p className='process-title'>Date:</p>
                        <p className='case-date'>{moment(el?.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                      </div>
                      <hr className='hor-line'/>
                      <div className='d-flex justify-content-between align-items-center '>
                        <p className='process-title'>Process</p>
                        <p className='process-name'><span className='step-num'>{findStepNum(el?.trademarkStep)}</span> {stepsName.getDisplayTextKey(el?.trademarkStep)}</p>
                      </div>
                      <div className='d-flex justify-content-between align-items-center mt-4'>
                        <p className='process-title'>Card Number</p>
                        <p className='process-payment'>**** **** **** 1200 </p>
                      </div>
                      <hr className='hor-line'/>
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='process-title'>Payment</p>
                        <p className='process-fee'>${el?.amount}</p>
                      </div>
                    </div>))}
                    <div className='d-flex justify-content-between align-items-center mt-5 px-3'>
                      <h1 className='total-payment'>Total Amount </h1>
                      <p className='final-payment-digits'>${totalAmount}</p>
                    </div>                  

                  </div>
                  <div className="bg-white p-3 earning-header-btn text-center mb-5">
                  <CustomButton
                      loading={isDownloadPdfLoading}
                      color="primary"
                      title="Download Receipt"
                      onClick={ButtonClickHanlder}
                      className="login-btn-typo search-btn-header-btn"
                  />
                </div></>):(<></>)}
                  </>
                }
            />
        </>
    );
};

DownloadReceiptModal.propTypes = {
    modalActivator: PropTypes.bool,
    modalCloseHandler: PropTypes.func,
    modalData: PropTypes.array,
    isDataFetching: PropTypes.bool,
};

export default DownloadReceiptModal;
