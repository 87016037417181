import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import moment from "moment";
import { store } from "store";

import { SearchBox } from "components/SearchBox";
import Chat from "./Chat";
import { METATAGS } from "../../constants";
import { getAllChats } from "api/api.service";
import { Loader } from "components/Loader";
import { Socket, SocketTypes } from "../../utils/socketUtils";
import UserAvatar from "../../assets/images/aiAPaiT/chat/avatar-icon.svg";
import backIcon from "../../assets/images/aiAPaiT/back-arrow.svg";

var _ = require("lodash");

const Listing = () => {
    const [isSidebarToggleActive, setIsSidebarToggleActive] = useState(true);
    const [ChatListing, setChatListing] = useState([]);
    const [loading, setLoading] = useState(false);
    const [chat, setChat] = useState(null);
    const [total, setTotal] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [fetchData, setFetchData] = useState(false);
    const chatListAbortController = useRef(null);
    const history = useHistory();
    const params = useParams();

    const userId = store?.getState()?.userAuth?.user?.id;
    let isFirstTimeSearching = true;

    useEffect(() => {
        function handleMessageRecieved(newMsg) {
            const itemToUpdate = ChatListing.find((item) => item.id === newMsg.chatEvent.chatId);
            itemToUpdate.lastEvent = newMsg.chatEvent;
            if (newMsg?.chatEvent?.chatId == window.location.pathname.split("/")[2]) {
                Socket.readMessage(newMsg.chatEvent.id, newMsg.chatEvent.chatId, (data) => {});
                itemToUpdate.isLastEventReaded = true;
            } else {
                itemToUpdate.isLastEventReaded = false;
            }
            const updatedData = ChatListing.filter((item) => item.id !== newMsg.chatEvent.chatId);
            setChatListing([itemToUpdate, ...updatedData]);
        }
        Socket.onMessageRecieved(handleMessageRecieved);
        return () => {
            Socket.remove(SocketTypes.MESSAGE, handleMessageRecieved);
        };
    }, [ChatListing]);

    useEffect(async () => {
        setLoading(true);

        chatListAbortController.current = new AbortController();

        const res = await getAllChats(
            {
                page: page,
                column: "updatedAt",
                direction: "DESC",
                limit: 10,
                ...(searchText && { Q: searchText })
            },
            chatListAbortController.current.signal
        );
        setTotal(res?.total);
        let updatedData = [...ChatListing, ...res.chats];
        setChatListing(updatedData);
        setLoading(false);
    }, [page, fetchData]);

    const toggleSidebar = () => {
        setIsSidebarToggleActive(!isSidebarToggleActive);
    };

    const handleChatList = (chats) => {
        history.push(`/chats/${chats?.id}`);
        chats.isActive = true;
        chats.isLastEventReaded = true;
        setChat(chats);
    };

    const handleScroll = (e, noScroll = false) => {
        if (ChatListing?.length >= total) return;
        let currentPage = page + 1;
        setPage(currentPage);
    };

    const handleSearchChange = async (e) => {
        const searchTextTemp = e.target.value;
        if (searchTextTemp == "") {
            setSearchText("");
            setChatListing([]);
            page === 1 && setFetchData(!fetchData);
            setPage(1);
            return;
        }
        setLoading(true);
        const res = await getAllChats(
            {
                page: isFirstTimeSearching ? 1 : page,
                column: "updatedAt",
                direction: "DESC",
                limit: 10,
                ...(searchTextTemp && { Q: searchTextTemp })
            },
            chatListAbortController.current.signal
        );
        setTotal(res.total);
        let updatedData = [...res?.chats];
        setChatListing(updatedData);
        setLoading(false);
        setSearchText(searchTextTemp);
        isFirstTimeSearching = false;
    };

    const handleMessageSent = ({ message }) => {
        let obj = _.cloneDeep(ChatListing);
        const itemToUpdate = obj?.find(
            (item) => item?.id == window.location.pathname.split("/")[2]
        );
        itemToUpdate.lastEvent.content = message;
        itemToUpdate.lastEvent.updatedAt = new Date();

        const updatedData = obj?.filter(
            (item) => item?.id != window.location.pathname.split("/")[2]
        );
        setChatListing([itemToUpdate, ...updatedData]);
    };

    const timeConversions = (updatedAt) => {
        if (updatedAt) {
            const timestamp = moment(updatedAt);
            if (moment().diff(timestamp, "days") >= 2) {
                // More than 2 days ago, render the full date and time
                const formattedDate = timestamp.format("MMMM Do YYYY");
                return formattedDate;
            } else {
                // Less than 2 days ago, render the relative time
                const relativeTime = timestamp.fromNow();
                return relativeTime;
            }
        }
    };

    return (
        <>
            <MetaTags>
                <title>{METATAGS.CHAT}</title>
            </MetaTags>

            <div className="p-3 chat-main-con">
                <div className="row">                    
                    <div
                        className={`recipients-conversation-container col-md-12 col-lg-4 col-12 ${
                            isSidebarToggleActive ? "active" : ""
                        }`}
                    >
                        <div className="bg-white py-4 radius shadow form recipients-conversation-box">
                            <>
                                <div className="d-flex d-lg-none justify-end mx-4 mb-1">
                                    <img
                                        src={backIcon}
                                        alt="close modal icon"
                                        onClick={toggleSidebar}
                                    />
                                </div>
                                <div className="d-flex justify-space-between mx-4">
                                    <SearchBox
                                        iconClass="main-header-search-icon"
                                        className="main-header-search-box my-3"
                                        placeholder="Search users"
                                        value={searchText}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                {loading && page == 1 ? (
                                    <Loader parentClasses="loaderClasses" />
                                ) : ChatListing && ChatListing?.length == 0 && !loading ? (
                                    <div className="bg-white empty-state">No Data</div>
                                ) : (
                                    <div className="ma-0 pa-0 elevation-0 recipients-list recipients-panel-height fancy-scroll">
                                        <div className="recipients-list-item ">
                                            {ChatListing?.map((chat, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => handleChatList(chat)}
                                                    className="cursor-pointer"
                                                >
                                                    <div
                                                        className={`d-flex justify-start align-center ma-0 py-3 mb-3 pe-4 recipients ${
                                                            chat?.isActive &&
                                                            chat?.id == params?.id
                                                                ? "recipient-active"
                                                                : null
                                                        } `}
                                                    >
                                                        <img
                                                            className="mx-4"
                                                            src={UserAvatar}
                                                            style={{
                                                                maxWidth: "40px",
                                                                height: "40px"
                                                            }}
                                                        />
                                                        <div className="d-flex flex-column flex-fill ml-3 justify-center recipients-row">
                                                            <span className="recipients-name text-truncate title-width">
                                                                {chat?.caseTitle}
                                                            </span>

                                                            <span className="d-flex mt-1 justify-content-between">
                                                                <span
                                                                    className={`recipients-question justify-center align-center ml-4 text-truncate d-inline-block last-msg-width ${
                                                                        chat?.lastEvent
                                                                            ?.senderId !==
                                                                            userId &&
                                                                        (chat?.isLastEventReaded
                                                                            ? ""
                                                                            : "bold")
                                                                    }`}
                                                                >
                                                                    {chat?.lastEvent?.content
                                                                        ? chat?.lastEvent
                                                                                ?.content
                                                                        : "Anyone to respond the questions asked?"}
                                                                </span>
                                                                <div className="recipients-list-time">
                                                                    <span>
                                                                        {timeConversions(
                                                                            chat?.lastEvent
                                                                                ?.updatedAt
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {loading ? (
                                            <Loader />
                                        ) : ChatListing?.length >= total ? null : (
                                            <span className="load-more" onClick={handleScroll}>
                                                Load more
                                            </span>
                                        )}
                                    </div>
                                )}
                                
                            </>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-8">
                        {chat ? (
                            <div className="bg-white py-4 radius shadow conversation-height conversation-chat-container right-chat-panel-con p-3">
                                <Chat
                                    userData={chat}
                                    isSidebarToggleActive={isSidebarToggleActive}
                                    setIsSidebarToggleActive={setIsSidebarToggleActive}
                                    onMessageReceived={handleMessageSent}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Listing;
