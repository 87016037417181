import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from 'react-hot-toast';
import MetaTags from "react-meta-tags";
import PhoneInput from "react-phone-number-input";
import { Label } from "reactstrap";

import { CustomButton } from "components/CustomButton";
import { ResetPasswordModal, VerifyOTPModal } from "components/ResetPasswordModals";
import { SuccessModalCustom } from "components/SuccessModalCustom";
import { APP_ROUTES } from "helpers/routeHelpers";
import { METATAGS, preferredCountriesList,defaultCountry  } from "../../constants";
import { ForgetPasswordApi, ForgetPasswordVerification, ResetPassword, ResendOtp } from "api/api.service";

const ChangePassword = () => {

    const userObj = useSelector(store => store?.userAuth?.user);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState("");
    const [isOTPModalActive, setIsOTPModalActive] = useState(false);
    const [isChangePassModalActive, setIsChangePassModalActive] = useState(false);
    const [resetSuccessModal, setResetSuccessModal] = useState(false);
    const [isResetOTPLoading, setIsResendOTPLoading] = useState(false);

    // OTP request API submission handling
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let res = await ForgetPasswordApi({phone: userObj?.phone, countryCode: userObj?.countryCode});
        toast.success("OTP has been sent successfully")
        setToken(res?.token);
        setIsOTPModalActive(true);
    };

    // OTP submission API handling
    const resetOTPChangeHandler = async (data) => {
        let res = await ForgetPasswordVerification({token: token, code: data});
        setToken(res?.token);
        setIsOTPModalActive(false);
        setIsChangePassModalActive(true);
    };

    // resend OTP API request handling
    const  OTPResetHandler = async () => {
        setIsResendOTPLoading(true)
        let res = await ResendOtp({token: token, phone: userObj?.phone, countryCode: userObj?.countryCode });
        toast.success("OTP has been sent successfully")
        setToken(res?.token);
        setIsResendOTPLoading(false)
    }

    // reset password API submission
    const handleChangePasswordSubmit = async (data) => {
        let res = await ResetPassword({token: token, password: data?.newPassword});
        setToken("");
        setIsChangePassModalActive(false);
        setResetSuccessModal(true);
    };

    // success modal close handling
    const modalCloseHandler = () => {
        setResetSuccessModal(false);
        history.push(APP_ROUTES.SETTINGS);
    };

    // OTP modal close handling
    const handleOTPModalClose = () => {
        setIsOTPModalActive(false)
    }

    // change password modal close handling
    const handleChangePassModalClose = () => {
        setIsChangePassModalActive(false)
    }

    return (
        <>
            <MetaTags>
                <title>{METATAGS.CHANGE_PASSWORD}</title>
            </MetaTags>
            <div className="bv-login-wrap mt-5 edit-profile-con mb-5">
                <form className="" >
                    <Label>Phone Number</Label>
                    <div className="mb-4">
                        <PhoneInput
                            defaultCountry={defaultCountry}
                            countryOptionsOrder={preferredCountriesList}
                            onChange={(values) =>
                                console.log(values)
                            }
                            value={`${userObj?.countryCode}${userObj?.phone}`}
                            name="phoneNum"
                            className="phone-num-con phone-num-con-change-pass"
                            readOnly={true}
                        />
                    </div>
                    <CustomButton
                        loading={isLoading}
                        onClick={handleFormSubmit}
                        color="primary"
                        title="Get OTP"
                        className="w-100 login-btn-typo mb-5"
                    />
                </form>
            </div>
            <ResetPasswordModal
                modalActivator={isChangePassModalActive}
                handlePasswordSubmit={handleChangePasswordSubmit}
                modalCloseHandler={handleChangePassModalClose}
            />
            <VerifyOTPModal
                modalActivator={isOTPModalActive}
                submitDataHandler={resetOTPChangeHandler}
                OTPResetHandler={OTPResetHandler}
                modalCloseHandler={handleOTPModalClose}
                isResetOTPLoading={isResetOTPLoading}
            />
            <SuccessModalCustom
                btnTitle="Done"
                mainContent="Password has been updated successfully"
                modalActivator={resetSuccessModal}
                modalCloseHandler={modalCloseHandler}
            />
        </>
    );
};

export default ChangePassword;
