import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from 'react-router';
import { useParams, useLocation } from "react-router-dom";
import { CaseStatus, ResponseStatus, METATAGS } from "../../constants";
import FilingCompleted from "./FilingCompleted";
import POAApplication from "./POAApplication";
import POAApplicationCompleted from "./POAApplicationCompleted";
import Publication from "./Publication";
import PublicationAwaiting from "./PublicationAwaiting";
import Registration from "./Registration";
import RegistrationAwaiting from "./RegistrationAwaiting";
import TMCRDownload from "./TMCRDownload";
import TMCRAwaiting from "./TMCRAwaiting";
import { GetCaseById } from "api/api.service";
import { Loader } from "components/Loader";
import "./CaseManagement.scss";
import { CaseManagementHeader } from "components/CaseManagementHeader";

let dataObj = {};

const Details = () => {
    const params = useParams();
    const location = useLocation();
    const [open, setOpen] = useState("1");
    const [data, setData] = useState({});
    const [isFormTwoVisible, setIsFormTwoVisible] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isRefetchingRequired, setIsRefetchingRequired] = useState(false);

    // refetch steps API handler
    const refetchStepsAPIHandler = () => {
        setIsRefetchingRequired(!isRefetchingRequired)
    }
    // accordion header click open/close handling
    const toggle = (id) => {
        if (open === id) {
            setOpen("");
        } else {
            setOpen(id);
        }
    };

    // first step to second step handling
    const nextStepHandler = (data) => {
        dataObj.expiryDate = data?.feeDeadline;
        setIsFormTwoVisible(true);
        setOpen("4");
    };

    const nextStepHandlerForOtherSteps = (accordianId) => {
        setIsFormTwoVisible(true);
        setOpen(accordianId)
    }

    // open the required accordion according to stepStatus and stepName handling
    function findOutAccordianId(step, stepStatus, updateTarget){
        if(step === "FILING" && stepStatus === "Rejected"){
        updateTarget("64");
        }else if( step === "FILING" ){
        updateTarget("1");
        }else if( step === "POAAPPLICATION" && stepStatus === "Awaiting"){
        updateTarget("54");
        }else if( step === "PUBLICATION" && stepStatus === "Rejected"){
        updateTarget("46");
        }else if( step === "PUBLICATION" && stepStatus === "Awaiting"){
        updateTarget("5");
        }else if( step === "PUBLICATION" && stepStatus === "Pending"){
        updateTarget("3");
        }else if( step === "REGISTRATION" && stepStatus === "Rejected"){
        updateTarget("39");
        }else if( step === "REGISTRATION" && stepStatus === "Awaiting"){
        updateTarget("29");
        }else if( step === "REGISTRATION" && stepStatus === "Pending"){
        updateTarget("27");
        }else if( step === "TMCRDOWNLOAD" && stepStatus === "Rejected"){
        updateTarget("19");
        }else if( step === "TMCRDOWNLOAD" && stepStatus === "Awaiting"){
        updateTarget("9");
        }else if( step === "TMCRDOWNLOAD" && stepStatus === "Pending"){
        updateTarget("16");
        }else{
            updateTarget("");
        }
    }

    // refetching the data from API handling
    useEffect(async () => {
        try {
            setIsFetchingData(true);
            let res = await GetCaseById(params?.id);
            let stepStatus = ResponseStatus.getDisplayTextKey(res?.trademarkStepsStatus)
            findOutAccordianId(res?.trademarkStep, stepStatus, setOpen)
            setData(res);
        } finally {
            setIsFetchingData(false);
            setIsFormTwoVisible(false);
        }
    }, [isRefetchingRequired, params?.id, location?.state?.isSameId]);

    return (
        <>
            <MetaTags>
                <title>{METATAGS.CASE_DETAILS}</title>
            </MetaTags>
            {
                isFetchingData ? (<><Loader /></>) : null
            }
            {!isFetchingData && Object.keys(data).length > 0 && (
                <div className="case-detail-container">
                    <CaseManagementHeader data={data}/>

                    {/** if all steps are completed */}
                    {data?.trademarkStep === "TMCRDOWNLOAD" &&
                        CaseStatus.getDisplayTextKey(data?.trademarkStatus) === "FileDownloaded" &&
                        ResponseStatus.getDisplayTextKey(data?.trademarkStepsStatus) ===
                            "Completed" && (
                            <>
                                <FilingCompleted
                                    openAccord={open}
                                    toggleButtonHandler={toggle}
                                    data_description={data?.filing}
                                    isCompleted={true}
                                    processNO={"01"}
                                    setIsRefetchingRequired={setIsRefetchingRequired}
                                />
                                <POAApplicationCompleted
                                    openAccord={open}
                                    toggleButtonHandler={toggle}
                                    title={"E Signature"}
                                    isCompleted={true}
                                    targetId={"34"}
                                    accordId={"34"}
                                    isPA={true}
                                    processNO={"02"}
                                    data_description={data?.pOAApplication}
                                />
                                <POAApplicationCompleted
                                    openAccord={open}
                                    toggleButtonHandler={toggle}
                                    title={"Publication"}
                                    isCompleted={true}
                                    targetId={"7"}
                                    accordId={"7"}
                                    isPA={false}
                                    processNO={"03"}
                                    data_description={data?.publication}
                                />
                                <POAApplicationCompleted
                                    openAccord={open}
                                    toggleButtonHandler={toggle}
                                    title={"Registration"}
                                    isCompleted={true}
                                    targetId={"10"}
                                    accordId={"10"}
                                    isPA={false}
                                    processNO={"04"}
                                    data_description={data?.registration}
                                />
                                <POAApplicationCompleted
                                    openAccord={open}
                                    toggleButtonHandler={toggle}
                                    title={"TMCR"}
                                    fileDownload={true}
                                    isCompleted={true}
                                    targetId={"13"}
                                    accordId={"13"}
                                    isPA={false}
                                    processNO={"05"}
                                    data_description={data?.tmcrDownload}
                                />
                            </>
                        )}

                    {/** handling document submission and e-signature steps submission */}
                    {data?.trademarkStep === "FILING" &&
                        CaseStatus.getDisplayTextKey(data?.trademarkStatus) === "Open" && (
                            <>
                                <FilingCompleted
                                    openAccord={open}
                                    toggleButtonHandler={toggle}
                                    data_description={data?.filing}
                                    isCompleted={false}
                                    processNO={"01"}
                                    nextStepHandler={nextStepHandler}
                                    isFormTwoVisible={isFormTwoVisible}
                                    refetchStepsAPIHandler={refetchStepsAPIHandler}
                                />
                                {isFormTwoVisible ? (
                                    <>
                                        <POAApplication
                                            openAccord={open}
                                            toggleButtonHandler={toggle}
                                            title={"E Signature"}
                                            targetId={"4"}
                                            accordId={"4"}
                                            isPA={true}
                                            processNO={"02"}
                                            dataObj={dataObj}
                                            refetchStepsAPIHandler={refetchStepsAPIHandler}
                                        />
                                    </>
                                ) : null}
                            </>
                        )}

                    {/** handling document submission and e-signature steps rejection */}
                    {data?.trademarkStep === "FILING" &&
                    CaseStatus.getDisplayTextKey(data?.trademarkStatus) === "Closed" &&
                    data?.trademarkStepsStatus === "Rejected" ? (
                        <>
                            <FilingCompleted
                                openAccord={open}
                                toggleButtonHandler={toggle}
                                data_description={data?.filing}
                                isCompleted={true}
                                processNO={"01"}
                                />
                            <POAApplication
                                openAccord={open}
                                toggleButtonHandler={toggle}
                                title={"E Signature"}
                                data_description={data}
                                targetId={"64"}
                                accordId={"64"}
                                isReject={true}
                                processNO={"02"}
                            />
                        </>
                    ) : null}

                    {/** handling document submission and e-signature steps awaiting */}
                    {data?.trademarkStep === "POAAPPLICATION" && (
                        <>
                            <FilingCompleted
                                openAccord={open}
                                toggleButtonHandler={toggle}
                                data_description={data?.filing}
                                isCompleted={true}
                                processNO={"01"}
                            />
                            {CaseStatus.getDisplayTextKey(data?.trademarkStatus) === "Open" ? (
                                <>
                                    {ResponseStatus.getDisplayTextKey(
                                        data?.trademarkStepsStatus
                                    ) === "Awaiting" ? (
                                        <>
                                            <POAApplicationCompleted
                                                openAccord={open}
                                                toggleButtonHandler={toggle}
                                                title={"E Signature"}
                                                status={"Open"}
                                                isCompleted={true}
                                                targetId={"54"}
                                                isAwaiting={true}
                                                accordId={"54"}
                                                isPA={true}
                                                processNO={"02"}
                                                data_description={data?.pOAApplication}
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : null}
                        </>
                    )}

                    {/** handling publication step submission, awaiting and rejection */}
                    {data?.trademarkStep === "PUBLICATION" &&
                        (
                            <>
                                <FilingCompleted
                                    openAccord={open}
                                    toggleButtonHandler={toggle}
                                    data_description={data?.filing}
                                    isCompleted={true}
                                    processNO={"01"}
                                />
                                {ResponseStatus.getDisplayTextKey(data?.trademarkStepsStatus) !==
                                "Pending" &&
                                (<POAApplicationCompleted
                                openAccord={open}
                                toggleButtonHandler={toggle}
                                title={"E Signature"}
                                isCompleted={true}
                                targetId={"3"}
                                accordId={"3"}
                                isPA={true}
                                processNO={"02"}
                                data_description={data?.pOAApplication}
                                />)}
                                
                                {CaseStatus.getDisplayTextKey(data?.trademarkStatus) === "Open" ? 
                                (ResponseStatus.getDisplayTextKey(data?.trademarkStepsStatus) ===
                                "Awaiting" ? (
                                    <>
                                        <PublicationAwaiting
                                            openAccord={open}
                                            toggleButtonHandler={toggle}
                                            title={"Publication"}
                                            targetId={"5"}
                                            accordId={"5"}
                                            isPA={false}
                                            processNO={"03"}
                                            data_description={data?.publication}
                                        />
                                    </>
                                ) : (
                                    <>
                                    <POAApplicationCompleted
                                        openAccord={open}
                                        toggleButtonHandler={toggle}
                                        title={"E Signature"}
                                        status={"Open"}
                                        isCompleted={false}
                                        targetId={"3"}
                                        accordId={"3"} 
                                        isPA={true}
                                        processNO={"02"}
                                        nextStepTargetId={"6"}
                                        nextStepHandler={nextStepHandlerForOtherSteps}
                                        isFormTwoVisible={isFormTwoVisible}
                                        data_description={data?.pOAApplication}
                                        refetchStepsAPIHandler={refetchStepsAPIHandler}
                                        /> 
                                        {isFormTwoVisible ? (<Publication
                                        isAwaiting={true}
                                        openAccord={open}
                                        toggleButtonHandler={toggle}
                                        title={"Publication"}
                                        targetId={"6"}
                                        accordId={"6"}
                                        processNO={"03"}
                                        data_description={data?.pOAApplication}
                                        refetchStepsAPIHandler={refetchStepsAPIHandler}
                                    />) : null}
                                    </>
                                )): (
                                    <>
                                        <POAApplication
                                            openAccord={open}
                                            toggleButtonHandler={toggle}
                                            title={"Publication"}
                                            data_description={data}
                                            targetId={"46"}
                                            accordId={"46"}
                                            isReject={true}
                                            processNO={"03"}
                                        />
                                    </>
                                )}
                            </>
                        )
                    }

                    {/** handling registration step submission, awaiting and rejection */}
                    {data?.trademarkStep === "REGISTRATION" && (
                        <>
                            <FilingCompleted
                                openAccord={open}
                                toggleButtonHandler={toggle}
                                data_description={data?.filing}
                                isCompleted={true}
                                processNO={"01"}
                            />
                            <POAApplicationCompleted
                                openAccord={open}
                                toggleButtonHandler={toggle}
                                title={"E Signature"}
                                isCompleted={true}
                                targetId={"3"}
                                accordId={"3"}
                                isPA={true}
                                processNO={"02"}
                                data_description={data?.pOAApplication}
                            />
                             {ResponseStatus.getDisplayTextKey(data?.trademarkStepsStatus) !==
                                "Pending" &&
                                (
                            <POAApplicationCompleted
                                openAccord={open}
                                toggleButtonHandler={toggle}
                                title={"Publication"}
                                isCompleted={true}
                                targetId={"27"}
                                accordId={"27"}
                                isPA={false}
                                processNO={"03"}
                                data_description={data?.publication}
                            />)}
                            {CaseStatus.getDisplayTextKey(data?.trademarkStatus) === "Open" ? (
                                <>
                                    {ResponseStatus.getDisplayTextKey(
                                        data?.trademarkStepsStatus
                                    ) === "Awaiting" ? (
                                        <>
                                            <RegistrationAwaiting
                                                openAccord={open}
                                                toggleButtonHandler={toggle}
                                                title={"Registration"}
                                                targetId={"29"}
                                                accordId={"29"}
                                                isPA={false}
                                                processNO={"04"}
                                                data_description={data?.registration}
                                            />
                                        </>
                                    ) : (
                                        <>
                                        <POAApplicationCompleted
                                            openAccord={open}
                                            toggleButtonHandler={toggle}
                                            title={"Publication"}
                                            status={"Open"}
                                            targetId={"27"}
                                            accordId={"27"}
                                            isCompleted={false}
                                            isPA={false}
                                            processNO={"03"}
                                            nextStepTargetId={"8"}
                                            data_description={data?.publication}
                                            nextStepHandler={nextStepHandlerForOtherSteps}
                                            isFormTwoVisible={isFormTwoVisible}
                                            refetchStepsAPIHandler={refetchStepsAPIHandler}
                                        />
                                        {isFormTwoVisible ? (<Registration
                                            isAwaiting={true}
                                            openAccord={open}
                                            toggleButtonHandler={toggle}
                                            title={"Registration"}
                                            targetId={"8"}
                                            accordId={"8"}
                                            processNO={"04"}
                                            data_description={data?.publication}
                                            refetchStepsAPIHandler={refetchStepsAPIHandler}
                                        />): null}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <POAApplication
                                        openAccord={open}
                                        toggleButtonHandler={toggle}
                                        title={"Registration"}
                                        data_description={data}
                                        targetId={"39"}
                                        accordId={"39"}
                                        isReject={true}
                                        processNO={"04"}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {/** handling TMCRD DOWNLOAD step submission, awaiting and rejection */}
                    {data?.trademarkStep === "TMCRDOWNLOAD" &&
                        CaseStatus.getDisplayTextKey(data?.trademarkStatus) !==
                            "FileDownloaded" && (
                            <>
                                <FilingCompleted
                                    openAccord={open}
                                    toggleButtonHandler={toggle}
                                    data_description={data?.filing}
                                    isCompleted={true}
                                    processNO={"01"}
                                />
                                <POAApplicationCompleted
                                    openAccord={open}
                                    toggleButtonHandler={toggle}
                                    data_description={data?.pOAApplication}
                                    title={"E Signature"}
                                    isCompleted={true}
                                    targetId={"14"}
                                    accordId={"14"}
                                    isPA={true}
                                    processNO={"02"}
                                />
                                <POAApplicationCompleted
                                    openAccord={open}
                                    toggleButtonHandler={toggle}
                                    data_description={data?.publication}
                                    title={"Publication"}
                                    isCompleted={true}
                                    targetId={"15"}
                                    accordId={"15"}
                                    isPA={false}
                                    processNO={"03"}
                                />
                                {ResponseStatus.getDisplayTextKey(data?.trademarkStepsStatus) !==
                                "Pending" &&
                                (<POAApplicationCompleted
                                    openAccord={open}
                                    toggleButtonHandler={toggle}
                                    data_description={data?.registration}
                                    title={"Registration"}
                                    isCompleted={true}
                                    targetId={"16"}
                                    accordId={"16"}
                                    isPA={false}
                                    processNO={"04"}
                                />)}
                                {CaseStatus.getDisplayTextKey(data?.trademarkStatus) === "Open" ? (
                                    <>
                                        {ResponseStatus.getDisplayTextKey(
                                            data?.trademarkStepsStatus
                                        ) === "Awaiting" ? (
                                            <>
                                                <TMCRAwaiting
                                                    openAccord={open}
                                                    toggleButtonHandler={toggle}
                                                    title={"TMCR"}
                                                    targetId={"9"}
                                                    accordId={"9"}
                                                    isPA={false}
                                                    processNO={"05"}
                                                    data_description={data?.tmcrDownload}
                                                />
                                            </>
                                        ) : (
                                            <>
                                            <POAApplicationCompleted
                                                openAccord={open}
                                                toggleButtonHandler={toggle}
                                                data_description={data?.registration}
                                                title={"Registration"}
                                                status={"Open"}
                                                isCompleted={false}
                                                targetId={"16"}
                                                accordId={"16"}
                                                isPA={false}
                                                processNO={"04"}
                                                nextStepTargetId={"17"}
                                                nextStepHandler={nextStepHandlerForOtherSteps}
                                                isFormTwoVisible={isFormTwoVisible}
                                                refetchStepsAPIHandler={refetchStepsAPIHandler}
                                            />
                                            {isFormTwoVisible ? (<TMCRDownload
                                                isAwaiting={true}
                                                openAccord={open}
                                                toggleButtonHandler={toggle}
                                                title={"TMCR"}
                                                targetId={"17"}
                                                accordId={"17"}
                                                processNO={"05"}
                                                data_description={data?.registration}
                                                refetchStepsAPIHandler={refetchStepsAPIHandler}
                                            />): null}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <POAApplication
                                            openAccord={open}
                                            toggleButtonHandler={toggle}
                                            data_description={data}
                                            title={"TMCR"}
                                            targetId={"19"}
                                            accordId={"19"}
                                            isReject={true}
                                            processNO={"05"}
                                        />
                                    </>
                                )}
                            </>
                        )}
                </div>
            )}
        </>
    );
};

export default withRouter(Details);
