import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import MetaTags from "react-meta-tags";

import { HeaderWithButton } from "components/HeaderWithButton";
import { DataTable } from "components/DataTable";
import { earningsDetailsHeader } from "../../constants";
import { METATAGS, stepsName } from "../../constants";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { GetEarningById, GetAllEarningsReceipt } from "api/api.service";
import { CustomButton } from "components/CustomButton";
import { DownloadReceiptModal } from "components/DownloadReceiptModal";

const Details = () => {

    const params = useParams();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isDownloadReceiptModalActive, setIsDownloadReceiptModalActive] = useState(false);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [receiptData, setReceiptData] = useState(undefined);

    // fetching earning data from API
    useEffect(async () => {
        setLoading(true);
        const res = await GetEarningById(params?.id);
        setData(res);
        setLoading(false);
    }, [params?.id]);

    // view receipt handling
    const ButtonClickHanlder = async (e) => {
        try {
            setIsDataFetching(true);
            let res = await GetAllEarningsReceipt(params?.id);
            setReceiptData(res);
            setIsDownloadReceiptModalActive(true);
        } finally {
            setIsDataFetching(false);
        }
    };

    // download receipt modal close handling
    const downloadReceiptModalCloseHandler = () => {
        setIsDownloadReceiptModalActive(false);
    };

    return (
        <>
            <MetaTags>
                <title>{METATAGS.EARNING_DETAILS}</title>
            </MetaTags>
            <HeaderWithButton isButton={false} data={data} />

            <Row className="p-3 pt-0 case-management-con earning-details-con">
                <Col md={12}>
                    <div className="bg-white p-3 earning-header-btn">
                        <CustomButton
                            color="primary"
                            title="Download Receipt"
                            onClick={ButtonClickHanlder}
                            className="login-btn-typo search-btn-header-btn"
                        />
                    </div>
                </Col>
                <Col md={12} className="earning-details-table-con">
                    <DataTable
                        data={data?.payments}
                        loading={loading}
                        config={[
                            {
                                title: earningsDetailsHeader.STEP_NUM,
                                className: "bold-font ed-step-col",
                                render: (data) => data?.stepNumber || "-"
                            },
                            {
                                title: earningsDetailsHeader.PROCESS,
                                className: "bold-font ed-process-col ",
                                render: (data) => stepsName.getDisplayTextKey(data?.process) || "-"
                            },
                            {
                                title: earningsDetailsHeader.FEES,
                                className: "bold-font ed-fees-col ",
                                render: (data) => <>${data?.amount}</> || "-"
                            }
                        ]}
                    />
                </Col>
            </Row>
            <DownloadReceiptModal
                modalActivator={isDownloadReceiptModalActive}
                modalCloseHandler={downloadReceiptModalCloseHandler}
                modalData={receiptData}
                isDataFetching={isDataFetching}
                caseRelatedData={data}
            />
        </>
    );
};

export default Details;
