import { Dashboard } from "../pages/Dashboard";
import { CaseManagementDetails, CaseManagementListing } from "../pages/CaseManagement";
import { CustomerManagementDetails, CustomerManagementListing } from "../pages/CustomerManagement";
import { SubAdminListing, SubAdminDetails } from "../pages/Departments";
import { EarningListing, EarningDetails } from "../pages/Earnings";
import { CountriesListing } from "../pages/Countries";
import { GoodsServicesListing } from "../pages/GoodsServices";
import { ChatsListing } from "../pages/Chats";
import { SettingsListing, SettingsDetails, ChangePassword } from "../pages/Settings";

import { APP_ROUTES } from "../helpers/routeHelpers";
import { UserRoleType } from "../constants";

let { SuperAdmin } = UserRoleType;

const authProtectedRoutes = [
    {
        path: APP_ROUTES.DASHBOARD,
        component: Dashboard,
        // permissions: [SuperAdmin],
        exact: true
    },

    {
        path: APP_ROUTES.CASE_MANAGEMENT,
        component: CaseManagementListing,
        // permissions: [SuperAdmin],
        exact: true
    },
    {
        path: `${APP_ROUTES.CASE_MANAGEMENT_DETAILS}/:id`,
        component: CaseManagementDetails,
        // permissions: [SuperAdmin],
        exact: true
    },
    {
        path: APP_ROUTES.CUSTOMER_MANAGEMENT,
        component: CustomerManagementListing,
        // permissions: [SuperAdmin],
        exact: true
    },
    {
        path: `${APP_ROUTES.CUSTOMER_MANAGEMENT_DETAILS}/:id`,
        component: CustomerManagementDetails,
        // permissions: [SuperAdmin],
        exact: true
    },
    {
        path: APP_ROUTES.SUB_ADMINS,
        component: SubAdminListing,
        // permissions: [SuperAdmin],
        exact: true
    },
    {
        path: `${APP_ROUTES.SUB_ADMINS_DETAILS}/:id`,
        component: SubAdminDetails,
        // permissions: [SuperAdmin],
        exact: true
    },
    {
        path: APP_ROUTES.EARNINGS,
        component: EarningListing,
        // permissions: [SuperAdmin],
        exact: true
    },
    {
        path: `${APP_ROUTES.EARNINGS_DETAILS}/:id`,
        component: EarningDetails,
        // permissions: [SuperAdmin],
        exact: true
    },
    {
        path: APP_ROUTES.COUNTRIES,
        component: CountriesListing,
        // permissions: [SuperAdmin],
        exact: true
    },
    {
        path: APP_ROUTES.GOODS_SERVICES,
        component: GoodsServicesListing,
        // permissions: [SuperAdmin],
        exact: true
    },

    {
        path: `${APP_ROUTES.CHATS}/:id?`,
        component: ChatsListing,
        // permissions: [SuperAdmin],
        exact: true
    },

    {
        path: APP_ROUTES.SETTINGS,
        component: SettingsListing,
        // permissions: [SuperAdmin],
        exact: true
    },
    {
        path: `${APP_ROUTES.SETTINGS_DETAILS}`,
        component: SettingsDetails,
        // permissions: [SuperAdmin],
        exact: true
    },
    {
        path: `${APP_ROUTES.SETTINGS_CHANGE_PASS}`,
        component: ChangePassword,
        // permissions: [SuperAdmin],
        exact: true
    }
];

export { authProtectedRoutes };
