import PropTypes from "prop-types";
import React, { useMemo, Fragment } from "react";
import { Pagination as PaginationContainer, PaginationItem, PaginationLink } from "reactstrap";
import leftArrowIcon from "../../assets/images/aiAPaiT/leftarrow-icon.svg";
import rightArrowIcon from "../../assets/images/aiAPaiT/rightarrow-icon.svg";
// import Config from "../../Config"

const MAX_PAGE_VIEW_LIMIT = 4;

const Pagination = (props) => {
    const { className="", currentPage, totalData, rowLimit = 10, onPageClick } = props;
    const totalPages = useMemo(() => {
        return Math.ceil(totalData / rowLimit);
    }, [totalData, rowLimit]);

    const handlePageClick = (val) => {
        if (onPageClick && typeof onPageClick === "function") {
            onPageClick(val);
        }
    };

    const handlePreviousClick = () => {
        if (currentPage > 1) {
            handlePageClick(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            handlePageClick(currentPage + 1);
        }
    };

    const renderPages = () => {
        let pages =
            totalPages > MAX_PAGE_VIEW_LIMIT
                ? new Array(MAX_PAGE_VIEW_LIMIT).fill("")
                : new Array(totalPages).fill("");

        return pages.map((_, index) => {
            return (
                <PaginationItem active={index + 1 === currentPage} key={index}>
                    <PaginationLink tag="button" onClick={() => handlePageClick(index + 1)}>
                        {index + 1}
                    </PaginationLink>
                </PaginationItem>
            );
        });
    };

    return (
        totalPages > 1 && (
            <PaginationContainer className={`pagination pagination-custom pagination-square justify-content-center align-items-center px-3 ${className}`}>
                <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink previous tag="button" onClick={handlePreviousClick}>
                        <img src={leftArrowIcon} alt="left arrow icon" />
                    </PaginationLink>
                </PaginationItem>
                {renderPages()}
                {totalPages > MAX_PAGE_VIEW_LIMIT && (
                    <Fragment>
                        <PaginationItem active={currentPage > MAX_PAGE_VIEW_LIMIT}>
                            <PaginationLink tag="button">
                                {currentPage > MAX_PAGE_VIEW_LIMIT ? currentPage : "..."}
                            </PaginationLink>
                        </PaginationItem>
                        {currentPage !== totalPages && (
                            <PaginationItem>
                                <PaginationLink
                                    tag="button"
                                    onClick={() => handlePageClick(totalPages)}
                                >
                                    {totalPages}
                                </PaginationLink>
                            </PaginationItem>
                        )}
                    </Fragment>
                )}
                <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink next tag="button" onClick={handleNextClick}>
                        <img src={rightArrowIcon} alt="right arrow icon" />
                    </PaginationLink>
                </PaginationItem>
            </PaginationContainer>
        )
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.any,
    totalData: PropTypes.any,
    rowLimit: PropTypes.any,
    onPageClick: PropTypes.any,
    className: PropTypes.string,
};

export default Pagination;
