import React from 'react';
import  PropTypes from 'prop-types'
import { CustomButton } from 'components/CustomButton';

const DepartmentHeaderButtons = ({
  viewbtnHandler, 
  editBtnHandler, 
  suspendBtnHandler, 
  isUserSuspended,
  isSuspensionLoading}) => {
  return(
    <>
      <div className="dept-detail-container py-2 bg-white dept-header-typo-sec dept-header-right-con">
        <div className="dept-detail-main-container mb-0">
          <div className='cm-header-con'>
            <div className="dept-detail-form-fields-container">
              <CustomButton
                title="View Countries"
                className="shadow-none view-countries-btn"
                color="#704DE7"
                outline
                onClick={viewbtnHandler}
              />
            </div>
            <div className="dept-detail-form-fields-container">
              <CustomButton
                title="Edit"
                className="shadow-none edit-btn"
                color="#704DE7"
                outline
                onClick={editBtnHandler}
              />
            </div>
            <div className="dept-detail-form-fields-container">
              <CustomButton
                title={isUserSuspended === "INACTIVE" ? "Unsuspend" : "Suspend"}
                className="cd-header-btn shadow-none suspend-btn"
                color="#704DE7"
                outline
                onClick={suspendBtnHandler}
                // disabled={isUserSuspended === "INACTIVE"}
                loading={isSuspensionLoading}
              />
            </div>
          </div>
        </div> 
      </div>
    </>
  )
};

DepartmentHeaderButtons.propTypes = {
  editBtnHandler: PropTypes.func,
  viewbtnHandler: PropTypes.func,
  suspendBtnHandler: PropTypes.func,
  isUserSuspended: PropTypes.string,
  isSuspensionLoading: PropTypes.bool,
}

export default DepartmentHeaderButtons;