import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { Config } from "../../../themeConfig";
import { CustomModal } from "../../CustomModal";
import { CustomButton } from "../../CustomButton";
import { ThemeColor } from "../../../constants";
import { getMediaPath } from "utils/mediaUtils";
import { SIGN_OUT } from "store/actions";

import LogoutIcon from "../../../assets/images/aiAPaiT/logout-icon.svg";
 
import "./ProfileMenu.scss";

const ProfileMenu = (props) => {
    const { user } = useSelector((state) => state?.userAuth);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [menu, setMenu] = useState(false);
    // const [userName, setUserName] = useState("Admin");

    const selectedTheming = Config.ThemeName;
    const currentTheme = ThemeColor[selectedTheming];

    const handleLogoutModal = () => {
        // const { signOut } = props;
        setShowLogoutModal(true);
        // localStorage.clear();
        // signOut();
    };

    const handleSignout = () => {
        const { signOut } = props;
        localStorage.clear();
        signOut();
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
                <DropdownToggle
                    className="btn header-item "
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    {/* <img
                        className="rounded-circle header-profile-user"
                        src={getMediaPath(user?.Media) || currentTheme.userImg.default}
                        alt="Header Avatar"
                    /> */}
                    <span className=" d-inline-block ms-2 me-1">{user?.name || ""}</span>
                    <i className="mdi mdi-chevron-down d-inline-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    {/* <DropdownItem tag="a" href="/profile">
                        {"My Profile"}
                    </DropdownItem> */}
                    {/* <div className="dropdown-divider" /> */}
                    <div className="dropdown-item" role="button" onClick={handleLogoutModal}>
                        <img src={LogoutIcon} alt="logout icon" className="me-2"/>
                        <span>{"Logout"}</span>
                    </div>
                </DropdownMenu>
            </Dropdown>
            <CustomModal
                isOpen={showLogoutModal}
                data={
                    <div className="text-center mt-3 mb-5 logout-modal-con">
                        <h2 className="clr-theme-primary">Are You Leaving?</h2>
                        <p className="my-4 desc-typo">
                            Are you sure you want to log out?
                        </p>
                        <CustomButton
                            title="CANCEL"
                            className="me-3 bv-btn-sm fw-bold"
                            color="primary"
                            outline
                            onClick={() => {
                                setShowLogoutModal(false);
                            }}
                        />
                        <CustomButton
                            title="LOGOUT"
                            color="primary"
                            className="bv-btn-sm shadow-theme fw-bold"
                            onClick={() => {
                                handleSignout();
                            }}
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch({ type: SIGN_OUT })
});

export default connect(null, mapDispatchToProps)(withRouter(ProfileMenu));

ProfileMenu.propTypes = {
    signOut: PropTypes.any
};
