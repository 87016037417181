import React from "react";
import moment from "moment";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PropTypes from "prop-types";

import { APP_ROUTES } from "../../helpers/routeHelpers";
import {  ResponseStatus, stepsName} from "../../constants";
import { findStepNum } from "utils/commonUtils";

import { CustomButton } from "components/CustomButton";

const CaseDetail = ({ data }) => {

    return (
        <>
            <div className="recent-case-container">
                <div className="recent-case-card">
                    <div className="header-id">{data?.id || "-"}</div>
                    <div className="header-time">{moment(data?.updatedAt).fromNow() || "-"}</div>
                </div>
                <div className="recent-case-card-body">
                    <div className="user-name">{data?.customerName || "-"}</div>
                    <div className="user-description">{data?.caseTitle || "-"}</div>
                    <div className="user-case-container">
                        <div className="user-case-type">
                            <div>{findStepNum(data?.trademarkStep) || "-"}</div>
                            <p>{stepsName.getDisplayTextKey(data?.trademarkStep) || "-"}</p>
                        </div>
                        <Link

                        to={{
                            pathname: `${APP_ROUTES.CASE_MANAGEMENT_DETAILS}/${data?.id}`,
                            state: {caseStatus: data?.trademarkStatus,
                                    responseStatus: ResponseStatus.getDisplayTextKey(
                                        data?.trademarkStepsStatus
                                    ),
                                    process: data?.trademarkStep
                                }
                            }} 
                            className=" pointer ">
                            <CustomButton title="View" className="case-view-button shadow-none" />
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CaseDetail;

CaseDetail.propTypes = {
    data: PropTypes.any,
    loading: PropTypes.bool,
    handleView: PropTypes.any
};
