import React from 'react';
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormik } from "formik";

import { CustomModal } from "components/CustomModal";
import backIcon from "../../assets/images/aiAPaiT/back-arrow.svg";

const listCountries =["Oman","Abu Dhabi", "Dubai"]

const CountriesListModal = ({
  modalActivator=false, 
  modalCloseHandler= () => {},
  data = {},
}) => {
  return(
    <>
      <CustomModal
        isOpen={modalActivator}
        className="add-department-custom-modal"
        data={
          <div className=" add-department-modal countries-list-modal px-5 pb-4 mb-3">
            <div className='d-flex  align-items-center mb-4'>
              <img 
              src={backIcon} 
              alt="close modal icon"
              onClick={modalCloseHandler}
              />
              <p className="my-2 mx-auto">
              {"List of Countries"}  
              </p>
            </div>
            <div className="countries-listing">
              {data && data?.countries?.map((el, idx) => <p className="mb-3" key={idx}>{el?.name}</p>)}
            </div>
          
        </div>
        }
      />
    </>
  )
}

CountriesListModal.propTypes = {
  modalActivator: PropTypes.bool,
  modalCloseHandler: PropTypes.func,
  data: PropTypes.object
}

export default CountriesListModal;