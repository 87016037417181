export const MonthYearFilter = {
    Monthly: "1",
    Yearly: "2"
};

export const filterOptions = [
    {
        label: "Case Status",
        value: "trademarkStatus"
    },
    {
        label: "Response Status",
        value: "trademarkStepsStatus"
    },
    {
        label: "Country",
        value: "countryName"
    },
    {
        label: "Process",
        value: "trademarkStep"
    }
];

export const filterOptionsCustomerManagement = [
    {
        label: "Case Status",
        value: "trademarkStatus"
    },
    {
        label: "Country",
        value: "countryName"
    },
    {
        label: "Process",
        value: "trademarkStep"
    }
];

export const responseStatusOptions = [
    {
        label: "Pending",
        value: "PendingAdmin"
    },
    {
        label: "Awaiting",
        value: "PendingCustomer"
    },
    {
        label: "Done",
        value: "Completed"
    },
    {
        label: "Rejected",
        value: "Rejected"
    }
];

export const caseStatusOptions = [
    {
        label: "Open",
        value: "Open"
    },
    {
        label: "Completed",
        value: "Completed"
    },
    {
        label: "Closed",
        value: "Closed"
    },
    {
        label: "File Downloaded",
        value: "FileDownloaded"
    }
];

export const processStatusOptions = [
    {
        label: "Document Submission",
        value: "FILING"
    },
    {
        label: "E Signature",
        value: "POAAPPLICATION"
    },
    {
        label: "Publication",
        value: "PUBLICATION"
    },
    {
        label: "Registration",
        value: "REGISTRATION"
    },
    {
        label: "TMCR",
        value: "TMCRDOWNLOAD"
    }
];

// export const countriesOptions1 = [
//     {
//         label: "Afghanistan(AF)",
//         value: "Afghanistan"
//     },
//     {
//         label: "Aland Islands(AX)",
//         value: "Aland Islands"
//     },
//     {
//         label: "Albania",
//         value: "Albania"
//     },
//     {
//         label: "Algeria",
//         value: "Algeria"
//     },
//     {
//         label: "American Samoa",
//         value: "American Samoa"
//     },
//     {
//         label: "Andorra",
//         value: "Andorra"
//     },
//     {
//         label: "Angola",
//         value: "Angola"
//     },
//     {
//         label: "Anguilla",
//         value: "Anguilla"
//     },
//     {
//         label: "Antarctica",
//         value: "Antarctica"
//     },
//     {
//         label: "Antigua and Barbuda",
//         value: "Antigua and Barbuda"
//     },
//     {
//         label: "Argentina",
//         value: "Argentina"
//     },
//     {
//         label: "Armenia",
//         value: "Armenia"
//     },
//     {
//         label: "Aruba",
//         value: "Aruba"
//     },
//     {
//         label: "Australia",
//         value: "Australia"
//     },
//     {
//         label: "Austria",
//         value: "Austria"
//     },
//     {
//         label: "Azerbaijan",
//         value: "Azerbaijan"
//     },
//     {
//         label: "Bahamas",
//         value: "Bahamas"
//     },
//     {
//         label: "Bahrain",
//         value: "Bahrain"
//     },
//     {
//         label: "Bangladesh",
//         value: "Bangladesh"
//     },
//     {
//         label: "Barbados",
//         value: "Barbados"
//     },
//     {
//         label: "Belarus",
//         value: "Belarus"
//     },
//     {
//         label: "Belgium",
//         value: "Belgium"
//     },
//     {
//         label: "Belize",
//         value: "Belize"
//     },
//     {
//         label: "Benin",
//         value: "Benin"
//     },
//     {
//         label: "Bermuda",
//         value: "Bermuda"
//     },
//     {
//         label: "Bhutan",
//         value: "Bhutan"
//     },
//     {
//         label: "Bolivia",
//         value: "Bolivia"
//     },
//     {
//         label: "Bosnia and Herzegovina",
//         value: "Bosnia and Herzegovina"
//     },
//     {
//         label: "Botswana",
//         value: "Botswana"
//     },
//     {
//         label: "Bouvet Island",
//         value: "Bouvet Island"
//     },
//     {
//         label: "Brazil",
//         value: "Brazil"
//     },
//     {
//         label: "British Indian Ocean Territory",
//         value: "British Indian Ocean Territory"
//     },
//     {
//         label: "Brunei",
//         value: "Brunei"
//     },
//     {
//         label: "Bulgaria",
//         value: "Bulgaria"
//     },
//     {
//         label: "Burkina Faso",
//         value: "Burkina Faso"
//     },
//     {
//         label: "Burundi",
//         value: "Burundi"
//     },
//     {
//         label: "Cambodia",
//         value: "Cambodia"
//     },
//     {
//         label: "Cameroon",
//         value: "Cameroon"
//     },
//     {
//         label: "Canada",
//         value: "Canada"
//     },
//     {
//         label: "Cabo Verde",
//         value: "Cabo Verde"
//     },
//     {
//         label: "Cayman Islands",
//         value: "Cayman Islands"
//     },
//     {
//         label: "Central African Republic",
//         value: "Central African Republic"
//     },
//     {
//         label: "Chad",
//         value: "Chad"
//     },
//     {
//         label: "Chile",
//         value: "Chile"
//     },
//     {
//         label: "China",
//         value: "China"
//     },
//     {
//         label: "Christmas Island",
//         value: "Christmas Island"
//     },
//     {
//         label: "Cocos (Keeling) Islands",
//         value: "Cocos (Keeling) Islands"
//     },
//     {
//         label: "Colombia",
//         value: "Colombia"
//     },
//     {
//         label: "Komori",
//         value: "Komori"
//     },
//     {
//         label: "Republic of the Congo",
//         value: "Republic of the Congo"
//     },
//     {
//         label: "Democratic Republic of the Congo",
//         value: "Democratic Republic of the Congo"
//     },
//     {
//         label: "Cook Islands",
//         value: "Cook Islands"
//     },
//     {
//         label: "Costa Rica",
//         value: "Costa Rica"
//     },
//     {
//         label: "Ivory Coast",
//         value: "Ivory Coast"
//     },
//     {
//         label: "Croatia",
//         value: "Croatia"
//     },
//     {
//         label: "Cuba",
//         value: "Cuba"
//     },
//     {
//         label: "Cyprus",
//         value: "Cyprus"
//     },
//     {
//         label: "Czechia",
//         value: "Czechia"
//     },
//     {
//         label: "Danmark",
//         value: "Danmark"
//     },
//     {
//         label: "Djibouti",
//         value: "Djibouti"
//     },
//     {
//         label: "Dominica",
//         value: "Dominica"
//     },
//     {
//         label: "Dominican Republic",
//         value: "Dominican Republic"
//     },
//     {
//         label: "Ecuador",
//         value: "Ecuador"
//     },
//     {
//         label: "Egypt",
//         value: "Egypt"
//     },
//     {
//         label: "El Salvador",
//         value: "El Salvador"
//     },
//     {
//         label: "Equatorial Guinea",
//         value: "Equatorial Guinea"
//     },
//     {
//         label: "Eritrea",
//         value: "Eritrea"
//     },
//     {
//         label: "Estonia",
//         value: "Estonia"
//     },
//     {
//         label: "Ethiopia",
//         value: "Ethiopia"
//     },
//     {
//         label: "Falkland Islands (Islas Malvinas)",
//         value: "Falkland Islands (Islas Malvinas)"
//     },
//     {
//         label: "Faroe Islands",
//         value: "Faroe Islands"
//     },
//     {
//         label: "Fiji",
//         value: "Fiji"
//     },
//     {
//         label: "Suomi",
//         value: "Suomi"
//     },
//     {
//         label: "France",
//         value: "France"
//     },
//     {
//         label: "French Guiana",
//         value: "French Guiana"
//     },
//     {
//         label: "French Polynesia",
//         value: "French Polynesia"
//     },
//     {
//         label: "French Southern and Antarctic Lands",
//         value: "French Southern and Antarctic Lands"
//     },
//     {
//         label: "Gabon",
//         value: "Gabon"
//     },
//     {
//         label: "The Gambia",
//         valu: "The Gambia"
//     },
//     {
//         label: "Georgia",
//         value: "Georgia"
//     },
//     {
//         label: "Germany",
//         value: "Germany"
//     },
//     {
//         label: "Ghana",
//         value: "Ghana"
//     },
//     {
//         label: "Gibraltar",
//         value: "Gibraltar"
//     },
//     {
//         label: "Greece",
//         value: "Greece"
//     },
//     {
//         label: "Greenland",
//         value: "Greenland"
//     },
//     {
//         label: "Grenada",
//         value: "Grenada"
//     },
//     {
//         label: "Guadeloupe",
//         value: "Guadeloupe"
//     },
//     {
//         label: "Guam",
//         value: "Guam"
//     },
//     {
//         label: "Guatemala",
//         value: "Guatemala"
//     },
//     {
//         label: "Guernsey",
//         value: "Guernsey"
//     },
//     {
//         label: "Guinea",
//         value: "Guinea"
//     },
//     {
//         label: "Guinea-Bissau",
//         value: "Guinea-Bissau"
//     },
//     {
//         label: "Guyana",
//         value: "Guyana"
//     },
//     {
//         label: "Haiti",
//         value: "Haiti"
//     },
//     {
//         label: "Heard Island and McDonald Islands",
//         value: "Heard Island and McDonald Islands"
//     },
//     {
//         label: "Vaticano",
//         value: "Vaticano"
//     },
//     {
//         label: "Honduras",
//         value: "Honduras"
//     },
//     {
//         label: "Hong Kong",
//         value: "Hong Kong"
//     },
//     {
//         label: "Hungary",
//         value: "Hungary"
//     },
//     {
//         label: "Iceland",
//         value: "Iceland"
//     },
//     {
//         label: "India",
//         value: "India"
//     },
//     {
//         label: "Indonesia",
//         value: "Indonesia"
//     },
//     {
//         label: "Iran",
//         value: "Iran"
//     },
//     {
//         label: "Iraq",
//         value: "Iraq"
//     },
//     {
//         label: "Ireland",
//         value: "Ireland"
//     },
//     {
//         label: "Isle of Man",
//         value: "Isle of Man"
//     },
//     {
//         label: "Israel",
//         value: "Israel"
//     },
//     {
//         label: "Italy",
//         value: "Italy"
//     },
//     {
//         label: "Jamaica",
//         value: "Jamaica"
//     },
//     {
//         label: "Japan",
//         value: "Japan"
//     },
//     {
//         label: "Jersey",
//         value: "Jersey"
//     },
//     {
//         label: "Jordan",
//         value: "Jordan"
//     },
//     {
//         label: "Kazakhstan",
//         value: "Kazakhstan"
//     },
//     {
//         label: "Kenya",
//         value: "Kenya"
//     },
//     {
//         label: "Kiribati",
//         value: "Kiribati"
//     },
//     {
//         label: "North Korea",
//         value: "North Korea"
//     },
//     {
//         label: "South Korea",
//         value: "South Korea"
//     },
//     {
//         label: "Kosovo",
//         value: "Kosovo"
//     },
//     {
//         label: "Kuwait",
//         value: "Kuwait"
//     },
//     {
//         label: "Kyrgyzstan",
//         value: "Kyrgyzstan"
//     },
//     {
//         label: "Laos",
//         value: "Laos"
//     },
//     {
//         label: "Latvija",
//         value: "Latvija"
//     },
//     {
//         label: "Lebanon",
//         value: "Lebanon"
//     },
//     {
//         label: "Lesotho",
//         value: "Lesotho"
//     },
//     {
//         label: "Liberia",
//         value: "Liberia"
//     },
//     {
//         label: "Libya",
//         value: "Libya"
//     },
//     {
//         label: "Liechtenstein",
//         value: "Liechtenstein"
//     },
//     {
//         label: "Lithuania",
//         value: "Lithuania"
//     },
//     {
//         label: "Luxembourg",
//         value: "Luxembourg"
//     },
//     {
//         label: "Macao",
//         value: "Macao"
//     },
//     {
//         label: "North Macedonia",
//         value: "North Macedonia"
//     },
//     {
//         label: "Madagascar",
//         value: "Madagascar"
//     },
//     {
//         label: "Malawi",
//         label: "Malawi"
//     },
//     {
//         label: "Malaysia",
//         value: "Malaysia"
//     },
//     {
//         label: "Maldives",
//         value: "Maldives"
//     },
//     {
//         label: "Mali",
//         value: "Mali"
//     },
//     {
//         label: "Malta",
//         value: "Malta"
//     },
//     {
//         label: "Marshall Islands",
//         value: "Marshall Islands"
//     },
//     {
//         label: "Martinique",
//         value: "Martinique"
//     },
//     {
//         label: "Mauritania",
//         value: "Mauritania"
//     },
//     {
//         label: "Maurice",
//         value: "Maurice"
//     },
//     {
//         label: "Mayotte",
//         value: "Mayotte"
//     },
//     {
//         label: "Mexico",
//         value: "Mexico"
//     },
//     {
//         label: "Micronesia",
//         value: "Micronesia"
//     },
//     {
//         label: "Moldova",
//         value: "Moldova"
//     },
//     {
//         label: "Monaco",
//         value: "Monaco"
//     },
//     {
//         label: "Mongolia",
//         value: "Mongolia"
//     },
//     {
//         label: "Montenegro",
//         value: "Montenegro"
//     },
//     {
//         label: "Montserrat",
//         value: "Montserrat"
//     },
//     {
//         label: "Morocco",
//         value: "Morocco"
//     },
//     {
//         label: "Mozambique",
//         value: "Mozambique"
//     },
//     {
//         label: "Myanmar",
//         value: "Myanmar"
//     },
//     {
//         label: "Namibia",
//         value: "Namibia"
//     },
//     {
//         label: "Nauru",
//         value: "Nauru"
//     },
//     {
//         label: "Nepal",
//         value: "Nepal"
//     },
//     {
//         label: "Netherlands",
//         value: "Netherlands"
//     },
//     {
//         label: "Netherlands Antilles",
//         value: "Netherlands Antilles"
//     },
//     {
//         label: "New Caledonia",
//         value: "New Caledonia"
//     },
//     {
//         label: "New Zealand",
//         value: "New Zealand"
//     },
//     {
//         label: "Nicaragua",
//         value: "Nicaragua"
//     },
//     {
//         label: "Niger",
//         value: "Niger"
//     },
//     {
//         label: "Nigeria",
//         value: "Nigeria"
//     },
//     {
//         label: "Niue",
//         value: "Niue"
//     },
//     {
//         label: "Norfolk Island",
//         value: "Norfolk Island"
//     },
//     {
//         label: "Northern Mariana Islands",
//         value: "Northern Mariana Islands"
//     },
//     {
//         label: "Norway",
//         value: "Norway"
//     },
//     {
//         label: "Oman",
//         value: "Oman"
//     },
//     {
//         label: "Pakistan",
//         value: "Pakistan"
//     },
//     {
//         label: "Palau",
//         value: "Palau"
//     },
//     {
//         label: "Palestine",
//         value: "Palestine"
//     },
//     {
//         label: "Panama",
//         value: "Panama"
//     },
//     {
//         label: "Papua New Guinea",
//         value: "Papua New Guinea"
//     },
//     {
//         label: "Paraguay",
//         value: "Paraguay"
//     },
//     {
//         label: "Peru",
//         value: "Peru"
//     },
//     {
//         label: "Philippines",
//         value: "Philippines"
//     },
//     {
//         label: "Pitcairn Islands",
//         value: "Pitcairn Islands"
//     },
//     {
//         label: "Poland",
//         value: "Poland"
//     },
//     {
//         label: "Portugal",
//         value: "Portugal"
//     },
//     {
//         label: "Puerto Rico",
//         value: "Puerto Rico"
//     },
//     {
//         label: "Qatar",
//         value: "Qatar"
//     },
//     {
//         label: "Romania",
//         value: "Romania"
//     },
//     {
//         label: "Russia",
//         value: "Russia"
//     },
//     {
//         label: "Rwanda",
//         value: "Rwanda"
//     },
//     {
//         label: "Reunion Island",
//         value: "Reunion Island"
//     },
//     {
//         label: "Saint Barthelemy",
//         value: "Saint Barthelemy"
//     },
//     {
//         label: "Saint Helena",
//         value: "Saint Helena"
//     },
//     {
//         label: "Saint Kitts and Nevis",
//         value: "Saint Kitts and Nevis"
//     },
//     {
//         label: "Saint Lucia",
//         value: "Saint Lucia"
//     },
//     {
//         label: "Saint Martin",
//         value: "Saint Martin"
//     },
//     {
//         label: "Saint Pierre and Miquelon",
//         value: "Saint Pierre and Miquelon"
//     },
//     {
//         label: "Saint Vincent and the Grenadines",
//         value: "Saint Vincent and the Grenadines"
//     },
//     {
//         label: "Samoa",
//         value: "Samoa"
//     },
//     {
//         label: "San Marino",
//         value: "San Marino"
//     },
//     {
//         label: "Saint Thomas and Prince",
//         value: "Saint Thomas and Prince"
//     },
//     {
//         label: "Saudi Arabia",
//         value: "Saudi Arabia"
//     },
//     {
//         label: "Senegal",
//         value: "Senegal"
//     },
//     {
//         label: "Serbia",
//         value: "Serbia"
//     },
//     {
//         label: "Seychelles",
//         value: "Seychelles"
//     },
//     {
//         label: "Sierra Leone",
//         value: "Sierra Leone"
//     },
//     {
//         label: "Singapore",
//         value: "Singapore"
//     },
//     {
//         label: "Slovakia",
//         value: "Slovakia"
//     },
//     {
//         label: "Slovenia",
//         value: "Slovenia"
//     },
//     {
//         label: "Solomon Islands",
//         value: "Solomon Islands"
//     },
//     {
//         label: "Somalia",
//         value: "Somalia"
//     },
//     {
//         label: "South Africa",
//         value: "South Africa"
//     },
//     {
//         label: "South Sudan",
//         value: "South Sudan"
//     },
//     {
//         label: "South Georgia",
//         value: "South Georgia"
//     },
//     {
//         label: "Spain",
//         value: "Spain"
//     },
//     {
//         label: "Sri Lanka",
//         value: "Sri Lanka"
//     },
//     {
//         label: "Sudan",
//         value: "Sudan"
//     },
//     {
//         label: "Suriname",
//         value: "Suriname"
//     },
//     {
//         label: "Svalbard and Jan Mayen",
//         value: "Svalbard and Jan Mayen"
//     },
//     {
//         label: "Eswatini",
//         value: "Eswatini"
//     },
//     {
//         label: "Sweden",
//         value: "Sweden"
//     },
//     {
//         label: "Switzerland",
//         value: "Switzerland"
//     },
//     {
//         label: "Syria",
//         value: "Syria"
//     },
//     {
//         label: "Taiwan",
//         value: "Taiwan",

//         code: "TW",
//         dial_code: "+886"
//     },
//     {
//         label: "Tajikistan",
//         value: "Tajikistan"
//     },
//     {
//         label: "Tanzania",
//         value: "Tanzania"
//     },
//     {
//         label: "Thailand",
//         value: "Thailand"
//     },
//     {
//         label: "Timor-Leste",
//         value: "Timor-Leste"
//     },
//     {
//         label: "Togo",
//         value: "Togo"
//     },
//     {
//         label: "Tokelau",
//         value: "Tokelau"
//     },
//     {
//         label: "Tonga",
//         value: "Tonga"
//     },
//     {
//         label: "Trinidad and Tobago",
//         value: "Trinidad and Tobago"
//     },
//     {
//         label: "Tunisia",
//         value: "Tunisia"
//     },
//     {
//         label: "Turkey",
//         value: "Turkey"
//     },
//     {
//         label: "Turkmenistan",
//         value: "Turkmenistan"
//     },
//     {
//         label: "Turks and Caicos Islands",
//         value: "Turks and Caicos Islands"
//     },
//     {
//         label: "Tuvalu",
//         value: "Tuvalu"
//     },
//     {
//         label: "Uganda",
//         value: "Uganda"
//     },
//     {
//         label: "Ukraine",
//         value: "Ukraine"
//     },
//     {
//         label: "United Arab Emirates",
//         code: "AE",
//         dial_code: "+971"
//     },
//     {
//         label: "United Kingdom",
//         value: "United Kingdom"
//     },
//     {
//         label: "United States",
//         value: "United States"
//     },
//     {
//         label: "Uruguay",
//         value: "Uruguay"
//     },
//     {
//         label: "Uzbekistan ",
//         value: "Uzbekistan "
//     },
//     {
//         label: "Vanuatu",
//         value: "Vanuatu"
//     },
//     {
//         label: "Venezuela",
//         value: "Venezuela"
//     },
//     {
//         label: "Vietnam",
//         value: "Vietnam"
//     },
//     {
//         label: "British Virgin Islands",
//         value: "British Virgin Islands"
//     },
//     {
//         label: "United States Virgin Islands",
//         value: "United States Virgin Islands"
//     },
//     {
//         label: "Wallis and Futuna",
//         value: "Wallis and Futuna"
//     },
//     {
//         label: "Yemen",
//         value: "Yemen"
//     },
//     {
//         label: "Zambia",
//         value: "Zambia"
//     },
//     {
//         label: "Zimbabwe",
//         value: "Zimbabwe"
//     }
// ];

export const countriesOptions = [
    {
        label: "Afghanistan(AF)",
        value: "Afghanistan"
    },
    {
        label: "Aland Islands(AX)",
        value: "Aland Islands"
    },
    {
        label: "Albania(AL)",
        value: "Albania"
    },
    {
        label: "Algeria(DZ)",
        value: "Algeria"
    },
    {
        label: "American Samoa(AS)",
        value: "American Samoa"
    },
    {
        label: "Andorra(AD)",
        value: "Andorra"
    },
    {
        label: "Angola(AO)",
        value: "Angola"
    },
    {
        label: "Anguilla(AI)",
        value: "Anguilla"
    },
    {
        label: "Antarctica(AQ)",
        value: "Antarctica"
    },
    {
        label: "Antigua and Barbuda(AG)",
        value: "Antigua and Barbuda"
    },
    {
        label: "Argentina(AR)",
        value: "Argentina"
    },
    {
        label: "Armenia(AM)",
        value: "Armenia"
    },
    {
        label: "Aruba(AW)",
        value: "Aruba"
    },
    {
        label: "Australia(AU)",
        value: "Australia"
    },
    {
        label: "Austria(AT)",
        value: "Austria"
    },
    {
        label: "Azerbaijan(AZ)",
        value: "Azerbaijan"
    },
    {
        label: "Bahamas(BS)",
        value: "Bahamas"
    },
    {
        label: "Bahrain(BH)",
        value: "Bahrain"
    },
    {
        label: "Bangladesh(BD)",
        value: "Bangladesh"
    },
    {
        label: "Barbados(BB)",
        value: "Barbados"
    },
    {
        label: "Belarus(BY)",
        value: "Belarus"
    },
    {
        label: "Belgium(BE)",
        value: "Belgium"
    },
    {
        label: "Belize(BZ)",
        value: "Belize"
    },
    {
        label: "Benin(BJ)",
        value: "Benin"
    },
    {
        label: "Bermuda(BM)",
        value: "Bermuda"
    },
    {
        label: "Bhutan(BT)",
        value: "Bhutan"
    },
    {
        label: "Bolivia(BO)",
        value: "Bolivia"
    },
    {
        label: "Bosnia and Herzegovina(BA)",
        value: "Bosnia and Herzegovina"
    },
    {
        label: "Botswana(BW)",
        value: "Botswana"
    },
    {
        label: "Bouvet Island(BV)",
        value: "Bouvet Island"
    },
    {
        label: "Brazil(BR)",
        value: "Brazil"
    },
    {
        label: "British Indian Ocean Territory(IO)",
        value: "British Indian Ocean Territory"
    },
    {
        label: "Brunei(BN)",
        value: "Brunei"
    },
    {
        label: "Bulgaria(BG)",
        value: "Bulgaria"
    },
    {
        label: "Burkina Faso(BF)",
        value: "Burkina Faso"
    },
    {
        label: "Burundi(BI)",
        value: "Burundi"
    },
    {
        label: "Cambodia(KH)",
        value: "Cambodia"
    },
    {
        label: "Cameroon(CM)",
        value: "Cameroon"
    },
    {
        label: "Canada(CA)",
        value: "Canada"
    },
    {
        label: "Cabo Verde(CV)",
        value: "Cabo Verde"
    },
    {
        label: "Cayman Islands(KY)",
        value: "Cayman Islands"
    },
    {
        label: "Central African Republic(CF)",
        value: "Central African Republic"
    },
    {
        label: "Chad(TD)",
        value: "Chad"
    },
    {
        label: "Chile(CL)",
        value: "Chile"
    },
    {
        label: "China(CN)",
        value: "China"
    },
    {
        label: "Christmas Island(CX)",
        value: "Christmas Island"
    },
    {
        label: "Cocos (Keeling) Islands(CC)",
        value: "Cocos (Keeling) Islands"
    },
    {
        label: "Colombia(CO)",
        value: "Colombia"
    },
    {
        label: "Komori(KM)",
        value: "Komori"
    },
    {
        label: "Republic of the Congo(CG)",
        value: "Republic of the Congo"
    },
    {
        label: "Democratic Republic of the Congo(CD)",
        value: "Democratic Republic of the Congo"
    },
    {
        label: "Cook Islands(CK)",
        value: "Cook Islands"
    },
    {
        label: "Costa Rica(CR)",
        value: "Costa Rica"
    },
    {
        label: "Ivory Coast(CI)",
        value: "Ivory Coast"
    },
    {
        label: "Croatia(HR)",
        value: "Croatia"
    },
    {
        label: "Cuba(CU)",
        value: "Cuba"
    },
    {
        label: "Cyprus(CY)",
        value: "Cyprus"
    },
    {
        label: "Czechia(CZ)",
        value: "Czechia"
    },
    {
        label: "Danmark(DK)",
        value: "Danmark"
    },
    {
        label: "Djibouti(DJ)",
        value: "Djibouti"
    },
    {
        label: "Dominica(DM)",
        value: "Dominica"
    },
    {
        label: "Dominican Republic(DO)",
        value: "Dominican Republic"
    },
    {
        label: "Ecuador(EC)",
        value: "Ecuador"
    },
    {
        label: "Egypt(EG)",
        value: "Egypt"
    },
    {
        label: "El Salvador(SV)",
        value: "El Salvador"
    },
    {
        label: "Equatorial Guinea(GQ)",
        value: "Equatorial Guinea"
    },
    {
        label: "Eritrea(ER)",
        value: "Eritrea"
    },
    {
        label: "Estonia(EE)",
        value: "Estonia"
    },
    {
        label: "Ethiopia(ET)",
        value: "Ethiopia"
    },
    {
        label: "Falkland Islands (Islas Malvinas)(FK)",
        value: "Falkland Islands (Islas Malvinas)"
    },
    {
        label: "Faroe Islands(FO)",
        value: "Faroe Islands"
    },
    {
        label: "Fiji(FJ)",
        value: "Fiji"
    },
    {
        label: "Suomi(FI)",
        value: "Suomi"
    },
    {
        label: "France(FR)",
        value: "France"
    },
    {
        label: "French Guiana(GF)",
        value: "French Guiana"
    },
    {
        label: "French Polynesia(PF)",
        value: "French Polynesia"
    },
    {
        label: "French Southern and Antarctic Lands(TF)",
        value: "French Southern and Antarctic Lands"
    },
    {
        label: "Gabon(GA)",
        value: "Gabon"
    },
    {
        label: "The Gambia(GM)",
        value: "The Gambia"
    },
    {
        label: "Georgia(GE)",
        value: "Georgia"
    },
    {
        label: "Germany(DE)",
        value: "Germany"
    },
    {
        label: "Ghana(GH)",
        value: "Ghana"
    },
    {
        label: "Gibraltar(GI)",
        value: "Gibraltar"
    },
    {
        label: "Greece(GR)",
        value: "Greece"
    },
    {
        label: "Greenland(GL)",
        value: "Greenland"
    },
    {
        label: "Grenada(GD)",
        value: "Grenada"
    },
    {
        label: "Guadeloupe(GP)",
        value: "Guadeloupe"
    },
    {
        label: "Guam(GU)",
        value: "Guam"
    },
    {
        label: "Guatemala(GT)",
        value: "Guatemala"
    },
    {
        label: "Guernsey(GG)",
        value: "Guernsey"
    },
    {
        label: "Guinea(GN)",
        value: "Guinea"
    },
    {
        label: "Guinea-Bissau(GW)",
        value: "Guinea-Bissau"
    },
    {
        label: "Guyana(GY)",
        value: "Guyana"
    },
    {
        label: "Haiti(HT)",
        value: "Haiti"
    },
    {
        label: "Heard Island and McDonald Islands(HM)",
        value: "Heard Island and McDonald Islands"
    },
    {
        label: "Vaticano(VA)",
        value: "Vaticano"
    },
    {
        label: "Honduras(HN)",
        value: "Honduras"
    },
    {
        label: "Hong Kong(HK)",
        value: "Hong Kong"
    },
    {
        label: "Hungary(HU)",
        value: "Hungary"
    },
    {
        label: "Iceland(IS)",
        value: "Iceland"
    },
    {
        label: "India(IN)",
        value: "India"
    },
    {
        label: "Indonesia(ID)",
        value: "Indonesia"
    },
    {
        label: "Iran(IR)",
        value: "Iran"
    },
    {
        label: "Iraq(IQ)",
        value: "Iraq"
    },
    {
        label: "Ireland(IE)",
        value: "Ireland"
    },
    {
        label: "Isle of Man(IM)",
        value: "Isle of Man"
    },
    {
        label: "Israel(IL)",
        value: "Israel"
    },
    {
        label: "Italy(IT)",
        value: "Italy"
    },
    {
        label: "Jamaica(JM)",
        value: "Jamaica"
    },
    {
        label: "Japan(JP)",
        value: "Japan"
    },
    {
        label: "Jersey(JE)",
        value: "Jersey"
    },
    {
        label: "Jordan(JO)",
        value: "Jordan"
    },
    {
        label: "Kazakhstan(KZ)",
        value: "Kazakhstan"
    },
    {
        label: "Kenya(KE)",
        value: "Kenya"
    },
    {
        label: "Kiribati(KI)",
        value: "Kiribati"
    },
    {
        label: "North Korea(KP)",
        value: "North Korea"
    },
    {
        label: "South Korea(KR)",
        value: "South Korea"
    },
    {
        label: "Kosovo(XK)",
        value: "Kosovo"
    },
    {
        label: "Kuwait(KW)",
        value: "Kuwait"
    },
    {
        label: "Kyrgyzstan(KG)",
        value: "Kyrgyzstan"
    },
    {
        label: "Laos(LA)",
        value: "Laos"
    },
    {
        label: "Latvija(LV)",
        value: "Latvija"
    },
    {
        label: "Lebanon(LB)",
        value: "Lebanon"
    },
    {
        label: "Lesotho(LS)",
        value: "Lesotho"
    },
    {
        label: "Liberia(LR)",
        value: "Liberia"
    },
    {
        label: "Libya(LY)",
        value: "Libya"
    },
    {
        label: "Liechtenstein(LI)",
        value: "Liechtenstein"
    },
    {
        label: "Lithuania(LT)",
        value: "Lithuania"
    },
    {
        label: "Luxembourg(LU)",
        value: "Luxembourg"
    },
    {
        label: "Macao(MO)",
        value: "Macao"
    },
    {
        label: "North Macedonia(MK)",
        value: "North Macedonia"
    },
    {
        label: "Madagascar(MG)",
        value: "Madagascar"
    },
    {
        label: "Malawi(MW)",
        value: "Malawi"
    },
    {
        label: "Malaysia(MY)",
        value: "Malaysia"
    },
    {
        label: "Maldives(MV)",
        value: "Maldives"
    },
    {
        label: "Mali(ML)",
        value: "Mali"
    },
    {
        label: "Malta(MT)",
        value: "Malta"
    },
    {
        label: "Marshall Islands(MH)",
        value: "Marshall Islands"
    },
    {
        label: "Martinique(MQ)",
        value: "Martinique"
    },
    {
        label: "Mauritania(MR)",
        value: "Mauritania"
    },
    {
        label: "Maurice(MU)",
        value: "Maurice"
    },
    {
        label: "Mayotte(YT)",
        value: "Mayotte"
    },
    {
        label: "Mexico(MX)",
        value: "Mexico"
    },
    {
        label: "Micronesia(FM)",
        value: "Micronesia"
    },
    {
        label: "Moldova(MD)",
        value: "Moldova"
    },
    {
        label: "Monaco(MC)",
        value: "Monaco"
    },
    {
        label: "Mongolia(MN)",
        value: "Mongolia"
    },
    {
        label: "Montenegro(ME)",
        value: "Montenegro"
    },
    {
        label: "Montserrat(MS)",
        value: "Montserrat"
    },
    {
        label: "Morocco(MA)",
        value: "Morocco"
    },
    {
        label: "Mozambique(MZ)",
        value: "Mozambique"
    },
    {
        label: "Myanmar(Burma)(MM)",
        value: "Myanmar (Burma)"
    },
    {
        label: "Namibia(NA)",
        value: "Namibia"
    },
    {
        label: "Nauru(NR)",
        value: "Nauru"
    },
    {
        label: "Nepal(NP)",
        value: "Nepal"
    },
    {
        label: "Netherlands(NL)",
        value: "Netherlands"
    },
    {
        label: "New Caledonia(NC)",
        value: "New Caledonia"
    },
    {
        label: "New Zealand(NZ)",
        value: "New Zealand"
    },
    {
        label: "Nicaragua(NI)",
        value: "Nicaragua"
    },
    {
        label: "Niger(NE)",
        value: "Niger"
    },
    {
        label: "Nigeria(NG)",
        value: "Nigeria"
    },
    {
        label: "Niue(NU)",
        value: "Niue"
    },
    {
        label: "Norfolk Island(NF)",
        value: "Norfolk Island"
    },
    {
        label: "Northern Mariana Islands(MP)",
        value: "Northern Mariana Islands"
    },
    {
        label: "Norway(NO)",
        value: "Norway"
    },
    {
        label: "Oman(OM)",
        value: "Oman"
    },
    {
        label: "Pakistan(PK)",
        value: "Pakistan"
    },
    {
        label: "Palau(PW)",
        value: "Palau"
    },
    {
        label: "Palestine(PS)",
        value: "Palestine"
    },
    {
        label: "Panama(PA)",
        value: "Panama"
    },
    {
        label: "Papua New Guinea(PG)",
        value: "Papua New Guinea"
    },
    {
        label: "Paraguay(PY)",
        value: "Paraguay"
    },
    {
        label: "Peru(PE)",
        value: "Peru"
    },
    {
        label: "Philippines(PH)",
        value: "Philippines"
    },
    {
        label: "Pitcairn Islands(PN)",
        value: "Pitcairn Islands"
    },
    {
        label: "Poland(PL)",
        value: "Poland"
    },
    {
        label: "Portugal(PT)",
        value: "Portugal"
    },
    {
        label: "Puerto Rico(PR)",
        value: "Puerto Rico"
    },
    {
        label: "Qatar(QA)",
        value: "Qatar"
    },
    {
        label: "Réunion(RE)",
        value: "Réunion"
    },
    {
        label: "Romania(RO)",
        value: "Romania"
    },
    {
        label: "Russia(RU)",
        value: "Russia"
    },
    {
        label: "Rwanda(RW)",
        value: "Rwanda"
    },
    {
        label: "Saint Barthélemy(BL)",
        value: "Saint Barthélemy"
    },
    {
        label: "Saint Helena(SH)",
        value: "Saint Helena"
    },
    {
        label: "Saint Kitts and Nevis(KN)",
        value: "Saint Kitts and Nevis"
    },
    {
        label: "Saint Lucia(LC)",
        value: "Saint Lucia"
    },
    {
        label: "Saint Martin(MF)",
        value: "Saint Martin"
    },
    {
        label: "Saint Pierre and Miquelon(PM)",
        value: "Saint Pierre and Miquelon"
    },
    {
        label: "Saint Vincent and the Grenadines(VC)",
        value: "Saint Vincent and the Grenadines"
    },
    {
        label: "Samoa(WS)",
        value: "Samoa"
    },
    {
        label: "San Marino(SM)",
        value: "San Marino"
    },
    {
        label: "São Tomé and Príncipe(ST)",
        value: "São Tomé and Príncipe"
    },
    {
        label: "Saudi Arabia(SA)",
        value: "Saudi Arabia"
    },
    {
        label: "Senegal(SN)",
        value: "Senegal"
    },
    {
        label: "Serbia(RS)",
        value: "Serbia"
    },
    {
        label: "Seychelles(SC)",
        value: "Seychelles"
    },
    {
        label: "Sierra Leone(SL)",
        value: "Sierra Leone"
    },
    {
        label: "Singapore(SG)",
        value: "Singapore"
    },
    {
        label: "Sint Maarten(SX)",
        value: "Sint Maarten"
    },
    {
        label: "Slovakia(SK)",
        value: "Slovakia"
    },
    {
        label: "Slovenia(SI)",
        value: "Slovenia"
    },
    {
        label: "Solomon Islands(SB)",
        value: "Solomon Islands"
    },
    {
        label: "Somalia(SO)",
        value: "Somalia"
    },
    {
        label: "South Africa(ZA)",
        value: "South Africa"
    },
    {
        label: "South Georgia and the South Sandwich Islands(GS)",
        value: "South Georgia and the South Sandwich Islands"
    },
    {
        label: "South Sudan(SS)",
        value: "South Sudan"
    },
    {
        label: "Spain(ES)",
        value: "Spain"
    },
    {
        label: "Sri Lanka(LK)",
        value: "Sri Lanka"
    },
    {
        label: "Sudan(SD)",
        value: "Sudan"
    },
    {
        label: "Suriname(SR)",
        value: "Suriname"
    },
    {
        label: "Svalbard and Jan Mayen(SJ)",
        value: "Svalbard and Jan Mayen"
    },
    {
        label: "Sweden(SE)",
        value: "Sweden"
    },
    {
        label: "Switzerland(CH)",
        value: "Switzerland"
    },
    {
        label: "Syria(SY)",
        value: "Syria"
    },
    {
        label: "Taiwan(TW)",
        value: "Taiwan"
    },
    {
        label: "Tajikistan(TJ)",
        value: "Tajikistan"
    },
    {
        label: "Tanzania(TZ)",
        value: "Tanzania"
    },
    {
        label: "Thailand(TH)",
        value: "Thailand"
    },
    {
        label: "Timor-Leste(TL)",
        value: "Timor-Leste"
    },
    {
        label: "Togo(TG)",
        value: "Togo"
    },
    {
        label: "Tokelau(TK)",
        value: "Tokelau"
    },
    {
        label: "Tonga(TO)",
        value: "Tonga"
    },
    {
        label: "Trinidad and Tobago(TT)",
        value: "Trinidad and Tobago"
    },
    {
        label: "Tunisia(TN)",
        value: "Tunisia"
    },
    {
        label: "Turkey(TR)",
        value: "Turkey"
    },
    {
        label: "Turkmenistan(TM)",
        value: "Turkmenistan"
    },
    {
        label: "Turks and Caicos Islands(TC)",
        value: "Turks and Caicos Islands"
    },
    {
        label: "Tuvalu(TV)",
        value: "Tuvalu"
    },
    {
        label: "Uganda(UG)",
        value: "Uganda"
    },
    {
        label: "Ukraine(UA)",
        value: "Ukraine"
    },
    {
        label: "United Arab Emirates(AE)",
        value: "United Arab Emirates"
    },
    {
        label: "United Kingdom(GB)",
        value: "United Kingdom"
    },
    {
        label: "United States(US)",
        value: "United States"
    },
    {
        label: "Uruguay(UY)",
        value: "Uruguay"
    },
    {
        label: "Uzbekistan(UZ)",
        value: "Uzbekistan"
    },
    {
        label: "Vanuatu(VU)",
        value: "Vanuatu"
    },
    {
        label: "Venezuela(VE)",
        value: "Venezuela"
    },
    {
        label: "Vietnam(VN)",
        value: "Vietnam"
    },
    {
        label: "Virgin Islands(British)(VG)",
        value: "Virgin Islands (British)"
    },
    {
        label: "Virgin Islands(US)(VI)",
        value: "Virgin Islands (US)"
    },
    {
        label: "Wallis and Futuna(WF)",
        value: "Wallis and Futuna"
    },
    {
        label: "Western Sahara(EH)",
        value: "Western Sahara"
    },
    {
        label: "Yemen(YE)",
        value: "Yemen"
    },
    {
        label: "Zambia(ZM)",
        value: "Zambia"
    },
    {
        label: "Zimbabwe(ZW)",
        value: "Zimbabwe"
    }
];
