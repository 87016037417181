import PropTypes from "prop-types"
import React from "react"
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap"

import "./CustomModal.scss"

const CustomModal = props => {
  const { isOpen, toggle, centered = true, data, className="", onClose, size= "md", modalHeaderClass="", modalCloseIconClass="", modalBodyClass="" } = props
  return (
    <div className={`bv-custom-modal ${className}`}>
      <Modal isOpen={isOpen} toggle={toggle} centered={centered} size={size} className={className} >
        <ModalHeader className={`border-0 ms-auto ${modalHeaderClass}`}>
          {onClose && (
            <i
              className={`mdi mdi-close-circle font-size-24 clr-theme-secondary ${modalCloseIconClass}`}
              onClick={onClose}
              role="button"
            ></i>
          )}
        </ModalHeader>
        <ModalBody className={`p-0 ${modalBodyClass}`}>{data}</ModalBody>
      </Modal>
    </div>
  )
}

CustomModal.propTypes = {
  className: PropTypes.any,
  isOpen: PropTypes.any,
  toggle: PropTypes.any,
  centered: PropTypes.any,
  data: PropTypes.any,
  onClose: PropTypes.any,
  size: PropTypes.any,
  modalHeaderClass: PropTypes.string,
}

export default CustomModal
