import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/app";
import "firebase/messaging";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./i18n";

import { SET_FCM_TOKEN } from './store/actions'
import { Loader } from "components/Loader";
import { store, persistor,  } from "./store";
import {getMe, updateFCMToken } from './store/actions'

import App from "./App";
import { Notificaion } from "utils/notificationUtils";

const app = (
    <Provider store={store}>
        <PersistGate loading={<Loader size="sm" />} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((reg) => {
            console.log("service worker registered");
        })
        .catch((err) => {
            console.log(err);
        });
}

// useEffect(() => {
    // let notification;

    // if (isAuthenticated) {
        // if ("Notification" in window && firebase.messaging.isSupported()) {
        //     notification = new Notificaion();
        //     notification
        //         .getToken()
        //         .then((token) => {
        //             console.log('token::<<', token)
                    

        //             dispatch(updateFCMToken(token)).catch(() => {});
        //         })
        //         .catch((err) => {});
        // }
        // if (!tokenVerified) {
        //     dispatch(getMe()).catch(() => {});
        // }
    // }
    // return () => {
    //     if (notification) {
    //         notification.removeListeners();
    //     }
    // };
    // eslint-disable-next-line 
// }, []);


// serviceWorker.unregister();
