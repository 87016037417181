import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import PhoneInput, {
    isValidPhoneNumber,
    parsePhoneNumber,
    getCountryCallingCode
} from "react-phone-number-input";
import { CustomModal } from "../../components/CustomModal";
import { useSelector } from "react-redux";
import { withRouter, Redirect, useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";

import AuthWrapper from "./AuthWrapper";
import { METATAGS } from "../../constants";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { ForgetPasswordApi } from "api/api.service";
import loginImage from "assets/images/aiAPaiT/login/login-img.png";

const ForgetPassword = (props) => {
    let {} = props;

    const history = useHistory();
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    const [holdToken, setHoldToken] = useState("");
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [phoneData, setPhoneData] = useState(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            phoneNum: ""
        },
        validationSchema: Yup.object({
            phoneNum: Yup.string()
                .required("Enter Phone Number")
                .test("phoneNum", "Phone number is invalid", function (val) {
                    return val && isValidPhoneNumber(val);
                })
        }),
        onSubmit: (values, { resetForm }) => {
            handleEmailSubmit(values, resetForm);
        }
    });

    const handleEmailSubmit = async (data, resetForm) => {
        try {
            const newData = { ...data };
            setIsLoading(true);
            let countryCodeAlpha = parsePhoneNumber(newData.phoneNum).country;
            let countryCode = getCountryCallingCode(countryCodeAlpha);
            countryCode = `+${countryCode}`;
            let newPhoneNum = newData.phoneNum?.split(countryCode)[1];
            let countriesArr = newData.countries?.map((el) => el.value);
            newData.phone = newPhoneNum;
            newData.countryCode = countryCode;
            delete newData.phoneNum;
            let res = await ForgetPasswordApi(newData);
            setHoldToken(res?.token);
            setPhoneData(newData);
            setShowOtpModal(true);
            resetForm();
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <MetaTags>
                <title>{METATAGS.FORGOT_PASSWORD}</title>
            </MetaTags>
            <AuthWrapper
                leftContent={
                    <div className="text-center">
                        <h1 className="text-white fw-normal">
                            <span>CONFIRM EMAIL ADDRESS</span>
                            <span className="d-block font-size-14">
                                Confirm your email address to reset your password
                            </span>
                        </h1>
                    </div>
                }
                data={
                    <div className="bv-forget-wrap margin-top-vh">
                        <p className="login-type mb-0 ms-0">Forgot Password?</p>
                        <form className="mt-2" onSubmit={formik.handleSubmit}>
                            <div className="forget-pass-info-type mb-2">
                                Don’t worry, Just fill your phone number. We'll send you an OTP to
                                reset your password
                            </div>
                            <div className="mb-4">
                                <PhoneInput
                                    defaultCountry={"SA"}
                                    countryOptionsOrder={["SA", "AE", "OM", "BH", "KW", "QA"]}
                                    onChange={(values) => formik.setFieldValue("phoneNum", values)}
                                    onBlur={() => {
                                        formik.setFieldTouched("phoneNum");
                                    }}
                                    value={formik.values.phoneNum}
                                    name="phoneNum"
                                    className="phone-num-con"
                                />
                                {formik.touched.phoneNum && formik.errors.phoneNum ? (
                                    <span className="error">{formik.errors.phoneNum}</span>
                                ) : null}
                            </div>

                            <CustomButton
                                loading={isLoading}
                                color="primary"
                                title="Get OTP"
                                className="w-100 mt-5 ms-0"
                                type="submit"
                            />
                        </form>
                    </div>
                }
                leftImgUrl={loginImage}
            />
            <CustomModal
                isOpen={showOtpModal}
                data={
                    <div className="text-center">
                        <h2 className="clr-theme-primary">OTP Sent</h2>
                        <p className="my-4">
                            One Time Password has been sent to your provided <br /> phone number.
                        </p>
                        <CustomButton
                            color="primary"
                            title="Continue"
                            className="bv-btn-sm mb-3"
                            onClick={() =>
                                history.push({
                                    pathname: APP_ROUTES.OTP_VERIFY_CODE,
                                    state: { tokenId: holdToken, phoneData: phoneData }
                                })
                            }
                        />
                    </div>
                }
            />
        </>
    );
};

export default withRouter(ForgetPassword);
