/* eslint-disable no-unused-vars */
import { ME_REQUEST, ME_SUCCESS, ME_ERROR, SET_TOKEN, SET_FCM_TOKEN, UPDATE_NOTIFICATION_COUNT_FAILURE,
    UPDATE_NOTIFICATION_COUNT,
    UPDATE_NOTIFICATION_COUNT_SUCCESS } from './actionTypes'

import { Login, GetMe, GetReadNotificationCount, updateUser  } from 'api/api.service'
import { NotificationStatus } from "../../constants";

export const login = (bodyData) => (dispatch, getState) => {
    return Login(bodyData)
        .then(async (res) => {
            dispatch({ type: SET_TOKEN, token: res.token })
            await dispatch(getMe(res.Token))
            return Promise.resolve({})
        })
        .catch((err) => {
            return Promise.reject(err)
        })
}

export const getMe = (token) => (dispatch, getState) => {
    dispatch({ type: ME_REQUEST })
    return GetMe()
        .then((res) => {
            dispatch({ type: ME_SUCCESS, payload: res })
            // dispatch({type: SET_USER_DATA , payload: res});
            localStorage.isAuthenticated = true
            return Promise.resolve(res)
        })
        .catch((err) => {
            dispatch({ type: ME_ERROR })
            return Promise.reject(err)
        })
}

export const updateFCMToken = (fcmToken) => (dispatch, getState) => {    
    const token = getState().userAuth.user.Token;
    const id = getState().userAuth.user.id;
    return updateUser(id, {
        fcmToken: fcmToken
    })
    .then((res) => {
        if (res && res) {
                dispatch({ type: SET_FCM_TOKEN, payload: fcmToken });
                return Promise.resolve(res);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const updateNotificationCount = () => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_NOTIFICATION_COUNT });
        try{
            let params= {readStatus: NotificationStatus.getDisplayTextKey(0)}
            let res = await GetReadNotificationCount(params)
            dispatch({ type: UPDATE_NOTIFICATION_COUNT_SUCCESS, payload: res?.count });
        }catch(err){
            dispatch({ type: UPDATE_NOTIFICATION_COUNT_FAILURE });

        }
    };
};

