import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'
import moment from 'moment'
import { 
  Col,
  Row,
  Card,
  Accordion,
  AccordionBody,
  Label,
  AccordionItem
} from "reactstrap";
import { TextInput } from "components/Input";
import { CustomButton } from "components/CustomButton";
import { CustomAccordionHeader } from "./Accordion";
import TrademarkDetail from './TrademarkDetail';
import { CaseRejection } from "components/CaseRejectionModal";
import DocumentViewerModal from "./DocumentViewerModal";

import ESignatureIcon from "../../assets/images/aiAPaiT/case-mangement/e-signature-icon.png";
import DateIcon from '../../assets/images/aiAPaiT/case-mangement/date-icon.svg';
import { APP_ROUTES } from "helpers/routeHelpers";

export default function POAApplicationCompleted ({
  openAccord, 
  toggleButtonHandler=() => {}, 
  title="",
  isCompleted,
  status="Completed",
  targetId="",
  accordId="",
  isPA=false,
  processNO,
  isAwaiting,
  data_description,
  isFormTwoVisible=false,
  nextStepTargetId="",
  nextStepHandler= () => {},
  refetchStepsAPIHandler,
  fileDownload= false}){
    const history = useHistory();
    const userType = useSelector(store => store?.userAuth?.user?.type)
    const [isPdfViewerModalActive, setIsPdfViewerModalActive] = useState(false)
    const [rejectionSuccessModal, setRejectionSuccessModal] = useState(false);
    const [pdfDataLink, setPdfDataLink] = useState("");

    // input field change handler
    const handleFeeChange = (e) => {
        console.log(e)
    }
    // rejection modal close handling
    const rejectionModalCloseHandler = (isReject) => {
        isReject && history.push(APP_ROUTES.CASE_MANAGEMENT);
        setRejectionSuccessModal(false);
    };

    // pdf/image document view handling
    const documentViewerHandler = (dataUrl) => {
        setPdfDataLink(dataUrl)
        setIsPdfViewerModalActive(true)
    };

    // pdf/image document view modal close handler
    const pdfViewerModalCloseHandler = (e) => {
        setPdfDataLink("")
        setIsPdfViewerModalActive(false)
    }

    // move to next step
    const handleMoveToNextStep = (e) => {
      nextStepHandler(nextStepTargetId)
    }

  return(
  <>
    <div className="case-detail-accordian-signature-con">
      <Accordion open={openAccord} toggle={toggleButtonHandler}>
          <AccordionItem>
              <CustomAccordionHeader
                  containerClassName="case-detail-accordian-container"
                  innerClassName="case-detail-accordian-header"
                  subContainerClassName="case-detail-accordian-header-status"
                  headerCount={processNO}
                  headerText={title}
                  status={status}
                  targetId={targetId}
              />

              <AccordionBody
                  accordionId={accordId}
                  className="case-detail-accordian-body-con"
              >
                  <Card>
                    {data_description && 
                    data_description?.documents?.length > 0 && 
                    Object.keys(data_description).length > 0 ? (<>                    
                      <Row>
                          {isPA && (<>
                          <Col md={6}>
                              <div className="case-detail-accordian-signature-body">
                                  Power of Attorney field
                              </div>
                                <TrademarkDetail
                                    documentData={
                                    data_description?.documents[0]?.documentType === "PowerOfAttorney" ? 
                                    data_description?.documents[0]?.media:
                                    data_description?.documents[1]?.media}
                                    documentViewerHandler={documentViewerHandler}
                                />
                          </Col>
                          <Col md={6}>
                              <div className="case-detail-accordian-signature-body">
                                  Application field
                              </div>                              
                               <TrademarkDetail
                                    documentData={
                                    data_description?.documents[0].documentType === "Application" ? 
                                    data_description?.documents[0].media:
                                    data_description?.documents[1].media}
                                    documentViewerHandler={documentViewerHandler}
                                />
                          </Col>
                          </>)}
                          {!isPA && (<>
                            <Col md={12}>
                              <div className="case-detail-accordian-signature-body">
                              {title === "Publication" ? "Trademark Publication Form" : "Trademark Registration"}
                              </div>
                              <TrademarkDetail
                                    documentData={
                                    data_description?.documents[0].media}
                                    documentViewerHandler={documentViewerHandler}
                                />
                          </Col>
                          </>)}
                          <Col md={12}>
                            {!fileDownload && (<div  className="case-detail-accordian-body">
                                <div>Step Fee</div>
                                <p>
                                  {`$${data_description?.amount}`}
                                </p>
                            </div>)}
                            <div className='bg-white p-3 pt-4 case-details-accordian-date-field'>
                                <Label>{!fileDownload ? "Fee Deadline" : "Renewal Date"}</Label>
                                <div className="mb-3">
                                    <TextInput
                                        name={"feesDeadline"}
                                        type="date"
                                        className="hide-icon"
                                        onChange={handleFeeChange}
                                        value={moment(data_description?.expiryDate).format('YYYY-MM-DD')}
                                        autoFocus
                                        iconFile={DateIcon}
                                        disabled
                                    />
                                </div>                  
                            </div> 
                          </Col>
                      </Row>
                      {isAwaiting ?  
                      (<>
                      <hr /> 
                      <Row>
                          <div className="e-signature-footer-btn">
                              <img src={ESignatureIcon} height={40} width={40} />
                              <div className="accordian-body-footer-text">
                                  Case is in progress and waiting for customer
                                  response
                              </div>
                          </div>
                      </Row>
                      </>):null}
                      {userType === 'ADMIN' && !isCompleted && !isAwaiting ? (
                      <>
                        <hr /> 
                        <Row>
                            <div className="e-signature-footer-btn">
                                <img src={ESignatureIcon} height={40} width={40} />
                                <div className="accordian-body-footer-text">
                                    Case is in progress and waiting for department
                                    response
                                </div>
                            </div>
                        </Row>
                      </>) : null}
                      {!isCompleted  && userType !== 'ADMIN' && (
                        <>
                      <hr />
                      <Row>
                          <div className="accordian-body-footer-btn">
                              <CustomButton
                                  title="Move To Next Step"
                                  className="trademark-body-footer-accept-button shadow-none me-3"
                                  onClick={handleMoveToNextStep}
                                  disabled={isFormTwoVisible }
                              /> 
                              <CustomButton
                                  title="Reject"
                                  className="trademark-body-footer-reject-button shadow-none"
                                  color="#E64A4A"
                                  onClick={() => {
                                      setRejectionSuccessModal(true);
                                  }}
                              />
                          </div>
                      </Row>
                      </>
                      )}
                    </>) : (<><p className="data-not-found-message">Data Not Found</p></>)}                                                      
                  </Card>
                  <DocumentViewerModal
                    modalActivator={isPdfViewerModalActive}
                    modalCloseHandler={pdfViewerModalCloseHandler}
                    modalData={pdfDataLink}
                    isDataFetching={false}
                    />
                   <CaseRejection
                        modalCloseHandler={rejectionModalCloseHandler}
                        modalActivator={rejectionSuccessModal}
                        stepId={data_description}
                        setIsRefetchingRequired={refetchStepsAPIHandler}
                        title={title}
                    />
              </AccordionBody>
          </AccordionItem>
      </Accordion>
  </div>
  </>
  )
}

// handling prop types
POAApplicationCompleted.propTypes = {
  openAccord: PropTypes.string,
  toggleButtonHandler: PropTypes.func,
  title: PropTypes.string,
  targetId: PropTypes.string,
  accordId: PropTypes.string,
  isPA: PropTypes.bool,
  processNO: PropTypes.string

}