import React from 'react';
import PropTypes from "prop-types"
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";

import { TextInput } from "components/Input";
import { CustomModal } from "components/CustomModal";
import { CustomButton } from "components/CustomButton";

import { createGoodsServices, updateGoodsServices } from "api/api.service"

import backIcon from "../../assets/images/aiAPaiT/back-arrow.svg";

const initailData = {
  name: "",
  code: "123"
}

const AddNewGoodServices = ({ 
  modalActivator= false,
  modalCloseHandler= () => {},
  modalData = {},
  dataChangeHandler=() => {}, 
  isEdit=false,
}) => {

  const  dataObj = isEdit ? modalData : initailData;
  
  const [isLoading, setIsLoading] = React.useState(false)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...dataObj
    },
    validationSchema: Yup.object({
        name: Yup.string()
        .required("Enter Good/Service name")
        .min(3, "Too short Good/Service name!")
        .max(55, "Too long Good/Service name!"),
    })
    ,
    onSubmit: (values, {resetForm}) => {
      handleFormSubmit(values, resetForm);
    }
  });

  const handleFormSubmit = async (data, resetForm) => {
    try{      
      const res = isEdit ?  await updateGoodsServices(modalData?.id, data) : await createGoodsServices({name: data?.name});
      toast.success("Good/Service has been created successfully!");
      modalCloseHandler("isCreated")
      resetForm()
    }finally{
      setIsLoading(false);
    }
  };  

  const handleBackBtn = (e, resetForm) => {
    resetForm()
    modalCloseHandler()
  }

  return(
    <>
      <CustomModal
        isOpen={modalActivator}
        className="add-department-custom-modal"
        data={
            <div className=" add-department-modal px-5 pb-4 mb-3">
              <form onSubmit={formik.handleSubmit}>
                <div className='d-flex  align-items-center mb-4'>
                  <img 
                    src={backIcon} 
                    alt="close modal icon"
                    onClick={e => handleBackBtn(e, formik.resetForm)}
                  />
                  <p className="my-2 mx-auto">
                    {isEdit ? "Edit Good/Service" : "Add New Good/Service"}  
                  </p>
                </div>
                <div className='mb-3'>
                  <TextInput
                    placeholder="Enter Good/Service Name"
                    className="dept-name-ip "
                    type="text"
                    name={"name"}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name} 
                  />
                  {
                    formik.touched.name && formik.errors.name ? (
                      <span className="error">{formik.errors.name}</span>
                    ) : null
                  }
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <CustomButton
                    title={isEdit ? "Save Changes" : "Create New Good/Service"}
                    className="w-100 shadow-none"
                    color="primary"
                    type={"submit"}
                    loading={isLoading}
                  />
                </div>
              </form>
            </div>
        }
      />
    </>
  )
}

AddNewGoodServices.propTypes = {
  modalActivator: PropTypes.bool,
  modalCloseHandler: PropTypes.func,
  modalData: PropTypes.object,
  dataChangeHandler: PropTypes.func,
  selectCountryHandler: PropTypes.func,
  isEdit: PropTypes.bool,
}

export default AddNewGoodServices;