import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useSelector } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import { useFormik } from "formik";
import { Col, Row, Card, Accordion, AccordionBody, Label, AccordionItem } from "reactstrap";
import { finalizeMediaUtil, initializeMediaUtil, uploadOnS3 } from "utils/mediaUtils";
import { removeSpaceUnderscoreCharacterFromString } from 'utils/commonUtils'
import { CustomAccordionHeader } from "./Accordion";
import { CaseRejection } from "components/CaseRejectionModal";
import { APP_ROUTES } from "helpers/routeHelpers";
import { TextInput } from "components/Input";

import { ImageUpload } from "components/ImageUpload";
import { ImageTypes } from "../../constants";
import { CustomButton } from "components/CustomButton";
import { createStepFive } from "api/api.service";
import UploadLogo from "../../assets/images/aiAPaiT/case-mangement/upload-logo.png";
import DateIcon from "../../assets/images/aiAPaiT/case-mangement/date-icon.svg";
import ESignatureIcon from "../../assets/images/aiAPaiT/case-mangement/e-signature-icon.png";

const KB_CONVERTER = 1024;
export default function TMCRDownload({
    openAccord,
    toggleButtonHandler = () => {},
    title = "",
    targetId = "",
    accordId = "",
    processNO,
    data_description,
    refetchStepsAPIHandler
}) {
    const mountedRef = useRef(true)
    let dataObj = {};
    const params = useParams();
    const history = useHistory();
    const userType = useSelector(store => store?.userAuth?.user?.type);

    const [rejectionSuccessModal, setRejectionSuccessModal] = useState(false);
    const [trademarkTMCRDownloadMedia, setTrademarkTMCRDownloadMedia] = useState({
        image: "",
        fileName: undefined,
        imageError: undefined
    });
    const [errorType, setErrorType] = useState(0);
    const [isSubmittingStepFive, setIsSubmittingStepFive] = useState(false);

    // formik validation for input fields
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            feeDeadline: ""
        },
        validationSchema: Yup.object({
            feeDeadline: Yup.date().required("Please enter the date")
        }),
        onSubmit: (values) => {
            dataObj.expiryDate = values.feeDeadline;
            handleStepTMCRSubmission();
        }
    });

    // rejection modal close handling
    const rejectionModalCloseHandler = (isReject) => {
        isReject && history.push(APP_ROUTES.CASE_MANAGEMENT);
        setRejectionSuccessModal(false);
    };

    // file upload handling
    const FileUploadHander = (file) => {
        let imageError = undefined;
        let fileName = undefined;
        let image = "";
        if (file) {
            const uploadedFileType = file?.type?.split("/")[1]?.toLowerCase();
            let fileSize = file && file?.size;
            fileSize = fileSize / KB_CONVERTER;
            if (!ImageTypes.includes(uploadedFileType)) {
                imageError = "File type is not supported!";
                fileName = undefined;
                image = "";
            } else {
                fileName = removeSpaceUnderscoreCharacterFromString(file?.name)
                imageError = ""
                image = file
                image.fileName = fileName
            }
        }
        return { imageError, image, fileName };
    };

    // file type input change handling
    const handleFileChange = (e, imageObjUpdater) => {
        e.preventDefault();
        if(userType === 'ADMIN'){
            toast.error("Admin can't process the case!")
            return
        }
        let file = e.target.files[0];
        const { image, imageError, fileName } = FileUploadHander(file);
        imageObjUpdater({ image, imageError, fileName });
        setErrorType(0);
    };

    // hanlde remove image
    const removeImage = (imageObjUpdater) => {
        imageObjUpdater({ image: "", imageError: undefined, fileName: undefined });
        setErrorType(1);
    };

    // media upload on server
    const handleMediaUpload = async (file) => {
        return initializeMediaUtil(file).then(async (res) => {
            const credentials = res;
            await uploadOnS3(file, credentials);
            return await finalizeMediaUtil(credentials?.mediaId);
        });
    };

    // step submission on backend using API
    const handleStepTMCRSubmission = async () => {
        if (trademarkTMCRDownloadMedia.image === "") {
            setErrorType(1);
            return;
        } else {
                let payloadObj = { ...dataObj };
                payloadObj.expiryDate = moment(dataObj.expiryDate).format(
                    "YYYY-MM-DDTHH:mm:ss.SSSSZ"
                );
                payloadObj.documents = [];
                setIsSubmittingStepFive(true);
                if (trademarkTMCRDownloadMedia.image) {
                    let imageRes = await handleMediaUpload(trademarkTMCRDownloadMedia.image);
                    payloadObj.documents.push({ documentType: "TMCR", mediaId: imageRes.id });
                }
                createStepFive(params?.id, payloadObj).then(res => {
                    toast.success("TMCRDownload Step has been submitted successfully.");
                    refetchStepsAPIHandler();
                }).catch(err => {
                    setIsSubmittingStepFive(false);
                });
        }
    };

    // cleaning up async tasks
    useEffect(() => {
        return () => { 
            mountedRef.current = false
          }
    }, [])
    return (
        <>
            <div className="case-detail-accordian-signature-con">
                <Accordion open={openAccord} toggle={toggleButtonHandler}>
                    <AccordionItem>
                        <CustomAccordionHeader
                            containerClassName="case-detail-accordian-container"
                            innerClassName="case-detail-accordian-header"
                            subContainerClassName="case-detail-accordian-header-status"
                            headerCount={processNO}
                            headerText={title}
                            status={"Open"}
                            targetId={targetId}
                        />

                        <AccordionBody
                            accordionId={accordId}
                            className="case-detail-accordian-body-con"
                        >
                            <Card>
                                <form onSubmit={formik.handleSubmit}>
                                {userType !== 'ADMIN' ? 
                                (<Row>
                                    <Col md={12}>
                                        <div className="d-flex">
                                            <div className="case-detail-accordian-body">
                                                <div>Trademark Registration</div>
                                                <ImageUpload
                                                    image={UploadLogo}
                                                    inputId={5}
                                                    onChange={(e) =>
                                                        handleFileChange(
                                                            e,
                                                            setTrademarkTMCRDownloadMedia
                                                        )
                                                    }
                                                    fileName={trademarkTMCRDownloadMedia?.fileName}
                                                    imageError={
                                                        trademarkTMCRDownloadMedia?.imageError
                                                    }
                                                    removeImageHandler={() =>
                                                        removeImage(setTrademarkTMCRDownloadMedia)
                                                    }
                                                    errorType={
                                                        errorType === 1
                                                            ? "Kindly upload the file"
                                                            : ""
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="bg-white p-3 pt-0 case-details-accordian-date-field">
                                            <Label>Renewal Date</Label>
                                            <div className="mb-3">
                                                <TextInput
                                                    name={"feeDeadline"}
                                                    type="date"
                                                    className="hide-icon"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.feeDeadline}
                                                    autoFocus
                                                    iconFile={DateIcon}
                                                    min={moment().format("YYYY-MM-DD")}
                                                />
                                                {formik.touched.feeDeadline &&
                                                formik.errors.feeDeadline ? (
                                                    <span className="error">
                                                        {formik.errors.feeDeadline}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>):
                                (
                                    <>
                                        <Row>
                                            <div className="e-signature-footer-btn">
                                                <img src={ESignatureIcon} height={40} width={40} />
                                                <div className="accordian-body-footer-text">
                                                    Case is in progress and waiting for department
                                                    response
                                                </div>
                                            </div>
                                        </Row>
                                    </>)}
                                {userType !== 'ADMIN' ? 
                                    (<>
                                        <hr />
                                        <Row>
                                            <div className="accordian-body-footer-btn">
                                                <CustomButton
                                                    title="Send"
                                                    className="trademark-body-footer-accept-button me-3 shadow-none"
                                                    color="#704DE7"
                                                    outline={true}
                                                    type={"submit"}
                                                    loading={isSubmittingStepFive}
                                                />
                                                {/* <CustomButton
                                                    title="Reject"
                                                    className="trademark-body-footer-reject-button shadow-none"
                                                    color="#E64A4A"
                                                    onClick={() => {
                                                        setRejectionSuccessModal(true);
                                                    }}
                                                /> */}
                                            </div>
                                        </Row>
                                    </>):null }
                                </form>                                                                    
                            </Card> 
                            {/* <CaseRejection
                                modalCloseHandler={rejectionModalCloseHandler}
                                modalActivator={rejectionSuccessModal}
                                stepId={data_description}
                                setIsRefetchingRequired={refetchStepsAPIHandler}
                                title={title}
                            /> */}
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </div>
        </>
    );
}

// handling prop types
TMCRDownload.propTypes = {
    openAccord: PropTypes.string,
    toggleButtonHandler: PropTypes.func,
    title: PropTypes.string,
    targetId: PropTypes.string,
    accordId: PropTypes.string,
    isPA: PropTypes.bool,
    processNO: PropTypes.string
};
