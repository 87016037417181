import React, { useState } from "react";
import PropTypes from "prop-types";

import SendIcon from "../../assets/images/aiAPaiT/chat/send-icon.svg";
import { Form } from "reactstrap";

const KEY = "Enter";
const ESCAPE_CHARACTER = "\n";

const SendMessage = ({ onMessageSent }) => {
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e?.preventDefault();
        if (message && message.trim().length !== 0) {
            onMessageSent({ messageContent: message });
            setMessage("");
        }
    };

    const handleEnterKeyPress = (e) => {
        if (
            (e.altKey && e.shiftKey) ||
            (e.ctrlKey && e.key === KEY) ||
            (e.shiftKey && e.key === KEY)
        ) {
            setMessage((prevText) => prevText + ESCAPE_CHARACTER);
            e.preventDefault();
        }
        if (e.key === KEY && !e.shiftKey && !e.ctrlKey) {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <>
            <div className="">
                <Form onSubmit={handleSubmit}>
                    <div className="row field form px-0 py-2 text-center">
                        <div className="col-12 message-field">
                            <textarea
                                placeholder="Write your message here"
                                color="primary"
                                rows="1"
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                onKeyDown={handleEnterKeyPress}
                            ></textarea>

                            <div className="message-field-action">
                                <button className="pa-0 send-msg-btn" type="submit">
                                    Send
                                    <img src={SendIcon} alt="icon" className="ms-2" />
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
};

// handling prop types
SendMessage.propTypes = {
    socket: PropTypes.any,
    onMessageSent: PropTypes.any
};

export default SendMessage;
