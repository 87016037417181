import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";

import { CustomButton } from "../../components/CustomButton";
import { getGraphData } from 'api/api.service';
import { 
    findDaysRange, 
    findMonthsRange, 
    findYearRange, 
    groupingDataWithDate, 
    findDateFormat, 
    findOutFinalAmountArray } from 'utils/graphUtils';
import "./Dashboard.scss";

const Earnings = (props) => {

    const {} = props;
    const [activeButton, setActiveButton] = useState("months");
    const [durationType, setDurationType] = useState({weeks: [], months: [], years: []})
    const [series, setSeries] = useState([])

    // year/month/week selecting on change handling
    const handleClick = (buttonId) => {
        setActiveButton(buttonId);
    };
  
    // fetching data from API and processing the data to fit the graph
    useEffect(async () => {
        try{
            let findDatesDaysRange = findDateFormat(activeButton, findMonthsRange, findDaysRange, findYearRange);
            const {daysRange, datesRange, start_date , end_date} = findDatesDaysRange();
            let res = await getGraphData({start_date: start_date, end_date: end_date})
            //grouping the data
            let groupingFormat = activeButton === "years" ? 'YYYY-MM' : 'YYYY-MM-DD';
            let responses = groupingDataWithDate(res, groupingFormat)
            let finalAmount = findOutFinalAmountArray(responses, datesRange)
            setDurationType({...durationType, [activeButton]: daysRange})
            setSeries(finalAmount)            
        }finally{
    
        }
    }, [activeButton]);

    // updating the X-axis labels according to the user selection
    const getXAxisLabels = () => {
        if (activeButton == "months") {
            return durationType?.months;
        } else if (activeButton == "weeks") {
            return durationType?.weeks;
        } else {
            return durationType?.years;
        }
    };
 
    // graph library required options
    const options = {
        chart: {
            toolbar: "false",
            dropShadow: {
                enabled: !0,
                color: "#000",
                top: 18,
                left: 7,
                blur: 8,
                opacity: 0.2
            }
        },
        dataLabels: {
            enabled: !1
        },
        colors: ["#704DE7"],
        stroke: {
            curve: "smooth",
            width: 3
        },
        xaxis: {
            categories: getXAxisLabels()
        },
        yaxis: {
            showAlways: true,
            title: {
                text: "$ (USD)",
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: "#ADB5BD",
                    fontSize: "12px",
                    fontFamily: "Urbanist, sans-serif",
                    fontWeight: 700,
                    cssClass: "apexcharts-yaxis-title"
                }
            }
        }
    };

    return (
        <Card>
            <CardBody>
                <div className="clearfix">
                    <div className="float-end">
                        <CustomButton
                            title="Week"
                            className={`chart-button shadow-none me-2 ${
                                activeButton === "weeks" ? "active" : ""
                            }`}
                            color="#704DE7"
                            outline
                            onClick={() => handleClick("weeks")}
                        />
                        <CustomButton
                            title="Month"
                            className={`chart-button shadow-none me-2 ${
                                activeButton === "months" ? "active" : ""
                            }`}
                            color="#704DE7"
                            outline
                            onClick={() => handleClick("months")}
                        />
                        <CustomButton
                            className={`chart-button shadow-none me-2 ${
                                activeButton === "years" ? "active" : ""
                            }`}
                            color="#704DE7"
                            outline
                            onClick={() => handleClick("years")}
                            title="Year"
                        />
                    </div>
                    <h4 className="card-title mb-4">Revenue</h4>
                </div>
                <Row>
                    <Col lg={12}>
                        <div id="line-chart" className="apex-charts" dir="ltr">
                            <ReactApexChart
                                series={[{name: "Amount", data: series}]}
                                options={options}
                                type="line"
                                height={320}
                            />
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

Earnings.propTypes = {};

export default Earnings;
